import Axios from "./../../../../utils/Axios/Axios";

class User {

    static getUserDetail = async (data) => Axios.get(`/api/auth/getUserLoginDetail`,data);

    static changePassword = async (data) => Axios.put(`/api/auth/changePassword`,data); 

    static login = async (data) => Axios.postNoToken(`/api/auth/login`,data); 


  }
  
export default User;