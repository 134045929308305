import { Box, Button, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import dayjs from 'dayjs';



import useValidator from "../../../utils/Validator";
import MapContainer from "./MapContainer";
import Axios from "../../../utils/Axios";

import { setListPlace, setValueTabPlaceHeader } from "../../../utils/store/actions";
import ChangListPlace from "../../../layout/menu-items/changeListPlace";
import Loading from "../../Loading";
import GeneralForm from "../newPlace/GeneralForm";
import { useTranslation } from "react-i18next";



const styleInputFullField = {
    width: "100%",
};

const key = 'AIzaSyA1i6_c7sC-QA1QGrodUWwWxzMjszvdRk4';

const CustomButton = styled
    // eslint-disable-next-line no-empty-pattern
    (Button)(({ }) => ({
        backgroundColor: '#0958d9', // màu nền button
        color: 'white', // màu chữ button
        fontWeight: 'bold',
        borderRadius: 8,
        marginBottom: 18,
        paddingLeft: 30,
        paddingRight: 30,
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#08979c', // màu nền khi hover vào button
        },
    }));

const googleApiKey = key;
const initialZoom = 12;

export default function EditPlaces() {
    const { _id } = useParams();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const navigate = useNavigate();


    const newValueTabPlaceHeader = useSelector((state) => state.ReducerValueTabPlaceHeader.data);
    const { validatePlace } = useValidator();
    const [markers, setMarkers] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [selectedPlace, setSelectedPlace] = useState(null);

    const [initialCenter, setInitialCenter] = useState()

    const [inputValues, setInputValues] = useState({})
    const [errors, setErrors] = useState({});
    const [place, setPlace] = useState({});

    const [type, setType] = useState('');
    const [errorType, setErrorType] = useState(false);

    const [standardInterval, setStandardInterval] = useState();
    const [peekInterval, setPeekInterval] = useState();

    const [offPeakInterval, setOffPeakInterval] = useState();
    const [showErrorCheckBoxPeak, setShowErrorCheckBoxPeak] = useState(false);
    const [showErrorCheckBoxOffPeak, setShowErrorCheckBoxOffPeak] = useState(false);
    const [showErrorCheckBoxStand, setShowErrorCheckBoxStand] = useState(false);



    useEffect(() => {
        getOnePlace(_id)
        if (!newValueTabPlaceHeader) {
            dispatch(setValueTabPlaceHeader(1))

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event) => {
        setErrorType(false);
        setType(event.target.value);
    };

    const getOnePlace = async (_id) => {
        if (_id !== undefined) {
            setShowLoading(() => true);
            const response = await Axios.Place.getOne(_id);
            if (response.status === 200) {
                setShowLoading(() => false);
                setInitialCenter({
                    name: 'Current location',
                    lat: response?.data?.location?.coordinates[0], // Vĩ độ của vị trí
                    lng: response?.data?.location?.coordinates[1], // Kinh độ của vị trí
                })
                setMarkers([
                    {
                        name: response?.data?.name,
                        position: {
                            lat: response?.data?.location?.coordinates[0], // Vĩ độ của vị trí
                            lng: response?.data?.location?.coordinates[1]
                        },
                        address: response.data.address
                    }
                ])
                let data = response.data;
                data.lat = response?.data?.location?.coordinates[0];
                data.lng = response?.data?.location?.coordinates[1];
                setType(response.data.type)

                data.standardPrice = data.electricConsumptionTariff.standard.price
                data.peakPrice = data.electricConsumptionTariff.peak.price
                data.offPeakPrice = data.electricConsumptionTariff.offPeak.price

                setPlace(data);

                const standard = getElectric(data.electricConsumptionTariff.standard.interval);
                const peek = getElectric(data.electricConsumptionTariff.peak.interval)
                const offPeak = getElectric(data.electricConsumptionTariff.offPeak.interval);

                setPeekInterval(peek)
                setOffPeakInterval(offPeak)
                setStandardInterval(standard)
            } else {
                setShowLoading(() => false);
            }
        };
    }

    function ElectricConsumptionTariff(data) {
        const result = []
        data?.forEach(element => {
            if (element.status) {
                const dayOfWeek = element.dayOfWeek;
                const listCheckBox = [];
                for (let index = 0; index < dayOfWeek.length; index++) {
                    if (dayOfWeek[index] === true) {
                        let number = index + 2
                        if (number === 8) {
                            number = 1;
                        }
                        listCheckBox.push(number)
                    }
                }
                result.push({
                    startTime: element?.startTime?.$H + ':' + element?.startTime?.$m,
                    endTime: element?.endTime?.$H + ':' + element?.endTime?.$m,
                    dayOfWeek: listCheckBox
                }
                )
            }
        });
        return result;
    }

    function getElectric(data) {
        const listData = [];
        for (let jk = 0; jk < data.length; jk++) {
            const element = data[jk];
            const listDayOfWeek = [];
            let cn;
            for (let index = 1; index < 8; index++) {
                if (element.dayOfWeek.includes(index)) {
                    if (index !== 1) {
                        listDayOfWeek.push(true)
                    } else {
                        cn = true;
                    }
                } else {
                    if (index !== 1) {
                        listDayOfWeek.push(false)
                    }
                }
                if (index === 7 && cn) {
                    listDayOfWeek.push(cn);
                } else {
                    if (index === 7 && !element.dayOfWeek.includes(1)) {
                        listDayOfWeek.push(false);
                    }
                }
            }
            listData.push({ status: true, startTime: dayjs('2022-04-17T' + element.startTime), endTime: dayjs('2022-04-17T' + element.endTime), dayOfWeek: listDayOfWeek })
        }
        return listData
    }


    const handleMapClick = (mapProps, map, clickEvent) => {
        const lat = clickEvent.latLng.lat();
        const lng = clickEvent.latLng.lng();

        setSelectedPlace(null)

        const newMarker = {
            position: clickEvent.latLng,
            name: 'New Marker',
            lat,
            lng,
        };

        setMarkers([newMarker]);
        setErrors({
            ...errors,
            lat: '',
            lng: ''
        })
        // =========================
        place.lat = lat;
        place.lng = lng;
        setPlace({ ...place, ...place });
    };




    const handleOnSubmit = (e) => {
        e.preventDefault();
        let target = Array.from(e.target);
        let validForm = true;
        let error = {};
        target.forEach((data) => {
            if (data.name) {
                let err = validatePlace(data.name, data.value);
                if (err) {
                    error[data.name] = err;
                    validForm = false;
                }
                if (type.length === 0) {
                    validForm = false;
                    setErrorType(true);
                } else {
                    setErrorType(false);
                }
            }
        });
        setErrors(() => ({ ...errors, ...error }));
        const intervalPeekInterval = ElectricConsumptionTariff(peekInterval)
        const intervalOffPeakInterval = ElectricConsumptionTariff(offPeakInterval)
        const intervalStandardInterval = ElectricConsumptionTariff(standardInterval)

        const checkPeek = checkDayOfWeekArray(intervalPeekInterval)
        const checkOfPeek = checkDayOfWeekArray(intervalOffPeakInterval)
        const checkStand = checkDayOfWeekArray(intervalStandardInterval)
        if (!checkPeek || !checkOfPeek || !checkStand) {
            validForm = false;
        }

        if (validForm) {
            let listCoordinates = [place.lat, place.lng];


            let data = {}
            data.name = place.name;
            data.address = place.address;
            data.location = {
                coordinates: listCoordinates
            }
            data.electricConsumptionTariff = {
                offPeak: {
                    price: place.offPeakPrice,
                    interval: intervalOffPeakInterval
                },
                standard: {
                    price: place.standardPrice,
                    interval: intervalStandardInterval
                },
                peak: {
                    price: place.peakPrice,
                    interval: intervalPeekInterval
                }
            }
            data.type = type;

            updatePlace(data);
        } else {
            toast.error(`${t('Vui lòng điền đầy đủ thông tin')}!`);
        }
    };

    function checkDayOfWeekArray(array) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].dayOfWeek.length === 0) {
                return false;
            }
        }
        return true;
    }

    const updatePlace = async (data) => {
        setShowLoading(() => true);

        const response = await Axios.Place.update(_id, data);
        if (response.status === 200) {
            setShowLoading(() => false);
            toast.success(t('Cập nhật thành công'));
            ChangListPlace(0, 10, "", "", "createdAt").then(data => {
                dispatch(setListPlace(data));
            });

        } else {
            if (response.status === 400 && response.code === 5) {
                toast.error("Tên địa điểm đã tồn tại!");
                setErrors({
                    Name: "Tên địa điểm đã tồn tại!"
                })
            }
            setShowLoading(() => false);
        }
    };

    const handleOnInput = (event) => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
        setErrors({ ...errors, [name]: validatePlace(name, value) });
    };

    return (
        <>
            <form onSubmit={handleOnSubmit}>
                <Box sx={{ minHeight: '80px' }}>
                </Box>
                <Box sx={{ borderRadius: 2, boxShadow: 2, width: '90%', margin: 'auto', mb: 5 }}>
                    <Grid container sx={{ margin: 'auto', }} spacing={2} >
                        <Grid item xs={6} sm={6} md={6} >
                            <Box sx={{ width: '90%', margin: 'auto' }}>
                                <TextField
                                    name="name"
                                    id="outlined-basic"
                                    label={t('Tên')}
                                    multiline
                                    maxRows={3}
                                    value={place?.name || ''}
                                    variant="standard"
                                    color="secondary"
                                    sx={styleInputFullField}
                                    error={errors.name ? true : false}
                                    helperText={
                                        errors.name ? (
                                            errors.name
                                        ) : (
                                            <Box component="span" >
                                                &nbsp;
                                            </Box>
                                        )
                                    }
                                    onChange={(e) => {
                                        setPlace({ ...place, name: e.target.value });
                                    }}
                                    onInput={handleOnInput}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} >
                            <Box sx={{ width: '90%', margin: 'auto' }}>
                                <TextField
                                    name="address"
                                    id="outlined-basic"
                                    label={t('Địa chỉ')}
                                    multiline
                                    maxRows={3}
                                    value={place?.address || ''}
                                    variant="standard"
                                    color="secondary"
                                    sx={styleInputFullField}
                                    error={errors.address ? true : false}
                                    helperText={
                                        errors.address ? (
                                            errors.address
                                        ) : (
                                            <Box component="span" >
                                                &nbsp;
                                            </Box>
                                        )
                                    }
                                    onChange={(e) => {
                                        setPlace({ ...place, address: e.target.value });
                                    }}
                                    onInput={handleOnInput}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} >
                            <Box sx={{ width: '90%', margin: 'auto' }}>
                                <FormControl variant="standard" sx={{ width: '100%' }} error={errorType} >
                                    <InputLabel id="demo-simple-select-standard-label">{t('Loại')}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={type}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'singlePhase'}>1 {t('pha')}</MenuItem>
                                        <MenuItem value={'threePhase'}>3 {t('pha')}</MenuItem>
                                        <MenuItem value={'solar'}>Solar</MenuItem>
                                    </Select>
                                    {errorType && <FormHelperText>{t('Loại phải bắt buộc nhập!')}</FormHelperText>}
                                </FormControl>
                            </Box>

                        </Grid>

                        <Grid item xs={12} sm={6} md={5} sx={{ margin: 'auto' }} >
                            <Box sx={{ width: '90%', display: 'flex' }}>
                                <TextField
                                    name="lat"
                                    id="outlined-basic"
                                    label={t('Vĩ độ')}
                                    variant="standard"
                                    color="secondary"
                                    value={place?.lat || ''}
                                    sx={{
                                        ...styleInputFullField, styleInputFullField,
                                        ml: 3
                                    }}
                                    error={errors.lat ? true : false}
                                    helperText={
                                        errors.lat ? (
                                            errors.lat
                                        ) : (
                                            <Box component="span" >
                                                &nbsp;
                                            </Box>
                                        )
                                    }
                                    onChange={(e) => {
                                        setPlace({ ...place, lat: e.target.value });
                                    }}
                                    onInput={handleOnInput}
                                />
                                <TextField
                                    name="lng"
                                    id="outlined-basic"
                                    label={t('Kinh độ')}
                                    variant="standard"
                                    color="secondary"
                                    value={place?.lng || ''}
                                    sx={{
                                        ...styleInputFullField, styleInputFullField,
                                        ml: 3
                                    }}
                                    error={errors.lng ? true : false}
                                    helperText={
                                        errors.lng ? (
                                            errors.lng
                                        ) : (
                                            <Box component="span" >
                                                &nbsp;
                                            </Box>
                                        )
                                    }
                                    onChange={(e) => {
                                        setPlace({ ...place, lng: e.target.value });
                                    }}
                                    onInput={handleOnInput}
                                />
                            </Box>
                        </Grid>


                        <Grid item xs={12} sm={12} md={12} sx={{ mt: 3 }}>
                            <Box sx={{ width: '90%', margin: 'auto', height: '350px' }}>
                                {initialCenter &&
                                    <MapContainer
                                        googleApiKey={googleApiKey}
                                        initialZoom={initialZoom}
                                        initialCenter={initialCenter}
                                        markers={markers}
                                        handleMapClick={handleMapClick}
                                        selectedPlace={selectedPlace}
                                        setSelectedPlace={setSelectedPlace}
                                    />
                                }
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} sx={{ mt: 3, mb: 3 }}>
                            {place && offPeakInterval &&
                                <GeneralForm localizations={offPeakInterval} setLocalizations={setOffPeakInterval}
                                    place={place}
                                    setPlace={setPlace}
                                    errors={errors}
                                    setErrors={setErrors}
                                    handleOnInput={handleOnInput}
                                    titleValue={'offPeakPrice'}
                                    name={'Giá thấp điểm'}
                                    showErrorCheckBox={showErrorCheckBoxOffPeak}
                                    setShowErrorCheckBox={setShowErrorCheckBoxOffPeak}
                                />
                            }
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} sx={{ mt: 3, mb: 3 }}>
                            {standardInterval && place && standardInterval &&
                                <GeneralForm localizations={standardInterval} setLocalizations={setStandardInterval}
                                    place={place}
                                    setPlace={setPlace}
                                    errors={errors}
                                    setErrors={setErrors}
                                    handleOnInput={handleOnInput}
                                    titleValue={'standardPrice'}
                                    name={'Giá bình thường'}
                                    showErrorCheckBox={showErrorCheckBoxStand}
                                    setShowErrorCheckBox={setShowErrorCheckBoxStand}
                                />
                            }
                        </Grid>



                        <Grid item xs={12} sm={12} md={12} sx={{ mt: 3, mb: 3 }}>
                            {place && peekInterval &&
                                <GeneralForm localizations={peekInterval} setLocalizations={setPeekInterval}
                                    place={place}
                                    setPlace={setPlace}
                                    errors={errors}
                                    setErrors={setErrors}
                                    handleOnInput={handleOnInput}
                                    titleValue={'peakPrice'}
                                    name={'Giá cao điểm'}
                                    showErrorCheckBox={showErrorCheckBoxPeak}
                                    setShowErrorCheckBox={setShowErrorCheckBoxPeak}
                                />
                            }
                        </Grid>







                        <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }} >
                            <Box sx={{ width: '90%', margin: 'auto', textAlign: 'center' }}>
                                <CustomButton disabled={showLoading} type="submit" sx={{ ml: -1.5 }}>{t('Cập nhật')}</CustomButton>
                                <CustomButton onClick={(e) => { navigate('/my-place') }} sx={{ ml: 1.5, background: '#f5222d' }}>{t('Đóng')}</CustomButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </form>

            <Loading showLoading={showLoading} />
        </>
    )
}