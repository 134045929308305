import { Box, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setValueTabSystemHeader } from "../../../utils/store/actions";
import { useTranslation } from "react-i18next";


function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function TabSystem() {
    const dispatch = useDispatch();
    const { t } = useTranslation();


    const newValueTabHeader = useSelector((state) => state.ReducerValueTabSystemHeader.data);

    const handleChange = (event, newValue) => {
        dispatch(setValueTabSystemHeader(newValue))

    };

    return (
        <>
            <Box >
                <Tabs value={newValueTabHeader ? newValueTabHeader : 0} onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="scrollable prevent tabs example"
                    TabIndicatorProps={{
                        style: {
                            height: 2,
                            transform: 'translateY(-8px)',
                            borderBottom: '2px solid white'
                        },
                    }}
                >
                    <Tab label={t("Tổng quan")} {...a11yProps(0)} sx={{ color: 'white !important', fontSize: 14,fontWeight:700  }} />
                    {/* <Tab label="History Record" {...a11yProps(1)} sx={{ color: 'white !important', fontSize: 14,  }} /> */}
                    <Tab label={t('Phân tích báo cáo')} {...a11yProps(1)} sx={{ color: 'white !important', fontSize: 14,fontWeight:700  }} />
                    {/* <Tab label="Auto-Sending Settings" {...a11yProps(3)} sx={{ color: 'white !important', fontSize: 12,  }} /> */}
                </Tabs>
            </Box>
        </>

    )
}