import { createTheme } from '@mui/material/styles';
import themeTypography from './themeTypography';


/**
 * Represent theme style and structure as per Material-UI
 * @param {JsonObject} customization customization parameter object
 */
export const theme = (customization) => {


    const themeOption = {
        customization
    };


    const themes = createTheme(
        {
            palette: {
                mode: customization.mode,
            },
            typography: themeTypography(themeOption)
        });

    return themes;
};

export default theme;
