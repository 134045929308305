import { Box, Checkbox, IconButton, Table, TableBody, TableCell, TableContainer, TablePagination, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import logo from "./../../../../assets/images/home-grid.png"


import ListHead from "./PlaceListHead";
import Axios from "../../../../utils/Axios";
import FormatddMMyyyy from "../../../../utils/FormatDate/FormatddMMyyyy"
import PlaceListToolbar from "./PlaceListToolbar";
import Iconify from "../../../iconify/Iconify";
import DialogConfirm from "../../../../pages/Dialog/DialogConfirm";

import { setListPlace, setValueTabPlaceHeader } from "../../../../utils/store/actions";
import ChangListPlace from "../../../../layout/menu-items/changeListPlace";
import Loading from "../../../Loading";
import { useTranslation } from "react-i18next";
import logApiErrorGet from "../../../../utils/Axios/LogApiError";

const scrollbar = {
    "::-webkit-scrollbar": {
        width: "5px",
    },
    ":hover::-webkit-scrollbar-thumb": {
        " -webkit-border-radius": "5px",
        borderRadius: "5px",
        background: "#dee2e3",
    },
    "::-webkit-scrollbar-thumb:window-inactive": {
        background: "#dee2e3",
    },
};


let TABLE_HEAD = [
    { id: "name", label: "Tên", alignRight: false, },
    { id: "address", label: "Địa chỉ", alignRight: false },
    { id: "type", label: 'Loại', alignRight: false },
    { id: "_id", label: 'Id', alignRight: false },
    { id: "status", label: 'Kết nối', alignRight: false },
    { id: "createdAt", label: "Ngày tạo", alignRight: false },
];

let titleSeleced = "Đã chọn";

export default function TablePlace() {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { t } = useTranslation();

    const [listData, setListData] = useState([]);
    const [order, setOrder] = useState("desc");
    const [orderBy, setOrderBy] = useState("createdAt");
    const [page, setPage] = useState(0);
    const [limit, setLimit] = useState(10);
    const [countPage, setCountPage] = useState(0);
    const [selected, setSelected] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [openDialog, setOpenDialog] = useState(false);



    const getAllPlace = async (page, limit, sortOrder, search, property) => {
        setShowLoading(() => true);
        try {
            const response = await Axios.Place.getAll(
                page,
                limit,
                sortOrder,
                search,
                property
            );
            if (response?.status === 200) {
                setShowLoading(() => false);
                setSelected([]);
                setPage(page);
                setLimit(limit);
                setListData(response.data.listItem);
                setCountPage(parseInt(response.data.totalItem));
            } else {
                logApiErrorGet(setShowLoading, "getAllPlace", response);

            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "getAllPlace", error);

        }
    };



    const handleRequestSort = (event, property) => {
        const isAsc = ((orderBy === property || orderBy !== property) && order === "asc");
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
        getAllPlace(newPage, 10, -1, "", orderBy);
    };

    const handleChangeRowsPerPage = (event) => {
        getAllPlace(0, event.target.value, -1, "", orderBy);
        setLimit(parseInt(event.target.value, 10));
    };


    const handleClick = (event, _id) => {
        const selectedIndex = selected.indexOf(_id);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, _id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    }

    const deletePlace = async (listPlaceId) => {
        setShowLoading(() => true);
        const response = await Axios.Place.delete({ listPlaceId });
        if (response.status === 200) {
            setShowLoading(() => false);
            getAllPlace(page, limit, -1, "", orderBy);
            toast.success(`${t('Xoá thành công')}!`);
            setOpenDialog(false)
            ChangListPlace(0, 4, "", "", "createdAt").then(data => {
                dispatch(setListPlace(data));
            });
            setSelected([])
        } else {
            setShowLoading(() => false);
            toast.error(t('Xoá thất bại'));
        }
    };

    const handleClickOpen = (id) => {
        setSelected([id])
        setOpenDialog(true);
    };

    const handleDialogDelete = () => {
        deletePlace(selected);
        setOpenDialog(false);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = listData?.map((n) => n._id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleEdit = (id) => {
        navigate(`/edit-place/` + id);
        dispatch(setValueTabPlaceHeader(1))
    };

    const handlePlaceDetail = (id) => {
        navigate(`/energy-system/` + id);
    };

    const handleCopy = async (textToCopy) => {
        // if (!navigator.clipboard) {
        // Trình duyệt không hỗ trợ Clipboard API
        //     toast.error('Trình duyệt không hỗ trợ sao chép vào clipboard.');
        //     return;
        //   }
        // try {
        //     await navigator.clipboard.writeText(textToCopy);
        //     toast.success("Đã sao chép thành công!");
        //   } catch (err) {
        //     console.error('Lỗi khi sao chép:', err);
        //     toast.error("Không thể sao chép!");
        //   }

        // ======================No https==============================
        const tempInput = document.createElement("input");
        tempInput.value = textToCopy;
        document.body.appendChild(tempInput);
        tempInput.select();
        try {
            document.execCommand("copy");
            toast.success(t('Đã sao chép thành công'));
        } catch (err) {
            console.error('Lỗi khi sao chép:', err);
            toast.error(`${t('Không thể sao chép')}!`);
        } finally {
            document.body.removeChild(tempInput);
        }
    };

    return (
        <>
            <PlaceListToolbar
                numSelected={selected.length}
                selected={selected}
                onChange={getAllPlace}
                listData={listData}
                showLoading={showLoading}
                deletePlace={deletePlace}
                t={t}
            />
            <TableContainer
                sx={{
                    maxHeight: '70vh',
                    cursor: 'pointer',
                    ...scrollbar,
                    width: '96%',
                    margin: 'auto',
                    borderRadius: 2,
                    border: '2px solid rgb(224 224 224)'
                }}
            >
                <Table
                    size={'small'}
                >
                    <ListHead
                        order={order}
                        orderBy={orderBy}
                        headLabel={TABLE_HEAD}
                        rowCount={listData?.length}
                        numSelected={selected.length}
                        onRequestSort={handleRequestSort}
                        onSelectAllClick={handleSelectAllClick}
                        onChange={getAllPlace}
                        page={page}
                        limit={limit}
                    />
                    <TableBody>
                        {listData?.map((row, index) => {
                            const { _id, name, address, type, createdAt } = row;
                            const selectedUser = selected.indexOf(_id) !== -1;
                            return (
                                <TableRow
                                    hover
                                    key={index}
                                    tabIndex={-1}
                                    sx={{ cursor: "pointer", }}
                                >
                                    <TableCell padding="checkbox"
                                    >
                                        <Checkbox
                                            checked={selectedUser}
                                            onChange={(event) => handleClick(event, _id)}
                                        />
                                    </TableCell>

                                    <TableCell
                                        align="center"
                                        onClick={(e) => { handlePlaceDetail(_id) }}
                                        padding="none"
                                        style={{minWidth:'200px'}}
                                    >
                                        <Typography sx={{ ml: -2 }} >
                                            <IconButton
                                                size="large"
                                                color="inherit"
                                            >
                                                <img src={logo} alt="logo" width={30} />
                                            </IconButton>
                                            {name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        onClick={(e) => { handlePlaceDetail(_id) }}
                                        padding="none"
                                        style={{minWidth:'200px'}}
                                    >
                                        <Typography sx={{ ml: -2 }} >
                                            {address}
                                        </Typography>
                                    </TableCell>

                                    <TableCell
                                        align="center"
                                        onClick={(e) => { handlePlaceDetail(_id) }}
                                        padding="none"
                                        style={{minWidth:'150px'}}
                                    >
                                        <Typography sx={{ ml: -2 }} >
                                            {type}
                                        </Typography>
                                    </TableCell>
                                    <TableCell
                                        align="center"
                                        padding="none"
                                        style={{minWidth:'250px'}}
                                    >
                                        <Typography sx={{ ml: -2 }} >
                                            {_id}
                                            <IconButton
                                                size="large"
                                                color="inherit"
                                                onClick={(e) => handleCopy(_id)}
                                            >
                                                <Iconify icon={"iconamoon:copy"} />
                                            </IconButton>
                                        </Typography>

                                    </TableCell>

                                    <TableCell
                                        align="center"
                                        padding="none"
                                        style={{minWidth:'150px'}}
                                    >
                                        <Typography sx={{ ml: -2 }} > <Iconify icon="ph:circle-fill" color='#52c41a' /></Typography>
                                    </TableCell>

                                    <TableCell
                                        align="center"
                                        onClick={(e) => { handlePlaceDetail(_id) }}
                                        padding="none"
                                        style={{minWidth:'150px'}}
                                    >
                                        <Typography sx={{ ml: -2 }} >
                                            <FormatddMMyyyy dateTime={createdAt} />
                                        </Typography>

                                    </TableCell>
                                    <TableCell align="right" sx={{ minWidth: "70px" }}
                                        padding="none">
                                        <Iconify icon={"bx:edit"} color='#1890ff' sx={{ mr: 2 }} onClick={(e) => { handleEdit(_id) }} />

                                        <Iconify icon={"eva:trash-2-outline"} color='red' sx={{ mr: 1 }} onClick={(e) => { handleClickOpen(_id) }} />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
                {listData.length === 0 && <Box sx={{ textAlign: 'center', p: 1 }}>No data</Box>
                }
            </TableContainer>
            {listData.length !== 0 &&
                <TablePagination
                    sx={{ float: "left" }}
                    rowsPerPageOptions={[10, 25, 50]}
                    component="div"
                    count={countPage}
                    rowsPerPage={limit}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    labelRowsPerPage={t("Số hàng mỗi trang")}
                />}

            <DialogConfirm title={t('Xoá')} openDialog={openDialog} setOpenDialog={setOpenDialog} numSelected={1} titleSeleced={titleSeleced} onChange={handleDialogDelete} />


            <Loading showLoading={showLoading} />


        </>
    )
}