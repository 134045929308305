import { Grid } from "@mui/material";
import AppWidgetSummary from "./AppWidgetSummary";


const listIcon = ['vaadin:spark-line', 'ph:chart-line', "fluent-mdl2:b-i-dashboard", "clarity:nodes-line", 'akar-icons:statistic-up'];
const listColor = ['#eb2f96', '#2f54eb', '#fa8c16', '#722ed1', '#a0d911'];
export default function ToTalValue({ listObject,unit }) {

    const md = Object.keys(listObject?.values).length;
    return (
        <>
            {Object.keys(listObject?.values).map((field, index) => {
                let name;
                if (field === 'currentValue') {
                    name = 'Giá trị hiện tại'
                }
                if (field === 'totalDay') {
                    name = 'Tổng ngày'
                }
                if (field === 'totalMonth') {
                    name = 'Tổng tháng'
                }
                if (field === 'totalYear') {
                    name = 'Tổng năm'
                }

                return <Grid key={index} item xs={12} sm={6} md={12 / md} sx={{ minWidth:'250px',height: '14vh', display: 'flex',margin:'auto', minHeight: '110px' }}>
                    {listObject &&
                        <AppWidgetSummary
                            title={name}
                            icon={listIcon[index]}
                            icon1={"icon-park-twotone:dashboard-car"}
                            icon2={"clarity:dashboard-line"}
                            value={listObject.values[field] || "0"}
                            unit={unit}
                            color={listColor[index]}
                        />}
                </Grid>
            })}
        </>
    )
}