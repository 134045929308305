export default function addFieldsToObject(newArray) {
    // console.log('-----> ',newArray[0].value)
    const number = Object.keys(newArray[0]?.value).length;
    let count = 1;
    let fieldMapping = {}
    for (let index = 0; index < number; index++) {
        let content = ' '
        if (number > 3) {
            if (count > 3) {
                count = 1;
            }
            content = ' ' + count;
        } else {
            content = ''
        }

        fieldMapping["Giờ thấp điểm" + content] = "Off-peak Hour" + content;
        fieldMapping["Giờ bình thường" + content] = "Normal Hour" + content;
        fieldMapping["Giờ cao điểm" + content] = "Peak Hour" + content;

        count++;
    }

    const transformedArray = newArray.map(item => {
        const transformedObject = {};
        for (const oldField in item.value) {
            if (oldField in fieldMapping) {
                transformedObject[fieldMapping[oldField]] = item.value[oldField];
            } else {
                transformedObject[oldField] = item.value[oldField];
            }
        }
        item.value = transformedObject;
        return item;
    });

        // console.log(transformedArray[0].value)
    return transformedArray;
}



