import { Box, Button, FormHelperText, Grid, TextField } from "@mui/material";
import { useState } from "react";
import { styled } from "@mui/material/styles";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import useValidator from "../../../utils/Validator";
import MapContainer from "./MapContainer";
import Axios from "../../../utils/Axios";
import { setListPlace, setValueTabPlaceHeader } from "../../../utils/store/actions";
import ChangListPlace from "../../../layout/menu-items/changeListPlace";
import Loading from "../../Loading";
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import GeneralForm from "./GeneralForm";
import { useTranslation } from "react-i18next";


dayjs.locale('en'); // Set the desired locale
dayjs.extend(require('dayjs/plugin/isSameOrAfter'));
dayjs.extend(require('dayjs/plugin/isSameOrBefore'));
dayjs.extend(require('dayjs/plugin/advancedFormat'));

const styleInputFullField = {
    width: "100%",
};

const key = 'AIzaSyA1i6_c7sC-QA1QGrodUWwWxzMjszvdRk4';

const CustomButton = styled
    // eslint-disable-next-line no-empty-pattern
    (Button)(({ }) => ({
        backgroundColor: '#0958d9', // màu nền button
        color: 'white', // màu chữ button
        fontWeight: 'bold',
        borderRadius: 8,
        marginBottom: 18,
        paddingLeft: 30,
        paddingRight: 30,
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#08979c', // màu nền khi hover vào button
        },
    }));

export default function NewPlaces() {
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const { validatePlace } = useValidator();

    const [markers, setMarkers] = useState([]);
    const [showLoading, setShowLoading] = useState(false);
    const [type, setType] = useState('');
    const [errorType, setErrorType] = useState(false);
    const [inputValues, setInputValues] = useState({})
    const [errors, setErrors] = useState({});
    const [place, setPlace] = useState({});
    const [showErrorCheckBoxPeak, setShowErrorCheckBoxPeak] = useState(false);
    const [showErrorCheckBoxOffPeak, setShowErrorCheckBoxOffPeak] = useState(false);
    const [showErrorCheckBoxStand, setShowErrorCheckBoxStand] = useState(false);
    const [standardInterval, setStandardInterval] = useState([{
        startTime: null, endTime: null, status: true, dayOfWeek: [false, false, false, false, false, false, false]
    }]);
    const [peekInterval, setPeekInterval] = useState([{
        startTime: null, endTime: null, status: true, dayOfWeek: [false, false, false, false, false, false, false]
    }]);


    const [offPeakInterval, setOffPeakInterval] = useState([{
        startTime: null, endTime: null, status: true, dayOfWeek: [false, false, false, false, false, false, false]
    }]);

    useEffect(() => {
        dispatch(setValueTabPlaceHeader(1))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChange = (event) => {
        setErrorType(false);
        setType(event.target.value);
    };



    const handleMapClick = (mapProps, map, clickEvent) => {
        const lat = clickEvent.latLng.lat();
        const lng = clickEvent.latLng.lng();

        const newMarker = {
            position: clickEvent.latLng,
            name: 'New Marker',
            lat,
            lng,
        };

        setMarkers([newMarker]);
        setErrors({
            ...errors,
            lat: '',
            lng: ''
        })
        // =========================
        place.lat = lat;
        place.lng = lng;
        setPlace({ ...place, ...place });
    };

    const googleApiKey = key;
    const initialZoom = 12;
    const initialCenter = {
        name: 'Current location',
        lat: 10.814613391264876, // Vĩ độ của vị trí
        lng: 106.63165072082994, // Kinh độ của vị trí
    };


    const handleOnSubmit = (e) => {
        e.preventDefault();
        let target = Array.from(e.target);
        let validForm = true;
        let error = {};
        target.forEach((data) => {
            if (data.name) {
                let err = validatePlace(data.name, data.value);
                if (err) {
                    error[data.name] = err;
                    validForm = false;
                }
            }
            if (type.length === 0) {
                validForm = false;
                setErrorType(true);
            } else {
                setErrorType(false);
            }
        });
        setErrors(() => ({ ...errors, ...error }));
        const intervalPeekInterval = ElectricConsumptionTariff(peekInterval)
        const intervalOffPeakInterval = ElectricConsumptionTariff(offPeakInterval)
        const intervalStandardInterval = ElectricConsumptionTariff(standardInterval)

        const checkPeek = checkDayOfWeekArray(intervalPeekInterval)
        const checkOfPeek = checkDayOfWeekArray(intervalOffPeakInterval)
        const checkStand = checkDayOfWeekArray(intervalStandardInterval)
        if(!checkPeek || !checkOfPeek || !checkStand){
            validForm = false;
        }

        if(!checkOfPeek){
            setShowErrorCheckBoxOffPeak(true)
        }
        if(!checkPeek){
            setShowErrorCheckBoxPeak(true)
        }
        if(!checkStand){
            setShowErrorCheckBoxStand(true)
        }

        if (validForm) {
            let listCoordinates = [place.lat, place.lng];

            //  ====
            let data = {}
            data.name = place.name;
            data.address = place.address;
            data.location = {
                coordinates: listCoordinates
            }
            data.electricConsumptionTariff = {
                offPeak: {
                    price: place.offPeakPrice,
                    interval: intervalOffPeakInterval
                },
                standard: {
                    price: place.standardPrice,
                    interval: intervalStandardInterval
                },
                peak: {
                    price: place.peakPrice,
                    interval: intervalPeekInterval
                }
            }
            data.type = type;
            createPlace(data);
        } else {
            toast.error(`${t('Vui lòng điền đầy đủ thông tin')}!`);
        }
    };

    function checkDayOfWeekArray(array) {
        for (let i = 0; i < array.length; i++) {
            if (array[i].dayOfWeek.length === 0) {
                return false;
            }
        }
        return true;
    }

    function ElectricConsumptionTariff(data) {
        const intervalPeekInterval = []
        data?.forEach(element => {
            if(element.status){
                const dayOfWeek = element.dayOfWeek;
                const listCheckBox = [];
                for (let index = 0; index < dayOfWeek.length; index++) {
                    if (dayOfWeek[index] === true) {
                        let number = index + 2
                        if (number === 8) {
                            number = 1;
                        }
                        listCheckBox.push(number)
                    }
                }
                intervalPeekInterval.push({
                    startTime: element?.startTime?.$H + ':' + element?.startTime?.$m,
                    endTime: element?.endTime?.$H + ':' + element?.endTime?.$m,
                    dayOfWeek: listCheckBox
                }
                )
            }
        });
        return intervalPeekInterval;
    }

    const createPlace = async (data) => {
        setShowLoading(() => true);
        const response = await Axios.Place.create(data);
        if (response.status === 200) {
            reset();
            setShowLoading(() => false);
            toast.success(t('Thêm thành công'));
            ChangListPlace(0, 10, "", "", "createdAt").then(data => {
                dispatch(setListPlace(data));
            });



        } else {
            if (response.status === 400 && response.code === 5) {
                toast.error(`${t('Tên địa điểm đã tồn tại')}!`);
                setErrors({
                    name: `${t('Tên địa điểm đã tồn tại')}!`
                })
            }
            setShowLoading(() => false);
        }
    };

    const handleOnInput = (event) => {
        const { name, value } = event.target;
        // console.log(name,'-',value)
        setInputValues({ ...inputValues, [name]: value });
        setErrors({ ...errors, [name]: validatePlace(name, value) });
    };

    const reset = () => {
        setErrors({})
        setPlace({})
        setMarkers([])
        setType('');
        setErrorType(false);

        setStandardInterval([{
            startTime: null, endTime: null, status: true, dayOfWeek: [false, false, false, false, false, false, false]
        }]);
       setPeekInterval([{
            startTime: null, endTime: null, status: true, dayOfWeek: [false, false, false, false, false, false, false]
        }]);
       setOffPeakInterval([{
            startTime: null, endTime: null, status: true, dayOfWeek: [false, false, false, false, false, false, false]
        }]);
    }


    return (
        <>
            <title>{t('Thêm địa điểm')}</title>
            <form onSubmit={handleOnSubmit}>
                <Box sx={{ minHeight: '30px' }}>
                </Box>
                <Box sx={{ borderRadius: 2, boxShadow: 2, width: '90%', margin: 'auto', mb: 5 }}>
                    <Grid container >
                        <Grid item xs={6} sm={6} md={6} >
                            <Box sx={{ width: '90%', margin: 'auto' }}>
                                <TextField
                                    name="name"
                                    id="outlined-basic"
                                    label={t('Tên')}
                                    multiline
                                    maxRows={3}
                                    value={place?.name || ''}
                                    variant="standard"
                                    color="secondary"
                                    sx={styleInputFullField}
                                    error={errors.name ? true : false}
                                    helperText={
                                        errors.name ? (
                                            errors.name
                                        ) : (
                                            <Box component="span" >
                                                &nbsp;
                                            </Box>
                                        )
                                    }
                                    onChange={(e) => {
                                        setPlace({ ...place, name: e.target.value });
                                    }}
                                    onInput={handleOnInput}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={6} sm={6} md={6} >
                            <Box sx={{ width: '90%', margin: 'auto' }}>
                                <TextField
                                    name="address"
                                    id="outlined-basic"
                                    label={t('Địa chỉ')}
                                    multiline
                                    maxRows={3}
                                    value={place?.address || ''}
                                    variant="standard"
                                    color="secondary"
                                    sx={styleInputFullField}
                                    error={errors.address ? true : false}
                                    helperText={
                                        errors.address ? (
                                            errors.address
                                        ) : (
                                            <Box component="span" >
                                                &nbsp;
                                            </Box>
                                        )
                                    }
                                    onChange={(e) => {
                                        setPlace({ ...place, address: e.target.value });
                                    }}
                                    onInput={handleOnInput}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={6} md={6} >
                            <Box sx={{ width: '90%', margin: 'auto' }}>
                                <FormControl variant="standard" sx={{ width: '100%' }} error={errorType} >
                                    <InputLabel id="demo-simple-select-standard-label">{t('Loại')}</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={type}
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={'singlePhase'}>1 {t('pha')}</MenuItem>
                                        <MenuItem value={'threePhase'}>3 {t('pha')}</MenuItem>
                                        <MenuItem value={'solar'}>Solar</MenuItem>
                                    </Select>
                                    {errorType && <FormHelperText>{t('Loại phải bắt buộc nhập!')}</FormHelperText>}
                                </FormControl>
                            </Box>

                        </Grid>

                        <Grid item xs={12} sm={6} md={5} sx={{ margin: 'auto' }} >
                            <Box sx={{ width: '90%', display: 'flex' }}>
                                <TextField
                                    name="lat"
                                    id="outlined-basic"
                                    label={t('Vĩ độ')}
                                    variant="standard"
                                    color="secondary"
                                    value={place?.lat || ''}
                                    sx={{
                                        ...styleInputFullField, styleInputFullField,
                                        ml: 3
                                    }}
                                    error={errors.lat ? true : false}
                                    helperText={
                                        errors.lat ? (
                                            errors.lat
                                        ) : (
                                            <Box component="span" >
                                                &nbsp;
                                            </Box>
                                        )
                                    }
                                    onChange={(e) => {
                                        setPlace({ ...place, lat: e.target.value });
                                    }}
                                    onInput={handleOnInput}
                                />
                                <TextField
                                    name="lng"
                                    id="outlined-basic"
                                    label={t('Kinh độ')}
                                    variant="standard"
                                    color="secondary"
                                    value={place?.lng || ''}
                                    sx={{
                                        ...styleInputFullField, styleInputFullField,
                                        ml: 3
                                    }}
                                    error={errors.lng ? true : false}
                                    helperText={
                                        errors.lng ? (
                                            errors.lng
                                        ) : (
                                            <Box component="span" >
                                                &nbsp;
                                            </Box>
                                        )
                                    }
                                    onChange={(e) => {
                                        setPlace({ ...place, lng: e.target.value });
                                    }}
                                    onInput={handleOnInput}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} sx={{ mt: 3,mb:3 }}>
                            <Box sx={{ width: '90%', margin: 'auto', height: '350px'}}>
                                <MapContainer
                                    googleApiKey={googleApiKey}
                                    initialZoom={initialZoom}
                                    initialCenter={initialCenter}
                                    markers={markers}
                                    handleMapClick={handleMapClick}
                                />
                            </Box>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} sx={{ mt: 3,mb:3 }}>
                            <GeneralForm localizations={offPeakInterval} setLocalizations={setOffPeakInterval}
                                place={place}
                                setPlace={setPlace}
                                errors={errors}
                                setErrors={setErrors}
                                handleOnInput={handleOnInput}
                                titleValue={'offPeakPrice'}
                                name={'Giá thấp điểm'}
                                showErrorCheckBox={showErrorCheckBoxOffPeak}
                                setShowErrorCheckBox={setShowErrorCheckBoxOffPeak}
                            />
                        </Grid>

                        <Grid item xs={12} sm={12} md={12} sx={{ mt: 3,mb:3 }}>
                            <GeneralForm localizations={standardInterval} setLocalizations={setStandardInterval}
                                place={place}
                                setPlace={setPlace}
                                errors={errors}
                                setErrors={setErrors}
                                handleOnInput={handleOnInput}
                                titleValue={'standardPrice'}
                                name={'Giá bình thường'}
                                showErrorCheckBox={showErrorCheckBoxStand}
                                setShowErrorCheckBox={setShowErrorCheckBoxStand}
                            />
                        </Grid>



                        <Grid item xs={12} sm={12} md={12} >
                            <GeneralForm localizations={peekInterval} setLocalizations={setPeekInterval}
                                place={place}
                                setPlace={setPlace}
                                errors={errors}
                                setErrors={setErrors}
                                handleOnInput={handleOnInput}
                                titleValue={'peakPrice'}
                                name={'Giá cao điểm'}
                                showErrorCheckBox={showErrorCheckBoxPeak}
                                setShowErrorCheckBox={setShowErrorCheckBoxPeak}
                            />
                        </Grid>




                 
                        <Grid item xs={12} sm={12} md={12} sx={{ mt: 2 }} >
                            <Box sx={{ width: '100%', margin: 'auto', textAlign: 'center', }}>
                                <CustomButton disabled={showLoading} type="submit" sx={{ ml: -1.5 }} >{t('Tạo')}</CustomButton>
                                <CustomButton onClick={reset} sx={{ background: '#f5222d', ml: 1.5, }}>{t('Đóng')}</CustomButton>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>

            </form>
            <Loading showLoading={showLoading} />
        </>
    )
}