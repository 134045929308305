
// action - state management
import * as actionTypes from './actions';
import config from './../../config';

export const initialState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    opened: localStorage.getItem("opened") === "false" ? false : true,
    mode: localStorage.getItem("theme") ? localStorage.getItem("theme") : "light",
};

export const valueTabSystemHeader = {};
export const valueTabPlaceHeader = {};
export const listPlace = {}

// ==============================|| CUSTOMIZATION REDUCER ||============================== //

const customizationReducer = (state = initialState, action) => {
    let id;
    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case 'setTheme':
            return {
                ...state,
                mode: action.payload
            }
        default:
            return state;
    }
};


const ReducerValueTabSystemHeader = (state = valueTabSystemHeader, action) => {
    let data = action.payload;
    switch (action.type) {
        case 'setValueTabSystemHeader':
            return {
                ...state,
                data
            }
        default:
            return state;
    }
};

const ReducerValueTabPlaceHeader = (state = valueTabPlaceHeader, action) => {
    let data = action.payload;
    switch (action.type) {
        case 'setValueTabPlaceHeader':
            return {
                ...state,
                data
            }
        default:
            return state;
    }
};

const ReducerListPlace = (state = listPlace, action) => {
    let data = action.payload;
    switch (action.type) {
        case 'setListPlace':
            return {
                ...state,
                data
            }
        default:
            return state;
    }
};

export {
    customizationReducer,
    ReducerValueTabSystemHeader,
    ReducerValueTabPlaceHeader,
    ReducerListPlace
};
