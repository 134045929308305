import { useTranslation } from "react-i18next";

export default function useValidator() {
  const { t } = useTranslation();
  const validateRequired = (name, value, error) => {
    if (!value || value === 0) {
      return false;
    }
    return true;
  };

  const validate = (name, value) => {
    const firstChar = name.substring(0, 1).toUpperCase();
    if (!validateRequired(name, value)) {
      return t(firstChar + name.substring(1) + " phải bắt buộc nhập!");
    }
  };

  const validateChangePassword = (name, value) => {
    if (name === "newpassword" || name === "newPassword") {
      name = "Mật khẩu mới"
    }
    if (name === "confirmpassword") {
      name = "Xác nhận mật khẩu"
    }
    if (name === "oldPassword") {
      name = "Mật khẩu cũ"
    }

    const firstChar = name.substring(0, 1).toUpperCase();
    if (!validateRequired(name, value)) {
      return t(firstChar + name.substring(1) + " phải bắt buộc nhập!");
    }
  };

  const validatePlace = (name, value) => {
    if(name==="offPeakPrice" || name==="standardPrice" || name==="peakPrice"){

      if(isNaN(value)){
        return t('Bắt buộc phải nhập số!');

      }

    }

    if (!validateRequired(name, value)) {

      if (name === "lat") {
        name = "Vĩ độ"
      }
      if (name === "lng") {
        name = "Kinh độ"
      }
      if (name === "name") {
        name = "Tên"
      }
      if (name === "address") {
        name = "Địa chỉ"
      }
      if (name === "standardPrice") {
        name = "Giá chuẩn"
      }
      if (name === "peakPrice") {
        name = "Giá cao điểm";
      }

      if (name === "offPeakPrice") {
        name = "Giá thấp điểm"
      }
      if (name.includes('peakPriceendTime') || name.includes('standardPriceendTime') || name.includes('offPeakPriceendTime') || name.includes("peakPricestartTime") || name.includes("standardPricestartTime") || name.includes('offPeakPricestartTime')) {
        name = "Thời gian"
      }
      return t(name + " phải bắt buộc nhập!");
    }
  };

  const validateHeatMap = (name, value) => {

    if(name.includes('from') || name.includes('to')){

      if(isNaN(value)){
        return t('Bắt buộc phải nhập số!');

      }

    }
    
    if (!validateRequired(name, value)) {
      if (name.includes('name')) {
        name = "Tên cảnh báo"
      }
      if (name.includes('from') || name.includes('to')) {
        name = "Giá trị"
      }
      return t(name + " phải bắt buộc nhập!");
    }
  };


  return {
    validate,
    validateChangePassword,
    validatePlace,
    validateHeatMap
  };
}
