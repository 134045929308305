import { Navigate, useRoutes } from "react-router-dom";
import MainLayout from "./layout/MainLayout";
import LoginPage from "./pages/LoginPage/LoginPage";

import NewPlace from "./components/place/newPlace/NewPlaces";
import useLogin from "./utils/Login/useLogin";
import ChangePassword from "./pages/ChangePassword/ChangePassword";
import MyPlaces from "./components/place/myPlace/MyPlace";
import EditPlaces from "./components/place/editPlace/EditPlaces";
import EnergySystems from "./components/EnergySystem/EnergySystems";
import Equipmennt from "./components/Equipment/Equipment";
import Dashboard from "./components/Dashboard";
import Parameters from "./components/Parameters";



export default function Router() {
    const { account } = useLogin();


    const routes = useRoutes([
        {
            path: "/login",
            element: <LoginPage />,
        },
        {
            element: (!account || account.status === false) ? <Navigate to="/login" /> : <MainLayout />,
            children: [
                {
                    path: "/dashboard",
                    element: <Dashboard />,
                },
                {
                    path: "/",
                    element: <Dashboard />,
                },
                {
                    path: "/new-place",
                    element: <NewPlace />,
                },
                {
                    path: "/changePassword",
                    element: <ChangePassword />,
                },
                {
                    path: "/my-place",
                    element: <MyPlaces />,
                },
                {
                    path: "/edit-place/:_id",
                    element: <EditPlaces />,
                },
                {
                    path: "/energy-system/:_id",
                    element: <EnergySystems />,
                },
                {
                    path: "/equipment",
                    element: <Equipmennt />,
                },
                {
                    path: "/parameters",
                    element: <Parameters />,
                }

                


            ]
        },
        {
            path: "/*",
            element: <MainLayout />,
        },

    ]);
    return routes;
}