import { Grid, Box, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import Iconify from "../iconify/Iconify";
const Container = styled(Box)({
    flexGrow: 1,
    maxHeight: '85vh',
    minHeight: '35vh',
    overflow: 'auto',
});



export default function Equipmennt() {

    function generateList(count) {
        const list = [];

        for (let i = 0; i < count; i++) {
            const name = Math.random().toString(36).substring(7); // Tạo tên ngẫu nhiên
            const data = [1, 2]; // Dữ liệu mẫu

            list.push({ name, data });
        }

        return list;
    }

    // Sử dụng hàm generateList và truyền vào số lượng phần tử cần lấy
    const result = generateList(50);


    return (
        <>
            <title>Equipment</title>

            <Grid container spacing={0}>
                <Grid item xs={12} sm={12} md={12} sx={{ minHeight: '90vh', }} >
                    <Container>
                        <Grid container spacing={0}>
                            {result.map((item, index) => {
                                return <Grid key={index} item xs={12} sm={6} md={3} sx={{ mt: 1 }}>
                                    <Box sx={{
                                        boxShadow: 1, borderRadius: 2, padding: 1, width: '90%', margin: 'auto',
                                        border: '1px solid #87e8de',
                                        background: localStorage.getItem("theme") === "dark" ? "rgb(81, 81, 81)" : 'white',
                                    }}>
                                        <Typography>Name: System {index}</Typography>
                                        <Box sx={{ display: 'flex', }}>
                                            <Typography>Total Energy Demand: </Typography>
                                            <Typography sx={{ marginLeft: 'auto' }}>{index + 1 * 300}0000 KW </Typography>
                                        </Box>

                                        <Box sx={{ display: 'flex' }}>
                                            <Typography>Status:</Typography>
                                            {index % 6 ?
                                                <Iconify icon="fontisto:radio-btn-active" width={20} height={20} sx={{ color: '#52c41a', mt: 0.4, marginLeft: 'auto' }} />
                                                :
                                                <Iconify icon="material-symbols:error-outline" width={20} height={20} sx={{ color: '#f5222d', mt: 0.4, marginLeft: 'auto' }} />
                                            }
                                        </Box>
                                    </Box>
                                </Grid>
                            })}

                        </Grid>
                    </Container>
                </Grid>
            </Grid>

        </>
    )
}