
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { TableVirtuoso } from 'react-virtuoso';
import React from 'react';
import { Typography, useMediaQuery, useTheme } from '@mui/material';

const scrollbar = {
    "::-webkit-scrollbar": {
        width: "5px",
    },
    ":hover::-webkit-scrollbar-thumb": {
        " -webkit-border-radius": "5px",
        borderRadius: "5px",
        background: "#dee2e3",
    },
    "::-webkit-scrollbar-thumb:window-inactive": {
        background: "#dee2e3",
    },
};

const VirtuosoTableComponents = {
    Scroller: React.forwardRef((props, ref) => (
        <TableContainer component={Paper} {...props}
            sx={{
                ...scrollbar,
                cursor: 'pointer',
            }}
            ref={ref} />
    )),
    Table: (props) => (
        <Table {...props} sx={{ borderCollapse: 'separate', tableLayout: 'fixed' }} />
    ),
    TableHead,
    TableRow: ({ item: _item, ...props }) => <TableRow {...props} />,
    TableBody: React.forwardRef((props, ref) => <TableBody {...props} ref={ref} />),
};


export default function TableReport({ TABLE_HEAD, listData }) {
    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    const [hoveredRow, setHoveredRow] = React.useState(null);
    function handleRowHover(index) {
        setHoveredRow(index);
    }


    function rowContent(_index, row) {
        const isRowHovered = _index === hoveredRow;
        return (
            <>
                {TABLE_HEAD.map((column) => (
                    <TableCell
                        size="small"
                        key={column.id}
                        align={column.numeric || true ? 'center' : 'right'}
                        sx={{
                            cursor: "pointer"
                            , backgroundColor: isRowHovered ? 'rgba(0, 0, 0, 0.1)' : 'transparent',
                            height: 10,
                        }}
                        onMouseEnter={() => handleRowHover(_index)}
                        onMouseLeave={() => handleRowHover(null)}
                    >
                        {row[column.id]}
                    </TableCell>
                ))}
            </>

        );
    }
    function fixedHeaderContent() {
        return (
            <TableRow >
                {TABLE_HEAD.map((column) => (
                    <TableCell
                        key={column.id}
                        variant="head"
                        align={column.numeric || true ? 'center' : 'right'}
                        style={{ width: column.width, color: 'white' }}
                        sx={{ background: '#1660b6',fontWeight:600 }}
                    >
                        {column.label}
                    </TableCell>
                ))}
            </TableRow>
        );
    }

    return (
        <>
            <Paper style={{
                height: listData.length > 0 ? matchDownMd?'60vh':'75vh' : '0vh', width: '96%',
                margin:'auto',
                border: '1px solid rgb(224 224 224)',
            }}>
                {listData.length === 0 && <Typography sx={{ textAlign: 'center',mt:1 }}>No Data </Typography>}
                <TableVirtuoso
                    data={listData}
                    components={VirtuosoTableComponents}
                    fixedHeaderContent={fixedHeaderContent}
                    itemContent={rowContent}
                />
            </Paper>
            {listData.length > 0 && <Typography sx={{ float: 'right',mt:1,mr:5 }}>{listData?.length} alarm records </Typography>}
        </>
    );

}