/* eslint-disable array-callback-return */
import { Box, Button, Typography } from "@mui/material";
import { useState } from "react";
import { ColorPicker, DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { styled } from "@mui/material/styles";
import schedule from 'node-schedule';
import { toast } from "react-toastify";


import Chart from "./Chart/Chart";
import Axios from "../../../../utils/Axios";
import styleHeatMap from "./heatMap.module.scss";
import Loading from "../../../Loading";
import { useTranslation } from "react-i18next";
import logApiErrorGet from "../../../../utils/Axios/LogApiError";
import Iconify from "../../../iconify/Iconify";
import useValidator from "../../../../utils/Validator";





const { RangePicker } = DatePicker;

const rangePresets = [
    {
        label: 'Last 7 Days',
        value: [dayjs().add(-7, 'd'), dayjs()],
    },
    {
        label: 'Last 14 Days',
        value: [dayjs().add(-14, 'd'), dayjs()],
    },
    {
        label: 'Last 30 Days',
        value: [dayjs().add(-30, 'd'), dayjs()],
    },
    {
        label: 'Last 90 Days',
        value: [dayjs().add(-90, 'd'), dayjs()],
    },
];

const dateFormat = 'DD/MM/YYYY';
const customFormat = (value) => `${value.format(dateFormat)}`;

const CustomButton = styled
    // eslint-disable-next-line no-empty-pattern
    (Button)(({ }) => ({
        backgroundColor: '#0958d9', // màu nền button
        color: 'white', // màu chữ button
        fontWeight: 'bold',
        borderRadius: 8,
        marginBottom: 18,
        paddingLeft: 30,
        paddingRight: 30,
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#08979c', // màu nền khi hover vào button
        },
    }));

export default function HeatMaps() {
    const { _id } = useParams();
    const { t } = useTranslation();

    const { validateHeatMap } = useValidator();

    const [showLoading, setShowLoading] = useState(false);
    const [series, setSeries] = useState();
    const [startDate, setStartDate] = useState(dayjs().set('hour', 0).set('minute', 0).set('second', 0).subtract(15, 'day'));
    const [endDate, setEndDate] = useState(dayjs().set('hour', 0).set('minute', 0).set('second', 0).add(15, 'day'));
    const [options, setOptions] = useState();
    const [open, setOpen] = useState(false);
    const [listWarning, setListWarning] = useState([])
    const [errors, setErrors] = useState({});
    const [inputValues, setInputValues] = useState({})
    const [idWarningHeatMap, setIdWarningHeatMap] = useState()


    useEffect(() => {
        getValueForHeatMap(_id, startDate, endDate);
        const job = schedule.scheduleJob('0 * * * *', () => {
            getValueForHeatMap(_id, startDate, endDate);
          });
      
          return () => {
            job.cancel(); 
          };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id, startDate, endDate])

    useEffect(() => {
        if (options) {
            const newOptions = JSON.parse(JSON.stringify(options));
            newOptions.xaxis = {
                labels: {
                    style: {
                        colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                    },
                }
            };
            newOptions.yaxis = {
                labels: {
                    formatter: function (value) {
                        if (value === '') {
                            return "";

                        } else {
                            return value + ":00";
                        }
                    },
                    style: {
                        colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                    },
                },
            }
            setOptions(newOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("theme")])

    useEffect(()=>{
        if(options){
            const newArray = JSON.parse(JSON.stringify(options.plotOptions.heatmap.colorScale.ranges));
            setListWarning(newArray)
        }
    },[options])

    const handleOption = (listRanges) => {
        setOptions({
            chart: {
                height: 350,
                type: 'heatmap',
            },
            plotOptions: {
                heatmap: {
                    shadeIntensity: 0.5,
                    radius: 0,
                    useFillColorAsStroke: false,
                    colorScale: {
                        ranges: listRanges,
                    },
                },
            },
            dataLabels: {
                enabled: false,
            },
            stroke: {
                width: 1,
                // colors: ['#d9d9d9', ]
            },
            xaxis: {
                labels: {
                    formatter: function (value) {
                        return value;
                    },
                    style: {
                        colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                    },
                },
                axisTicks: {
                    show: true // tach dau gach tren truc x
                },

            },
            yaxis: {
                labels: {
                    formatter: function (value) {
                        if (value === '') {
                            return "";

                        } else {
                            return value + ":00";

                        }
                    },
                    style: {
                        colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                    },
                },
            },
            tooltip: {
                enabled: true,
                custom: function ({ seriesIndex, dataPointIndex, w }) {
                    // seriesIndex: chỉ mục của chuỗi dữ liệu
                    // dataPointIndex: chỉ mục của điểm dữ liệu trong chuỗi
                    // w: đối tượng biểu đồ ApexCharts
                    // Lấy giá trị của ô
                    const value = w.config.series[seriesIndex].data[dataPointIndex];
                    // Tạo nội dung tooltip tuỳ chỉnh
                    const tooltipContent = `
                    <div class="${styleHeatMap["custom-tooltip"]}">
                      <span class="${styleHeatMap["tooltip-value"]}">${seriesIndex}:00 - ${value.x} </span>
                      <div class="${styleHeatMap["tooltip-label"]}">${t('Giá trị')}: ${value.y} kWh</div>
                    </div>
                  `;

                    // Trả về nội dung tooltip
                    return tooltipContent;
                },
            },
        })
    }

    const getValueForHeatMap = async (id, startDate, toDate) => {
        setShowLoading(true);
        try {
            const response = await Axios.Place.getValueForHeatMap(id, startDate, toDate);
            if (response?.status === 200) {
                setShowLoading(false);
                getDataSeries(response.data.values, startDate, toDate);
                heatmapWarning(id);
            } else {
                logApiErrorGet(setShowLoading, "getValueForHeatMap", response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "getValueForHeatMap", error);

        }
    }


    const heatmapWarning = async (id) => {
        setShowLoading(true);
        try {
            const response = await Axios.Place.heatmapWarning(id);
            if (response?.status === 200) {
                const listRanges = []
                for (let index = 0; index < response.data.listValue.length; index++) {
                    const element = response.data.listValue[index];
                    listRanges.push({ from: element.startValue, to: element.endValue, name: `(${element.startValue} < ${element.endValue} )`, color: element.color, title: element.name, status: true })
                }
                setIdWarningHeatMap(response.data._id);
                setShowLoading(false);
                handleOption(listRanges);
                // setListWarning(listRanges)
            } else {
                logApiErrorGet(setShowLoading, "heatmapWarning", response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "heatmapWarning", error);

        }
    }

    const updateHeatmapWarning = async (id, data) => {
        setShowLoading(true);
        try {
            const response = await Axios.Place.updateHeatmapWarning(id, data);
            if (response?.status === 200) {
                setShowLoading(false);
                toast.success(t('Cập nhật thành công'));
                handleClose();
                getValueForHeatMap(_id,startDate, endDate);
            } else {
                logApiErrorGet(setShowLoading, "updateHeatmapWarning", response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "updateHeatmapWarning", error);

        }
    }

    const getDataSeries = (listSeries, startDate, toDate,) => {
        // ======================================================
        if (listSeries[0]?.value) {

            const new_array = Object.keys(listSeries[0].value);
            const resultList = [];
            new_array.forEach((element, index) => {
                const temporary = [];
                const startTime = new Date(startDate);
                const toTime = new Date(toDate);
                const numberOfDays = Math.round((toTime - startTime) / (1000 * 60 * 60 * 24));
                const currentDate = startTime;

                const newResult = listSeries.map(item => {
                    const valueKeys = Object.keys(item.value);
                    const newValue = {
                        [valueKeys[index]]: item.value[valueKeys[index]]
                    };
                    return {
                        "value": newValue,
                        "ts": item.ts
                    };
                });

                for (var i = 0; i <= numberOfDays; i++) {
                    const timestampsList = getListOfTimestampsInDay(currentDate);
                    const newList = timestampsList.map(x => {
                        const matchingItem = newResult.find(item => roundSecondsToZero(item.ts) === x);
                        let getValue = 0
                        if (matchingItem) {
                            const valueItem = Object.values(matchingItem.value);
                            getValue = valueItem[0];
                        }
                        return {
                            x,
                            y: getValue
                        };
                    });
                    temporary.push({ ...newList })
                    currentDate.setDate(currentDate.getDate() + 1);
                }

                resultList.push({ name: element, data: temporary })
            });
            // console.log(resultList)

            const newListSeries = [];
            for (let index = 0; index < resultList.length; index++) {
                const element = resultList[index];
                const temporary = [];
                for (let jk = 0; jk < element.data.length; jk++) {
                    const item = element.data[jk];
                    for (let j = 0; j < 24; j++) {
                        temporary.push({ x: item[j].x, y: item[j].y })
                    }
                }
                newListSeries.push({ name: resultList[index].name, data: temporary })
            }
            const listData = [];
            // console.log(newListSeries)
            newListSeries.forEach(element => {
                const temporary = []
                for (let index = 0; index < 24; index++) {
                    const filteredList = element.data.filter(item => new Date(item.x).getHours() === index);
                    const newTimestampsList = filteredList.map(item => {
                        const date = new Date(item.x);


                        return {
                            x: formatVietnameseDate(date),
                            y: item.y
                        };
                    });
                    temporary.push({ name: index + '', data: newTimestampsList, })
                }

                listData.push({ name: element.name, data: temporary })
            });

            // console.log(listData)

            setSeries(listData)
        }

    }

    function formatVietnameseDate(dateString) {
        const daysOfWeek = ["CN", "Th2", "Th3", "Th4", "Th5", "Th6", "Th7"];
        const months = [
            "01", "02", "03", "04", "05", "06",
            "07", "08", "09", "10", "11", "12"
        ];

        const date = new Date(dateString);
        const dayOfWeek = daysOfWeek[date.getDay()];
        const day = date.getDate();
        const month = months[date.getMonth()];
        const year = date.getFullYear();

        return `${dayOfWeek},${day < 10 ? ('0' + day) : day}-${month}-${year}`;
    }

    function roundSecondsToZero(timestamp) {
        var date = new Date(timestamp);
        date.setSeconds(0);
        date.setMilliseconds(0);
        var roundedTimestamp = date.getTime();
        return roundedTimestamp;
    }

    function getListOfTimestampsInDay(timestamp) {
        const startOfDay = new Date(timestamp);
        startOfDay.setHours(0, 0, 0, 0);

        const endOfDay = new Date(timestamp);
        endOfDay.setHours(23, 59, 59, 999);

        const timestampsList = [];
        let currentTimestamp = startOfDay.getTime();

        while (currentTimestamp <= endOfDay.getTime()) {
            timestampsList.push(currentTimestamp);
            currentTimestamp += 3600000; // Thêm 1h (3600000 mili giây)
        }

        return timestampsList;
    }


    const onRangeChange = (dates, dateStrings) => {
        if (dates) {
            let newStartDate = dates[0].$d;
            let newEndDate = dates[1].$d;
            setStartDate(newStartDate)
            setEndDate(newEndDate)
        }
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setErrors({});
        const newArray = JSON.parse(JSON.stringify(options.plotOptions.heatmap.colorScale.ranges));
        setListWarning(newArray)
    };


    const handleAddLocalization = () => {
        setListWarning([...listWarning, { from: '', to: '', name: '', color: '#2f54eb', title: '', status: true }]);
    };


    const handleDeleteLocalization = (index) => {
        const updatedLocalizations = [...listWarning];
        updatedLocalizations[index].status = false;
        setListWarning([...updatedLocalizations])
        // ====
        const newObj = { ...errors };
        delete newObj["from" + index]
        delete newObj["to" + index]
        delete newObj["name" + index]

        setErrors(newObj)

    };
    const handleOnSubmit = (e) => {
        e.preventDefault();
        let target = Array.from(e.target);
        let validForm = true;
        let error = {};
        target.forEach((data) => {
            if (data.name) {
                let err = validateHeatMap(data.name, data.value);
                if (err) {
                    error[data.name] = err;
                    validForm = false;
                }
            }
        });
        setErrors(() => ({ ...errors, ...error }));
        if (validForm) {
            const listFilter = listWarning.filter(item => item.status);

            const updatedData = listFilter.map(item => ({
                name: item?.title,
                color: item?.color,
                startValue: Number(item?.from),
                endValue: Number(item?.to)
            }));

            const resultList = {
                placeId: _id,
                listValue: updatedData
            }

            updateHeatmapWarning(idWarningHeatMap, resultList)
        } else {
            toast.error(`${t('Vui lòng điền đầy đủ thông tin')}!`);
        }
    };

    const handleOnInput = (event) => {
        const { name, value } = event.target;
        setInputValues({ ...inputValues, [name]: value });
        setErrors({ ...errors, [name]: validateHeatMap(name, value) });
    };


    const handleChangeValue = (index, title, value) => {
        const updatedList = [...listWarning];
        updatedList[index] = {
            ...updatedList[index],
            [title]: value,
            name: `(${title === 'from' ? value : updatedList[index].from} < ${title === 'to' ? value : updatedList[index].to} )`
        };
        setListWarning(updatedList);
    };


    return (
        <>
            <Box sx={{
                width: '96%',
                height: '94%',
                margin: 'auto',
                borderRadius: 2,
                boxShadow: 2,
                background: localStorage.getItem("theme") === "dark" ? "rgb(81, 81, 81)" : 'white',
            }}>
                <Space direction="vertical" size={12} style={{ width: '100%', textAlign: 'center' }}>
                    <RangePicker
                        placement={"topLeft"}
                        format={customFormat}
                        size={'middle'}
                        defaultValue={[startDate, endDate]}
                        style={{ cursor: 'pointer', marginTop: '5px' }}
                        presets={rangePresets} onChange={onRangeChange}
                    />
                </Space>

                {series && options &&
                    <>
                        <div style={{
                            flexGrow: series.length > 1 ? 1 : 'none',
                            maxHeight: series.length > 1 ? '68vh' : '',
                            overflow: series.length > 1 ? 'auto' : '',
                            minHeight: series.length > 1 ? '480px' : ''
                        }}>
                            {series.map((item, index) => {
                                return <div key={index} style={{ position: 'relative' }}>
                                    <Typography sx={{ textAlign: 'center', fontWeight: 600, mt: 1 }}>Heat Maps {item.name}</Typography>
                                    <Iconify icon={'uil:setting'}
                                        onClick={handleClickOpen}
                                        sx={{ position: 'absolute', top: 25,zIndex:1, left: 5, cursor: 'pointer' }} />
                                    <div id="chart" style={{ cursor: 'pointer', height: series.length > 1 ? '58vh' : '65vh', minHeight: '490px', color: 'black' }} className={localStorage.getItem("theme") === 'dark' ? 'mode' : ''} >
                                        <Chart series={item.data} options={options} type="heatmap" />
                                    </div>
                                </div>
                            })}
                        </div>
                    </>

                }
            </Box>

            <Dialog open={open} onClose={handleClose}
                sx={{ zIndex: 13, }}
                maxWidth="800"
            >
                {open &&
                <form onSubmit={handleOnSubmit}>
                    <DialogTitle sx={{ width: '800px' }}>{t('Chỉnh sửa cảnh báo')}</DialogTitle>
                    <DialogContent >
                        {listWarning?.map((item, index) => {
                            if (item.status) {
                                return <Box key={index} sx={{ boxShadow: 2, p: 1, mt: 2, display: 'flex', position: 'relative' }}>
                                    <TextField 
                                    label={t('Tên cảnh báo')}
                                        name={'name' + index}
                                        variant="standard"
                                        value={item?.title}
                                        sx={{ width: '250px' }}
                                        error={errors['name' + index] ? true : false}
                                        helperText={
                                            errors['name' + index] ? (
                                                errors['name' + index]
                                            ) : (
                                                <Box component="span" >
                                                    &nbsp;
                                                </Box>
                                            )
                                        }
                                        onChange={(e) => {
                                            handleChangeValue(index, 'title', e.target.value);
                                        }}
                                        onInput={handleOnInput}
                                         />
                                    <TextField label={t('Giá trị bắt đầu')} 
                                        name={'from' + index}
                                        variant="standard"
                                        value={item?.from}
                                        error={errors['from' + index] ? true : false}
                                        helperText={
                                            errors['from' + index] ? (
                                                errors['from' + index]
                                            ) : (
                                                <Box component="span" >
                                                    &nbsp;
                                                </Box>
                                            )
                                        }
                                        onChange={(e) => {
                                            handleChangeValue(index, 'from', e.target.value);
                                        }}
                                        onInput={handleOnInput}
                                        sx={{ width: '100px', ml: '50px' }}
                                         />
                                    <TextField label={t('Giá trị cuối')}
                                        name={'to' + index}
                                        variant="standard"
                                        value={item?.to}
                                        sx={{ width: '100px', ml: '50px' }}
                                        error={errors['to' + index] ? true : false}
                                        helperText={
                                            errors['to' + index] ? (
                                                errors['to' + index]
                                            ) : (
                                                <Box component="span" >
                                                    &nbsp;
                                                </Box>
                                            )
                                        }
                                        onChange={(e) => {
                                            handleChangeValue(index, 'to', e.target.value);
                                        }}
                                        onInput={handleOnInput}
                                         />
                                    <div style={{ display: 'flex', marginTop: 8, width: '45px', marginLeft: '30px' }}>
                                        <ColorPicker
                                            style={{ width: '35px' }}
                                            value={item?.color}
                                            onChange={(e, value) => {
                                                handleChangeValue(index, 'color', value);
                                            }}
                                        />
                                        <span style={{ marginLeft: '10px', marginTop: '5px' }}>{item?.color}</span>
                                    </div>

                                    <Iconify icon={"iwwa:delete"}
                                        onClick={() => handleDeleteLocalization(index)}
                                        sx={{
                                            cursor: 'pointer',
                                            float: 'right',
                                            position: 'absolute',
                                            right: 0,
                                            mr: 0.5,
                                            top: 0
                                        }}
                                    />

                                </Box>
                            }

                        })}
                        <CustomButton
                            sx={{ background: '#a0d911' }}
                            onClick={handleAddLocalization}
                        >{t('Thêm')}</CustomButton>
                    </DialogContent>
                    <DialogActions >
                        <CustomButton
                            sx={{ background: '#f5222d' }}
                            onClick={handleClose}
                        >{t('Đóng')}</CustomButton>
                        <CustomButton type="submit" >{t('Cập nhật')}</CustomButton>
                    </DialogActions>
                </form>
            }
            </Dialog>


            <Loading showLoading={showLoading} />

        </>
    )

}