import PropTypes from "prop-types";
// @mui
import { styled } from "@mui/material/styles";
import {
  Toolbar,
  Tooltip,
  Typography,
  Grid,
} from "@mui/material";
// component
import React, { useState } from "react";
import { Box } from "@mui/system";
import Button from "@mui/material/Button";
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from "react";





import "./index.scss"
import DialogConfirm from "../../../../pages/Dialog/DialogConfirm";
import Iconify from "../../../iconify";
import { useNavigate } from "react-router-dom";

// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 100,
}));

// ----------------------------------------------------------------------

PlaceListToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
};

let titleSeleced = "Đã chọn";

export default function PlaceListToolbar({
  numSelected,
  selected,
  onChange,
  listData,
  showLoading,
  deletePlace,
  t
}) {
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [openDialog, setOpenDialog] = useState(false);


  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleDialogDelete = () => {
    deletePlace(selected);
    setOpenDialog(false);
  };

  useEffect(() => {
    if (open) {
      onChange(0, 10, -1, searchText, "createdAt");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText]);

  return (
    <StyledRoot>
      {numSelected > 0 ? (
        <>
          <Typography component="div" variant="subtitl1">
            <span style={{ fontWeight: "bold" }}>
              {numSelected}
            </span>{" "}
            {t(titleSeleced)}
            <Tooltip title={t('Xoá')} >
              <Button
                sx={{ color: "red", border: "none", mt: -0.5 }}
                onClick={handleClickOpen}
              >
                <Iconify icon="eva:trash-2-fill" sx={{ float: 'left' }} />
              </Button>
            </Tooltip>
          </Typography>
        </>

      ) : (
        <Box sx={{ width: '100%' }} >
          <Grid container spacing={0} >
            <Grid item xs={11} sm={10} md={6} >
              <Autocomplete
                id="asynchronous-demo"
                className="rounded-textfield"
                inputValue={searchText || ''}
                sx={{ width: '100%' }}
                open={open}
                onChange={(event, value) => {
                  navigate(`/edit-place/${value._id}`);
                }}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                isOptionEqualToValue={(option, value) => option.name === value.name}
                getOptionLabel={(option) => option.name}
                noOptionsText={t("Thông tin tìm kiếm không có")+'!'}
                options={listData}
                loading={showLoading}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Tìm kiếm...')}
                    onChange={(e, value) => {
                      setSearchText(e.target.value)
                    }}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {showLoading ? <CircularProgress color="inherit" size={25} /> : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                    onClick={(e) => { console.log(e) }}

                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      )}

      <DialogConfirm title={"xoá"} openDialog={openDialog} setOpenDialog={setOpenDialog} numSelected={numSelected} titleSeleced={titleSeleced} onChange={handleDialogDelete} />
    </StyledRoot>
  );
}
