import { useEffect, useState } from "react";
import { Box } from "@mui/material";




import Axios from "../../utils/Axios";
import Chart from "../EnergySystem/TabPanel0/components/Chart/Chart";
import logApiErrorGet from "../../utils/Axios/LogApiError";
import Loading from "../Loading";



export default function SimplePieChart() {

    const [showLoading, setShowLoading] = useState(false);

    const [series, setSeries] = useState();
    const [options, setOptions] = useState();

    useEffect(() => {
        getStatisticalOfAllPlace()
    }, [])

    const getStatisticalOfAllPlace = async () => {
        setShowLoading(true);
        try {
            const response = await Axios.Place.getStatisticalOfAllPlace();
            if (response?.status === 200) {
                setShowLoading(false);
                const listValue = response.data.consumption.map(item => item.P);
                const listName = response.data.consumption.map(item => item.place.name);

                setSeries(listValue)
                setOptions(
                    {
                        chart: {
                            width: 380,
                            type: 'pie',
                        },

                        labels: listName,
                        tooltip: {
                            custom: function ({ series, seriesIndex, dataPointIndex, w }) {
                                const colorCustom= w.globals.colors[seriesIndex];
                                const color = '#fff';
                                return '<div class="custom-tooltip" style="background-color:' + color + ' ;padding:10px;border: 5px solid '+colorCustom+'" >' +
                                    '<span style="color:black;">' + w.config.labels[seriesIndex] + ': ' + series[seriesIndex] + ' wKh</span>' +
                                    '</div>';
                            }
                        },
                        colors: ['#f5222d','#722ed1','#52c41a','#faad14','#eb2f96','#1677ff','#13c2c2','#fadb14','#a0d911','#ffbb96','#bfbfbf','#ffd6e7','#d3adf7','#87e8de','#ffa39e','#fffb8f','#eaff8f'],
                        responsive: [{
                            breakpoint: 480,
                            options: {
                                chart: {
                                    width: 200
                                },
                                legend: {
                                    position: 'bottom'
                                }
                            }
                        }],

                    }
                )
            } else {
                logApiErrorGet(setShowLoading, "getStatisticalOfAllPlace",response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "getStatisticalOfAllPlace", error);
        }
    }

    return <>
        <Box sx={{
            width: '96%',
            height: '90%',
            margin: 'auto',
            borderRadius: 2,
            boxShadow: 2,
            background: localStorage.getItem("theme") === "dark" ? "rgb(81, 81, 81)" : 'white',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            minWidth:'380px'
        }}>
            {series && options &&
                <div style={{ height: '30vh', minHeight: '250px', cursor: 'pointer' }} className={localStorage.getItem("theme") === 'dark' ? 'mode' : ''} >
                    <Chart series={series} options={options} type="pie" width={400} />
                </div>

            }
        </Box>
      <Loading showLoading={showLoading} />

    </>
}