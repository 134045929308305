// i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


const listValue = {
    'Thêm địa điểm': 'New Place',
    'Danh sách địa điểm': 'My Place',
    'Trạng thái thiết bị': 'Equipment',
    'Trang chủ': 'Dashboard',
    'Tất cả địa điểm':'Energy Systems',
    'Giám sát nguồn điện':'Power Monitoring',
    'Tổng ngày':'Total Day',
    'Giá trị hiện tại':'Current Value',
    'Tổng tháng':'Total Month',
    'Tổng năm':'Total Year',
    'Tổng quan':'Overview',
    "Phân tích báo cáo":'Report Analysis',
    'Chỉnh sửa địa điểm':'Edit place',
    'Tên':'Name',
    'Địa chỉ':'Address',
    'Loại':'Type',
    'Ngày tạo':'Created At',
    'Số hàng mỗi trang':'Rows per page',
    'Tìm kiếm...':'Search...',
    'Thông tin tìm kiếm không có':'Search information not available',
    'Năm':'Year',
    'Tháng':'Month',
    'Ngày':'Day',
    'Giá trị':'Value',
    'Tổng':'Total',
    'Đổi mật khẩu':'Change Password',
    'Xin chào':'Hi',
    'Đăng xuất':'Log out',
    'Thống kê khu vực':'Regional statistics',
    'Tiêu thụ điện lưới':'Grid power consumption',
    'Chi phí':'Money',
    'Giờ':'Hour',
    'Thấp điểm':'Off-peak',
    'Bình thường':'Normal',
    'Cao điểm':'Peak',
    'Ngày hiện tại':'Today',
    'Tích lũy tháng':'Accumulate month',
    'Tỉ lệ':'Ratio',
    'Tiền':'Money',
    'Chỉnh sửa cảnh báo':'Edit warning',
    'Đóng':'Cancel',
    'Cập nhật':'Update',
    'Thêm':'Add',
    'Vui lòng điền đầy đủ thông tin':'Please complete all information',
    'Cập nhật thành công':'Update successful',
    'Thêm thành công':'More success',
    'Đổi mật khẩu thành công':'Change password successfully',
    'Xoá thành công':'Delete successfully',
    'Đã sao chép thành công':'Successfully copied',
    'Không thể sao chép':`Can't copy`,
    'Xoá':'Delete',
    'Xoá thất bại!':'Erase failure!',
    'Chi tiết':'Detail',
    'Chỉnh sửa':'Edit',
    'Xác nhận xoá':'Confirm delete',
    'Đã chọn':'Selected',
    'Xác nhận':'Confirm',
    'Huỷ':'Cancel',
    'Tên địa điểm đã tồn tại':'The place name already exists',
    'Tạo':'Create',
    'Tên cảnh báo phải bắt buộc nhập!':'Alert name must be entered!',
    'Giá trị phải bắt buộc nhập!':'Alert name must be entered!',
    'Vĩ độ phải bắt buộc nhập!':'Latitude must be entered!',
    'Kinh độ phải bắt buộc nhập!':'Longitude must be entered!',
    'Tên phải bắt buộc nhập!':'Need to enter name!',
    'Địa chỉ phải bắt buộc nhập!':'Address is required to enter!',
    'Giá chuẩn phải bắt buộc nhập!':'Must enter standard price!',
    'Giá cao điểm phải bắt buộc nhập!':'The highest price must be entered!',
    'Giá thấp điểm phải bắt buộc nhập!':'Must enter low price!',
    'Thời gian phải bắt buộc nhập!':'Time cannot be left empty!',
    'Loại phải bắt buộc nhập!':'Type must be entered!',
    'pha':'phase',
    'Vĩ độ':'Latitude',
    'Kinh độ':'Longitude',
    'Giá thấp điểm':'Low price',
    'Giá cao điểm':'Peak price',
    'Giá bình thường':'Standard price',
    'Giờ bắt đầu':'Start time',
    'Giờ kết thúc':'Time end',
    'Đổi mật khẩu ':'Change Password',
    'Họ Và Tên':'First and last name',
    'Mật khẩu cũ':'Old password',
    'Mật khẩu mới':'A new password',
    'Nhập lại mật khẩu mới':'Enter your new password',
    'Nhập mật khẩu cũ':'Enter old password',
    'Nhập mật khẩu mới':'Enter your new password',
    'Mật khẩu cũ phải bắt buộc nhập!':'Must enter old password',
    'Mật khẩu mới phải bắt buộc nhập!':'New password required!',
    'Xác nhận mật khẩu phải bắt buộc nhập!':'Confirm password must be entered!',
    'Bắt buộc phải nhập số!':'Required to enter number',
    'Tên cảnh báo':'Warning name',
    'Giá trị bắt đầu':'Start value',
    'Giá trị cuối':'End value',
    'Kết nối':'Connect',
    'Thông số':'Parameters'
}

const reversedObject = Object.fromEntries(
    Object.entries(listValue).map(([key, value]) => [value, key])
  );

i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: listValue
        },
        vi: {
            translation: reversedObject
        }
    },
    lng: 'vi', // Ngôn ngữ mặc định
    fallbackLng: 'vi', // Ngôn ngữ sẽ sử dụng nếu không tìm thấy translation cho ngôn ngữ hiện tại
    interpolation: {
        escapeValue: false // Cho phép sử dụng HTML trong translation
    }
});

export default i18n;