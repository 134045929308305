import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";



const CustomButton = styled
    // eslint-disable-next-line no-empty-pattern
    (Button)(({ }) => ({
        backgroundColor: '#0958d9', // màu nền button
        color: 'white', // màu chữ button
        fontWeight: 'bold',
        borderRadius: 8,
        marginBottom: 18,
        paddingLeft: 30,
        paddingRight: 30,
        marginTop: 5,
        '&:hover': {
            backgroundColor: '#08979c', // màu nền khi hover vào button
        },
    }));


export default function DialogConfirm({ title,openDialog,setOpenDialog, numSelected, titleSeleced, onChange }) {
    const { t } = useTranslation();


    return (
        <>
            <Dialog
                open={openDialog}
                onClose={(e)=>{setOpenDialog(false)}}
            >
                <DialogTitle width={400} id="alert-dialog-title">
                    <Typography
                        component="div"
                        sx={{ fontSize: 20 }}
                        variant="subtitl1"
                    >
                        {`${t(`Xác nhận ${title}`)} ?`} 
                    </Typography>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <Typography
                            component="span"
                            sx={{ fontSize: 20, fontFamily: "bold" }}
                        >
                            <span style={{ fontWeight: "bold" }}>
                                {numSelected}
                            </span>{" "}
                            {t(titleSeleced)}
                        </Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <CustomButton onClick={onChange}>{t('Xác nhận')}</CustomButton>
                    <CustomButton onClick={(e)=>{setOpenDialog(false)}} sx={{ background: "#f72533" }} autoFocus>
                        {t('Huỷ')}
                    </CustomButton>
                </DialogActions>
            </Dialog>
        </>
    )
}