

export default function FormatCurrency (number){
    if (isNaN(number)) {
        return "Invalid input";
    }

    // Chuyển đổi số thành chuỗi và đảm bảo rằng nó có kiểu số nguyên
    const integerPart = Math.floor(number).toString();

    // Tách phần thập phân nếu có
    const decimalPart = (number % 1).toFixed(0);

    // Thêm dấu phẩy ngăn cách hàng nghìn vào phần nguyên
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    // Kết hợp phần nguyên và phần thập phân (nếu có) để tạo chuỗi định dạng
    const formattedCurrency = `${formattedIntegerPart}${decimalPart === "0" ? "" : "." + decimalPart} `;

    return formattedCurrency;
}

export function getUniqueAndSortedTimestamps(data) {
    const uniqueTs = new Set();
    for (const key in data) {
        for (const item of data[key]) {
            uniqueTs.add(item.ts);
        }
    }
    return Array.from(uniqueTs).sort((a, b) => a - b);
}