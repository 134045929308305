import Axios from "./../../../../utils/Axios/Axios";

class Place {

  static create = async (data) => Axios.post("/api/place/createPlace", data);

  static getAll = async (page, limit, sortOrder, search, sortProperty) =>
    Axios.get("/api/place/getAllPlace", {
      page, limit, sortOrder, search, sortProperty
    });

  static delete = async (data) => Axios.delete("/api/place/deletePlace", data);

  static getOne = async (id) => Axios.get(`/api/place/getPlace/${id}`);

  static update = async (id, data) => Axios.put("/api/place/updatePlace/" + id, data);

  // ========================= Serial =========================
  static getSerialValue = async (id, date) => Axios.get(`/api/value/getSerialValue/${id}`, {
    date
  });

  static getSerialValueOfAllPlace = async () => Axios.get(`/api/value/getSerialValueOfAllPlace`);
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< END - Serial>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>=


  // =========================Average=========================
  static getAverageValue = async (id, date, timeType) => Axios.get(`/api/value/getAverageValue/${id}`, {
    date, timeType
  });


  // ========================= Total =========================
  static getTotalValue = async (id) => Axios.get(`/api/value/getTotalValue/${id}`);
  static getTotalValueOfAllPlace = async (id) => Axios.get(`/api/value/getTotalValueOfAllPlace`);
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< END - Total >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // ========================= Report =========================
  static getReport = async (id, valueType, fromDate, toDate) =>
    Axios.get(`/api/value/getReport/${id}`, {
      valueType, fromDate, toDate
    });
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< END - Report >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

  // ========================= HeatMap =========================
  static getValueForHeatMap = async (id,startDate,toDate) => Axios.get(`/api/value/getValueForHeatMap/${id}`,{
    startDate,toDate
  });
  
  static heatmapWarning = async (id) =>
  Axios.get(`/api/heatmapWarning/getWarningByPlaceId?placeId=${id}`,);

  static updateHeatmapWarning = async (id, data) => Axios.put("/api/heatmapWarning/updateHeatmapWarning/" + id, data);


  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< END - HeatMap >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


  // ========================= Station =========================
  static getCurrentValueOfStation = async (id, valueType, fromDate, toDate) =>
  Axios.get(`/api/value/getCurrentValueOfStation/${id}`,);

  static getRevenue = async (id, date, timeType) => Axios.get(`/api/value/getRevenue/${id}`, {
    date, timeType
  });

  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< END - Station >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


  // ========================= Statistical =========================
  static getStatisticalRevenue = async (id) => Axios.get(`/api/value/getStatisticalRevenue/${id}`);

  static getStatisticalOfAllPlace = async (id) => Axios.get(`/api/value/getStatisticalOfAllPlace`);

  static getStatisticalTableOfAllPlace = async (date, timeType) => Axios.get(`/api/value/getStatisticalTableOfAllPlace`, {
    date, timeType
  });
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< END - Statis >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


  // ========================= Parameters =========================
  static getCurrentValueOfAllPlace = async () => Axios.get(`/api/value/getCurrentValueOfAllPlace`);
  
  // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<< END - Parameters >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

}

export default Place;