
import { Box } from "@mui/material"
import styleChart from "./index.module.scss"
import { useEffect, useState } from "react"
import Axios from "../../../../../utils/Axios"
import { useParams } from "react-router-dom"



import ic_dedicate_coal_v2 from "../../../../../assets/images/ic_dedicate_coal_v2.png";
import ic_dedicate_CO2_v2 from "../../../../../assets/images/ic_dedicate_CO2_v2.png";
import ic_dedicate_Tree_v2 from "../../../../../assets/images/ic_dedicate_Tree_v2.png";
import logApiErrorGet from "../../../../../utils/Axios/LogApiError"
import Loading from "../../../../Loading"




export default function SolarContent() {
    const { _id } = useParams();
    const [objectValue, setObjectValue] = useState();
    const [showLoading, setShowLoading] = useState(false);


    useEffect(() => {
        getCurrentValueOfStation()
        // eslint-disable-next-line react-hooks/exhaustive-deps
        let timeOut = setInterval(() => {
            getCurrentValueOfStation()
        }, 60000)
        return () => clearInterval(timeOut)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const getCurrentValueOfStation = async () => {
        setShowLoading(true);
        try {
            const response = await Axios.Place.getCurrentValueOfStation(_id);
            if (response?.status === 200) {
                setShowLoading(false);
                setObjectValue(response.data.values)
            } else {
                logApiErrorGet(setShowLoading, "getCurrentValueOfStation",  response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "getCurrentValueOfStation", error);

        }
    }

    return <>
        <Box sx={{
            width: '90%',
            height: '95%',
            margin: 'auto',
            borderRadius: 2,
            boxShadow: 2,
            justifyContent: 'center',
            display: 'flex',
            background: localStorage.getItem("theme") === "dark" ? "rgb(81, 81, 81)" : 'white',
            color: 'black'
        }}>
            <div className={styleChart['container']}>
                <div className={styleChart['homepv']}>
                    <div className={styleChart['content']} style={{ marginTop: '19px' }}>{objectValue?.pv} </div>
                </div>
                <div className={styleChart['curve1']}></div>
                {/* <div className={styleChart['curve2']}></div> */}
                <div className={styleChart['curve2Next']}></div>
                {/* <div className={styleChart['curve2Prev']}></div> */}
                {/* <div className={styleChart['straightLine1']}></div> */}
                <div className={styleChart['straightLine1Next']}></div>
                <div className={styleChart['home']}>
                    <div className={styleChart['content']}>{objectValue?.load} </div>
                </div>
                <div className={styleChart['feed-in']}>
                    <div className={styleChart['content']}>{objectValue?.grid} </div>
                </div>

                <div className={styleChart['battery']}>
                    <div className={styleChart['content']}>{objectValue?.battery} </div>
                    <div
                        style={{
                            position: 'absolute',
                            marginTop: '23px',
                            fontSize: '14px',
                            marginLeft: '39px',
                            width: '22px',
                            height: '37px',
                            background: `linear-gradient(to top, #52c41a ${objectValue?.pin}%, #fff ${objectValue?.pin}%)`,
                        }}
                    >

                    </div>
                    <div style={{
                        fontSize: '12px',
                        position: 'absolute',
                        marginLeft: '42px',
                        marginTop: '35px',
                        background: 'rgba(0, 0, 0, 0)'
                    }}>{objectValue?.pin || 0} %</div>
                </div>
                <div className={styleChart['straightLine2']}></div>
                <div className={styleChart['straightLine3']}></div>
                <div className={styleChart['curve3']}></div>

                <div className={styleChart['CarbonImpactMeasures']}>
                    <img src={ic_dedicate_coal_v2} alt="logo" />
                    <div style={{
                        position: 'absolute',
                        marginTop: '60px',
                        marginLeft: '30px'
                    }}>
                        3.15 (tons)
                    </div>

                    <img src={ic_dedicate_CO2_v2} alt="logo" />
                    <img src={ic_dedicate_Tree_v2} alt="logo" />
                </div>
            </div>
        </Box>
        <Loading showLoading={showLoading} />

    </>

}
