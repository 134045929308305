import system from './system';
import utilities from './utilities';

// ==============================|| MENU ITEMS ||============================== //
export default function MenuItems({utilitie}) {

    const menuItems = {
        items: [utilities({utilitie}), system]
    };
    return menuItems
    
};
