import { combineReducers } from 'redux';

// reducer import
import {
    customizationReducer,
    ReducerValueTabSystemHeader,
    ReducerValueTabPlaceHeader,
    ReducerListPlace
} from './customizationReducer';



// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
    customization: customizationReducer,
    ReducerValueTabSystemHeader,
    ReducerValueTabPlaceHeader,
    ReducerListPlace
});

export default reducer;
