import { useState, useRef, useEffect } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Paper,
  Popper,
  Stack,
  Typography,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

// project imports
import MainCard from "./../../../components/cards/MainCard";
import Transitions from "./../../../components/extended/Transitions";

// assets
import { IconSettings } from '@tabler/icons-react';
import Iconify from "../../../components/iconify/Iconify";
// import logo from "../../../assets/images/logoAvatar.jpg";
import LinearProgress from '@mui/material/LinearProgress';
import useLogin from "../../../utils/Login/useLogin";
import Axios from "../../../utils/Axios";
import Loading from "../../../components/Loading";
import logApiErrorGet from "../../../utils/Axios/LogApiError";
import { useTranslation } from "react-i18next";

// ==============================|| PROFILE MENU ||============================== //

const MENU_OPTIONS = [
  {
    label: "Trang chủ",
    path: "/dashboard",
    icon: "ic:outline-home",
  },
  {
    label: "Đổi mật khẩu",
    path: "/changePassword",
    icon: "carbon:password",
  },
];

const ProfileSection = () => {

  const navigate = useNavigate();
  const { logout } = useLogin();
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [userDetail, setUserDetail] = useState({})
  const [showLoading, setShowLoading] = useState(false);
  const { t } = useTranslation();


  const anchorRef = useRef(null);


  const handleRedirect = (path) => {
    navigate(path);
  };

  useEffect(() => {
    getUserDetail();
  }, [])

  const getUserDetail = async () => {
    setShowLoading(true);
    try {
      const response = await Axios.User.getUserDetail();
      if (response?.status === 200) {
        setShowLoading(false)
        setUserDetail(response.data)
      } else {
        logApiErrorGet(setShowLoading, "getUserDetail",response);
      }
    } catch (error) {
      logApiErrorGet(setShowLoading, "getUserDetail", error);

    }
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);


  return (
    <>
      <Chip
        sx={{
          // alignItems: "center",
          // borderRadius: "27px",
          transition: "all .2s ease-in-out",
          borderColor: 'white',
          '&[aria-controls="menu-list-grow"], &:hover': {
            "& svg": {
              stroke: '#ffadd2',
            },
          },
          "& .MuiChip-label": {
            lineHeight: 0,
          },
        }}
        label={
          <IconSettings
            stroke={1.5}
            size="1.5rem"
            style={{ marginRight: 10, marginLeft: 10 }}
            color="white"
          />
        }
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      &emsp;&emsp;
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 14],
              },
            },
          ],
        }}

      >
        {({ TransitionProps }) => (
          <Transitions in={open} {...TransitionProps}>
            <Paper >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                >
                  <Box sx={{ p: 2 }}>
                    <Stack>
                      <Stack direction="row" spacing={0.5} alignItems="center" sx={{ mt: -1.4 }}>
                        <Typography variant="h6">
                          {t('Xin chào')}, {userDetail.username}
                        </Typography>
                      </Stack>
                    </Stack>
                    <LinearProgress sx={{ height: "1.4px", mt: 0.2 }} />
                  </Box>
                  <Stack sx={{ p: 0.5, mt: -2 }}>
                    {MENU_OPTIONS.map((option) => (
                      <MenuItem
                        key={option.label}
                        onClick={() => handleRedirect(option.path)}
                      >
                        <Iconify icon={option.icon} sx={{ marginRight: 1, }} />
                        {t(option.label)}
                      </MenuItem>
                    ))}
                  </Stack>
                  <Divider />

                  <MenuItem onClick={logout} sx={{ m: 1, }}>
                    <Iconify icon={"bx:log-out"} sx={{ marginRight: 2 }} />
                    {t('Đăng xuất')}
                  </MenuItem>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
      <Loading showLoading={showLoading} />

    </>
  );
};

export default ProfileSection;
