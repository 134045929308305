// @mui
import { styled } from "@mui/material/styles";
import { Box, Typography } from "@mui/material";
// components
// import Logo from "./../../utils/Logo/Logo";
// sections
import { LoginForm } from "./components/LoginForm";
import { Helmet } from "react-helmet";
import bg from "../../assets/images/Login/bgLogin.jpeg"
import { useEffect, useState } from "react";

// ----------------------------------------------------------------------

const StyledRoot = styled("div")(({ theme }) => ({
  background: `url(${bg})`,
  // backgroundSize: 'cover',
  backgroundSize: "100% 100%",
}));

// ----------------------------------------------------------------------

export default function LoginPage() {
  const [matchDownMd, setMatchDownMd] = useState(false);
  useEffect(() => {
    function handleWindowSize() {
      if (window.innerWidth < 1250) {
        setMatchDownMd(true)
      } else {
        setMatchDownMd(false)
      }
    }

    // Gọi hàm kiểm tra khi tải trang và khi cửa sổ trình duyệt thay đổi kích thước
    handleWindowSize();
    window.addEventListener('resize', handleWindowSize);

    // Cleanup: Loại bỏ sự kiện khi component unmount
    return () => {
      window.removeEventListener('resize', handleWindowSize);
    };
  }, []);
  return (
    <>
      <Helmet>
        <title> Login | EMS - Project </title>
      </Helmet>

      <StyledRoot>
        <Box sx={{
          display: "flex", flexWrap: 'wrap', height: '100vh',
          minHeight:'800px'
        }}>
          <Box sx={{ width: matchDownMd ? '90%' : '50%', height: matchDownMd ? '' : '450px', margin: 'auto' }}>
            <Box sx={{ maxWidth: '600px', margin: 'auto', color: '#fff', }}>
              <span
                style={{
                  fontFamily: 'Nacelle',
                  fontSize: matchDownMd ? '40px' : '70px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                }}
              >Power Monitoring</span>
              <div
                style={{ fontSize: matchDownMd ? '15px' : '25px' }}
              >
                Elevate Performance through Precise Power Insight.
              </div>
            </Box>
          </Box>
          <Box sx={{ width: matchDownMd ? '95%' : '50%', margin: 'auto' }}>
            <Box sx={{
              background: '#fff', maxWidth: '600px', minHeight: '400px', margin: 'auto', borderRadius: 5,
              display: 'flex', alignItems: 'center', justifyContent: 'center', justifyItems: 'center',
            }}>
              <Box sx={{ width: '90%' }}>
                <LoginForm />
              </Box>
            </Box>
          </Box>
          <Box sx={{ minHeight: '100px', width: '100%'}}></Box>
          <Box sx={{
            position: 'absolute', width: '100%', height: '100px',
            bottom: 30,
            display: 'flex',
            justifyContent: 'center', alignItems: 'center',
            color: '#fff',
            flexWrap: 'wrap',
          }}>
            <Typography sx={{ width: '100%', textAlign: 'center' }}>Terms of Service  |  Privacy Policy</Typography>
            <Typography sx={{ width: '100%', textAlign: 'center' }}>Copyright © 2023 Siginx</Typography>

            <a href="https://siginx.com" target="_blank" rel="noopener noreferrer"
              style={{ textDecoration: 'none' }}>
              <Typography sx={{
                width: '100%', textAlign: 'center',
                cursor: 'pointer',
                color: '#fff'
              }}
              >Website: https://siginx.com</Typography>
            </a>
          </Box>
        </Box>
      </StyledRoot>
    </>
  );
}
