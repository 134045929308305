const localStorageToken = `${localStorage.getItem("account")}`;
const dataToken = JSON.parse(localStorageToken) ? JSON.parse(localStorageToken) : "";
const CONFIG = {
  // URL: "http://103.176.179.174:5001/electric-energy",
  URL: "https://electric-energy.siginx.com/electric-energy",
  HEADERS: {
    Accept: "application/json",
    "Content-Type": "application/json; charset=utf-8",
    Authorization: 'Bearer '+dataToken.accessToken,
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS",
  },
};

export default CONFIG;
