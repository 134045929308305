import { Box, } from '@mui/material';
import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useParams } from 'react-router-dom';
import dayjs from "dayjs";
import 'dayjs/locale/en-gb';
import { useState } from 'react';
import { useEffect } from 'react';
import schedule from 'node-schedule';



import Axios from '../../../../utils/Axios';
import Chart from './Chart/Chart';
import Loading from "../../../Loading"
// import LineChart from './Chart/LineChart';
import { useTranslation } from 'react-i18next';
import logApiErrorGet from '../../../../utils/Axios/LogApiError';


export default function ChartPower() {
    const { _id } = useParams();

    const [datePickerValue, setDatePickerValue] = useState(dayjs(new Date()));
    const [series, setSeries] = useState();
    const [options, setOptions] = useState();

    const [showLoading, setShowLoading] = useState(false);
    const [showType, setShowType] = useState();
    const [title, setTitle] = useState();
    // const [dataChartLine, setDataChartLine] = useState();
    const { t } = useTranslation();


    useEffect(() => {
        getSerialValue(_id, datePickerValue.$d);

        const job = schedule.scheduleJob('*/5 * * * *', () => {
          getSerialValue(_id, datePickerValue.$d)
        });
    
        return () => {
          job.cancel(); 
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datePickerValue, _id])


    useEffect(() => {
        if (options) {
            const newOptions = JSON.parse(JSON.stringify(options));
            newOptions.xaxis = {
                labels: {
                    style: {
                        colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                    },
                }
            };
            newOptions.yaxis = {
                labels: {
                    formatter: function (value) {
                        return Math.floor(value) + ' kWh';
                    },
                    style: {
                        colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                    },
                },

            }
            setOptions(newOptions)

        } else {
            setOptions(
                {
                    chart: {
                        type: 'area',
                        height: 350,
                        // events: {
                        //     zoomed: function(d1,d2) {
                        //       console.log('Zoomed event',d1,'--',d2,'==');
                        //     },
                        //     scrolled: function({ chartContext, opts }) {
                        //       console.log('Scrolled event--->');
                        //     }
                        //   }
                    },
                    colors: ['#008FFB', '#52c41a', '#eb2f96', '#722ed1', '#faad14', '#13c2c2'],
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                    },


                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false,
                            format: 'HH:mm',
                            style: {
                                colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                            },
                        },
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false
                        },

                    },

                    stroke: {
                        curve: 'straight',// duong vien nhon
                        width: 1.3 // Đường viền mỏng hơn với giá trị 
                    },
                    yaxis: {
                        tickAmount: 6,
                        floating: false,

                        labels: {
                            formatter: function (value) {
                                return Math.floor(value) + ' kWh';
                            },
                            style: {
                                colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                            },
                        },

                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yyyy HH:mm',
                        },
                        y: {
                            formatter: (val, { seriesIndex, dataPointIndex, w }) => {
                                var sumDown = 0;
                                for (var index = 0; index < w.config.series.length; index++) {
                                    let arr = w.globals.series[index]
                                    sumDown += arr[dataPointIndex]
                                }


                                // ========toFixed===========
                                const integerPart = Math.floor(sumDown);
                                const decimalPart = Math.floor((sumDown - integerPart) * 100) / 100;

                                const resultSumDown = `${integerPart}.${decimalPart.toFixed(2).slice(2)}`;
                                // ===================
                                let total = `<div style="margin-top:-5px;margin-bottom:5px;">${seriesIndex === 0 ? `${t('Tổng')}: ${resultSumDown} kWh` : ''}</div><p style="margin-top:-5px">${val} kWh</p>`;
                                if (w.config.series.length < 2) {
                                    total = val;
                                }

                                return total;
                            }
                        },
                    },

                    fill: {
                        type: 'solid', // Sử dụng màu solid (không loang)
                        opacity: 0   // Đặt độ mờ của màu fill thành 0
                    }
                }
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("theme")]);

    function getListOfTimestampsInDay(timestamp) {
        const startOfDay = new Date(timestamp);
        startOfDay.setHours(0, 0, 0, 0);

        const endOfDay = new Date(timestamp);
        endOfDay.setHours(23, 59, 59, 999);

        const timestampsList = [];
        let currentTimestamp = startOfDay.getTime();

        while (currentTimestamp <= endOfDay.getTime()) {
            timestampsList.push(currentTimestamp);
            currentTimestamp += 300000; // Thêm 5 phút (60,000 mili giây)
        }

        return timestampsList;
    }

    function roundSecondsToZero(timestamp) {
        var date = new Date(timestamp);
        date.setSeconds(0);
        date.setMilliseconds(0);
        var roundedTimestamp = date.getTime();
        return roundedTimestamp;
    }


    const getSerialValue = async (id, date) => {
        setShowLoading(true);
        const response = await Axios.Place.getSerialValue(id, date);
        // console.log('--> ',response.data.values)

        try {
            if (response.status === 200) {
                setShowLoading(false);
                setTitle(response.data.name)
                setShowType(response.data.type);
                const data = response.data.values.serialValue;
                // if(data){

                // Tạo một object mới loại bỏ trường serialValue
                const temporary = {
                    ...response.data.values
                };
                delete temporary.serialValue;
                // setDataChartLine(temporary)

                const timestamp = new Date(datePickerValue.$d).getTime();
                const timestampsList = getListOfTimestampsInDay(timestamp);

                // ======================================================
                const new_array = Object.keys(data[0]?.value);
                const resultList = [];


                new_array.forEach((element, index) => {
                    const result = timestampsList.map(x => {
                        const matchingItem = data.find(item => roundSecondsToZero(item.ts) === x);
                        let getValue = 0
                        if (matchingItem) {
                            const valueItem = Object.values(matchingItem.value);
                            getValue = valueItem[index];
                        }
                        return {
                            x,
                            y: getValue
                        };
                    });
                    resultList.push(result)
                });

                getDataChart(new_array, resultList)
            } else {
                logApiErrorGet(setShowLoading, "getSerialValue", response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "getSerialValue", error);
        }
    }


    const getDataChart = (listName, listData) => {
        const newList = []
        // console.log(listData)
        listName.forEach((element, index) => {
            newList.push({
                name: element, data: listData[index],

            })
        });

        setSeries(newList)

    }

    const handleChangeDate = (value) => {
        setDatePickerValue(value)
    }

    return (
        <>
            <title>{title}</title>
            <Box sx={{
                width: '96%',
                height: '90%',
                margin: 'auto',
                borderRadius: 2,
                boxShadow: 2,
                background: localStorage.getItem("theme") === "dark" ? "rgb(81, 81, 81)" : 'white',
            }}>
                <Box sx={{ textAlign: 'center', }}>
                    <div style={{ display: showType === 'solar' ? 'flex' : '', flexWrap: showType === 'solar' ? 'wrap' : '', height: showType === 'solar' ? '7.5vh' : '' }}>
                        {/* {showType === 'solar' &&
                            <LineChart color1={'#69b1ff'} color2={'#bae0ff'}
                                width={'30%'}
                                value1={((dataChartLine?.consumed / dataChartLine?.yield) || 0 * 100).toFixed(2)}
                                value2={((dataChartLine?.fedToGrid / dataChartLine?.yield) || 0 * 100).toFixed(2)}
                                title={`Yield: ${dataChartLine?.yield || 0} kW`}
                                from={`Consumed: ${dataChartLine?.consumed || 0} kW`}
                                to={`Fed To Grid: ${dataChartLine?.fedToGrid || 0} kW`} />} */}
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'en-gb'}>
                            <DatePicker
                                value={datePickerValue}
                                onChange={(newValue) => handleChangeDate(newValue)}
                                sx={{
                                    mt: 0.5,
                                    width: '180px',
                                    'input': {
                                        padding: '5px',
                                        paddingLeft: '35px',
                                    }
                                }}
                            />
                        </LocalizationProvider>
                        {/* {showType === 'solar' &&
                            <LineChart color1={'#85a5ff'} color2={'#d6e4ff'}
                                width={'30%'}
                                value1={((dataChartLine?.fromPV / dataChartLine?.consumption) || 0 * 100).toFixed(2)}
                                value2={((dataChartLine?.fromGrid / dataChartLine?.consumption) || 0 * 100).toFixed(2)}
                                title={`Consumption: ${dataChartLine?.consumption || 0} kW`}
                                from={`From PV: ${dataChartLine?.fromPV || 0} kW`}
                                to={`From Grid: ${dataChartLine?.fromGrid || 0} kW`} />
                        } */}
                    </div>
                </Box>
                {series && options &&
                    <div style={{ height: '30vh', minHeight: '230px', color: 'black', }} className={localStorage.getItem("theme") === 'dark' ? 'mode' : ''} >
                        <Chart series={series} options={options} type="area" />
                    </div>
                }

            </Box>
            <Loading showLoading={showLoading} />


        </>
    );
};