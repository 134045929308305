import { Grid, } from "@mui/material";

import { useEffect, useState } from "react";




import ToTalValue from "../EnergySystem/TabPanel0/components/ToTalValue";
import SimplePieChart from "./simplePieChart";
import TotalPower from "./TotalPower";

import Axios from "../../utils/Axios";
import Loading from "../Loading";
import logApiErrorGet from "../../utils/Axios/LogApiError";
import RegionalStatistics from "./RegionalStatistics";
import { useTranslation } from "react-i18next";


export default function Dashboard() {

    const [showLoading, setShowLoading] = useState(false);
    const [listObject, setListObject] = useState();
    const { t } = useTranslation();


    useEffect(() => {
        getTotalValueOfAllPlace()
    }, [])

    const getTotalValueOfAllPlace = async () => {
        setShowLoading(true);
        try {
            const response = await Axios.Place.getTotalValueOfAllPlace();
            if (response?.status === 200) {
                setShowLoading(false);
                setListObject(response.data);
            } else {
                logApiErrorGet(setShowLoading, "getTotalValueOfAllPlace", response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "getTotalValueOfAllPlace", error);


        }
    }



    return <>
        <title>{t('Trang chủ')}</title>
    
        <Grid container >
            <Grid item xs={12} sm={12} md={12} sx={{ minWidth:'250px',height: !listObject?'14vh':''}} >
                <Grid container spacing={0} >
                    {listObject &&
                        <ToTalValue listObject={listObject} unit={'kWh'} />
                    }
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} md={7.5} sx={{ minHeight: '39.5vh', }}>
                <Grid container spacing={0} >
                    <Grid item xs={12} sm={12} md={12} sx={{
                        height: '39.5vh',
                        display: 'flex',
                        minHeight: '310px'
                    }}>
                        <TotalPower />
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={4.5} sx={{ minHeight: '39.5vh', }}>
                <Grid container spacing={0} >
                    <Grid item xs={12} sm={12} md={12} sx={{
                        height: '39.5vh',
                        display: 'flex',
                        minHeight: '310px',
                    }}>
                        <SimplePieChart />
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} md={12} sx={{ minHeight: '39.5vh', }}>
                <Grid container spacing={0} >
                    <Grid item xs={12} sm={12} md={12} sx={{
                        height: '39.5vh',
                        display: 'flex',
                        minHeight: '370px'
                    }}>
                        <RegionalStatistics />
                    </Grid>

                </Grid>
            </Grid>
        </Grid>
        <Loading showLoading={showLoading} />

    </>
}