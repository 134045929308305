export const SET_MENU = 'SET_MENU';
export const MENU_TOGGLE = 'MENU_TOGGLE';
export const MENU_OPEN = 'MENU_OPEN';
export const setTheme = (data) => {
    return {
        type: 'setTheme',
        payload: data
    }
}

export const setValueTabSystemHeader = (data) => {
    return {
        type: 'setValueTabSystemHeader',
        payload: data
    }
}


export const setValueTabPlaceHeader = (data) => {
    return {
        type: 'setValueTabPlaceHeader',
        payload: data
    }
}

export const setListPlace = (data) => {
    return {
        type: 'setListPlace',
        payload: data
    }
}


