
export default function FormatddMMyyyy({ dateTime }) {

    let today = new Date(dateTime);
    let day = today.getDate();
    if(day < 10){
      day = "0"+day;
    }

    let month = today.getMonth()+1
    if(month < 10){
      month = "0"+month;
    }

    let date =
      day +
      "-" +
      month +
      "-" +
      today.getFullYear()
      //  +
      // "    -  ( " +
      // today.getHours() +
      // " giờ : " +
      // today.getMinutes() +
      // " phút : " +
      // today.getSeconds() +
      // " giây )";

  return <span>{date}</span>;
}
