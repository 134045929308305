import { Box, AppBar, useTheme, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useDispatch, useSelector, } from 'react-redux';



import Sidebar from "./Sidebar";
import Header from './Header';
import { SET_MENU } from './../utils/store/actions';
import { Outlet } from 'react-router-dom';


const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
    ...theme.typography.mainContent,
    background: localStorage.getItem("theme") === "dark" ? "#011e3c" : 'white',
    flexGrow: 1,
    overflow: "auto",
    height: "100vh",
    ...(!open && {
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen
        }),
        [theme.breakpoints.up('md')]: {
            marginLeft: -(260 - 0),
            // width: `calc(100%)`
        },
    }),
    ...(open && {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen
        }),
        marginLeft: 0,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        // width: `calc(100%)`,
    })
}));


// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
    const dispatch = useDispatch();
    const theme = useTheme();

    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
    const leftDrawerOpened = useSelector((state) => state.customization.opened);

    const handleLeftDrawerToggle = () => {
        localStorage.setItem("opened", !leftDrawerOpened)
        dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
    };



    return (
        <Box sx={{ display: 'flex', }}>
            <AppBar
                enableColorOnDark
                position="fixed"
                color="inherit"
                elevation={0}
                sx={{
                    minHeight: '60px', background: '#1660b6',
                    display: 'flex',
                    justifyContent: 'center',
                    zIndex: 12
                }}

            >
                <Header handleLeftDrawerToggle={handleLeftDrawerToggle} />
            </AppBar>
            <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />
            <Main theme={theme} open={leftDrawerOpened} >
                <Box sx={{ height: '7vh', minHeight: matchDownMd ? '130px' : '60px',}}>
                </Box>
                <Outlet />
            </Main>
        </Box>



    )
}
export default MainLayout;
