
import { Box, Divider, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import Axios from "../../../../../utils/Axios"
import { useParams } from "react-router-dom"
import FormatCurrency from "../../../../../utils/FormatNumber/FormatNumber";
import { useTranslation } from "react-i18next";
import logApiErrorGet from "../../../../../utils/Axios/LogApiError";
import Loading from "../../../../Loading";


export default function ElectricityContent() {
    const { _id } = useParams();
    const { t } = useTranslation();


    const [listObject, setListObject] = useState();
    const [showLoading, setShowLoading] = useState(false);



    useEffect(() => {
        getStatisticalRevenue()
        let timeOut = setInterval(() => {
            getStatisticalRevenue();
        }, 60000);
        return () => clearInterval(timeOut)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id])


    const getStatisticalRevenue = async () => {
        setShowLoading(true)
        try {
            const response = await Axios.Place.getStatisticalRevenue(_id);
            if (response?.status === 200) {
                setShowLoading(false)
                setListObject(response.data.values)
            } else {
                logApiErrorGet(setShowLoading, "getStatisticalRevenue", response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "getStatisticalRevenue", error);

        }
    }

    return <>
        <Box sx={{
            width: '96%',
            height: '90%',
            margin: 'auto',
            borderRadius: 2,
            boxShadow: 2,
            background: localStorage.getItem("theme") === "dark" ? "rgb(81, 81, 81)" : 'white',
            position: 'relative',
            textAlign: 'center',
        }}>
            <Box sx={{ display: 'flex', width: '100%', height: '10%', alignItems: 'center', background: '#bae7ff' }}>
                <Content title={""} fs={14} fw={700} dataKWHDay={`KWH ${t('Ngày')}`} dataDay={'Ngày hiện tại'} dataMonth={'Tích lũy tháng'} dataKWHMonth={`KWH ${t('Tháng')}`} />
            </Box>
            <Box sx={{ display: 'flex', width: '100%', height: '5%', pb: 1.6, pt: 1, alignItems: 'center', background: '#bae7ff' }}>
                <Content title={`(${t('Giờ')})`} fs={13} dataKWHDay={'(KWH)'} dataDay={'(VNĐ)'} dataMonth={'(VNĐ)'} dataKWHMonth={'(KWH)'} />
            </Box>

            {listObject &&
                <Box sx={{ height: '81%', mt: 0.5 }}>
                    <Box sx={{ display: 'flex', width: '100%', height: '24%', alignItems: 'center', borderLeft: '0.5rem solid #faad14' }}>
                        <Content title={"Thấp điểm"} fs={13} dataKWHDay={`${FormatCurrency(listObject?.totalDayOffPeakKWH)} `} dataDay={`${FormatCurrency(listObject?.totalDayOffPeak)}`} dataKWHMonth={`${FormatCurrency(listObject?.totalMonthOffPeakKWH)}`} dataMonth={`${FormatCurrency(listObject?.totalMonthOffPeak)}`} />
                    </Box>
                    <Divider sx={{ height: '1%' }} />
                    <Box sx={{ display: 'flex', width: '100%', height: '24%', alignItems: 'center', borderLeft: '0.5rem solid #a0d911' }}>
                        <Content title={"Bình thường"} fs={13} dataKWHDay={`${FormatCurrency(listObject?.totalDayStandardKWH)}`} dataDay={`${FormatCurrency(listObject?.totalDayStandard)}`} dataKWHMonth={`${FormatCurrency(listObject?.totalMonthStandardKWH)}`} dataMonth={`${FormatCurrency(listObject?.totalMonthStandard)}`} />
                    </Box>
                    <Divider sx={{ height: '1%' }} />
                    <Box sx={{ display: 'flex', width: '100%', height: '24%', alignItems: 'center', borderLeft: '0.5rem solid #f5222d' }}>
                        <Content title={"Cao điểm"} fs={13} dataKWHDay={`${FormatCurrency(listObject?.totalDayPeakKWH)}`} dataDay={`${FormatCurrency(listObject?.totalDayPeak)}`} dataKWHMonth={`${FormatCurrency(listObject?.totalMonthPeakKWH)}`} dataMonth={`${FormatCurrency(listObject?.totalMonthPeak)}`} />
                    </Box>
                    <Divider sx={{ height: '1%' }} />
                    <Box sx={{ display: 'flex', width: '100%', height: '27%', alignItems: 'center', borderTop: '0.5rem solid #a0d911' }}>
                        <Content title={"Tổng"} fw={700}
                            dataKWHDay={`${FormatCurrency(listObject?.totalDayStandardKWH + listObject?.totalDayOffPeakKWH + listObject?.totalDayPeakKWH)}`}
                            dataDay={`${FormatCurrency(listObject?.totalDayStandard + listObject?.totalDayOffPeak + listObject?.totalDayPeak)}`}
                            dataKWHMonth={`${FormatCurrency(listObject?.totalMonthStandardKWH + listObject?.totalMonthPeakKWH + listObject?.totalMonthOffPeakKWH)}`}
                            dataMonth={`${FormatCurrency(listObject?.totalMonthStandard + listObject?.totalMonthPeak + listObject?.totalMonthOffPeak)}`} />
                    </Box>
                </Box>
            }
        </Box>
        <Loading showLoading={showLoading} />

    </>
}


function Content({ title, dataKWHDay, dataDay, dataMonth, dataKWHMonth, fw, fs }) {
    const { t } = useTranslation();
    return (
        <>
            <Typography sx={{ width: '17%', fontWeight: fw, fontSize: fs, }}>{t(title)}</Typography>
            <Typography sx={{ width: '20%', fontWeight: fw, fontSize: fs, }}>{t(dataKWHDay)}</Typography>
            <Typography sx={{ width: '20%', fontWeight: fw, fontSize: fs }}>{t(dataDay)}</Typography>
            <Typography sx={{ width: '20%', fontWeight: fw, fontSize: fs }}>{t(dataKWHMonth)}</Typography>
            <Typography sx={{ width: '28%', fontWeight: fw, fontSize: fs, }}>{t(dataMonth)}</Typography>

        </>

    )
}