import PropTypes from 'prop-types';
import { forwardRef, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';



// material-ui
import { ListItemButton, ListItemIcon } from '@mui/material';
// project imports
import { MENU_OPEN, } from './../../../utils/store/actions';
import { useTranslation } from 'react-i18next';
// assets

// ==============================|| SIDEBAR MENU LIST ITEMS ||============================== //
const NavItem = ({ item, level }) => {

    const dispatch = useDispatch();
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const customization = useSelector((state) => state.customization);

    const Icon = item.icon;
    const itemIcon = item?.icon ? (
        <Icon stroke={1.5} size="1.3rem" />
    ) : (
        ''
    );

    let itemTarget = '_self';
    if (item.target) {
        itemTarget = '_blank';
    }

    let listItemProps = {
        component: forwardRef((props, ref) => <Link ref={ref} {...props} to={item.url} target={itemTarget} />)
    };
    if (item?.external) {
        listItemProps = { component: 'a', href: item.url, target: itemTarget };
    }

    const itemHandler = (id) => {
        dispatch({ type: MENU_OPEN, id });
    };

    // active menu item on page load
    useEffect(() => {

        const currentIndex = document.location.pathname
            .toString()
            .split('/')
            .findIndex((id) => id === item.id);
        if (currentIndex > -1) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }
        if (document.location.pathname.includes('/edit-place/')) {
            dispatch({ type: MENU_OPEN, id: 'my-place' });
        }
        if (document.location.pathname.includes('/dashboard') || document.location.pathname==='/') {
            dispatch({ type: MENU_OPEN, id: 'dashboard' });
        }
        if (item.url === pathname) {
            dispatch({ type: MENU_OPEN, id: item.id });
        }

        // eslint-disable-next-line
    }, []);

    return (
        <>
            < ListItemButton
                {...listItemProps}
                disabled={item.disabled}
                sx={{
                    borderRadius: `12px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    py: level > 1 ? 1 : 1.25,
                    marginLeft: `${level * 14}px`,
                    background: customization.isOpen[0] === item.id ? 'white !important' : '',
                    color: customization.isOpen[0] === item.id ? 'black !important' : 'white'
                }}
                selected={customization.isOpen.findIndex((id) => id === item.id) > -1}
                onClick={() => itemHandler(item.id)}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !item?.icon ? 18 : 36, color: customization.isOpen[0] === item.id ? 'black' : 'white' }}>{itemIcon}</ListItemIcon>
                <div style={{ fontSize: 14 }}>{t(item.title)}</div>
            </ListItemButton>
            {item.index > 10 &&
                <div onClick={(e) => { navigate('/my-place') }} style={{ fontSize: 15, cursor: 'pointer', textAlign: 'center', color: "white" }}>Show more...</div>
            }
        </>
    );
};

NavItem.propTypes = {
    item: PropTypes.object,
    level: PropTypes.number
};

export default NavItem;
