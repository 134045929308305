import { Box, Tab, Tabs } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setValueTabPlaceHeader } from "../../../utils/store/actions";
import { MENU_OPEN, } from './../../../utils/store/actions';


import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function TabPlace({ matchDownMd }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { t } = useTranslation();


    const newValueTabPlaceHeader = useSelector((state) => state.ReducerValueTabPlaceHeader.data);



    const handleChange = (event, newValue) => {
        if (newValue === 1) {
            navigate(`/new-place`);
            dispatch({ type: MENU_OPEN, id: 'new-place' });
        } else {
            navigate(`/my-place`);
            dispatch({ type: MENU_OPEN, id: 'my-place' });
        }

        dispatch(setValueTabPlaceHeader(newValue))
    };


    return (
        <>
            <Box
                // sx={{ margin: 'auto', width: matchDownMd ? '50%' : '100%' }}
            >
                <Tabs value={newValueTabPlaceHeader ? newValueTabPlaceHeader : 0} onChange={handleChange}
                    variant="scrollable"
                    scrollButtons={false}
                    aria-label="scrollable prevent tabs example"
                    TabIndicatorProps={{
                        style: {
                            height: 2,
                            transform: 'translateY(-8px)',
                            borderBottom: '2px solid white'
                        },
                    }}
                >
                    <Tab label={t("Danh sách địa điểm")} {...a11yProps(0)} sx={{ color: 'white !important',  fontSize: 14,fontWeight:700 }} />
                    {pathname.includes('/edit-place/') ? <Tab label={t('Chỉnh sửa địa điểm')} {...a11yProps(1)} sx={{ color: 'white !important',  fontSize: 14,fontWeight:700 }} />
                        :
                        <Tab label={t('Thêm địa điểm')} {...a11yProps(1)} sx={{ color: 'white !important',  fontSize: 14,fontWeight:700}} />
                    }

                </Tabs>
            </Box>
        </>

    )
}