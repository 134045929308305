import React from 'react';
import { Map, GoogleApiWrapper, Marker } from 'google-maps-react';
import logo from "../../../assets/images/googleMap.png"

const style = {
    width: '100%',
    height: '40vh',
    borderRadius: '5px',
    boxShadow: "0px 0px 0px 2px #9E9E9E",
}
const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%'
}

const MapContainer = (props) => {
    // const [selectedPlace, setSelectedPlace] = useState(null);
    const { google, initialCenter, initialZoom, handleMapClick } = props;
    // console.log(props)

    //hien thi chu thich
    // const onMarkerClick = (props, marker) => {
    //     setSelectedPlace(marker);
    // };

    const renderMarkers = () => {
        const markers = props.markers;
        return markers.map((marker, index) => (
            <Marker
                key={index}
                // onClick={handleMapClick}
                name={marker.name}
                position={marker.position}
                icon={{
                    url: logo,
                    scaledSize: new window.google.maps.Size(50, 50),
                }}
            />
        ));
    }




    return (
        <>
            <Map google={google} zoom={initialZoom}
                initialCenter={initialCenter}
                onClick={handleMapClick}
                containerStyle={containerStyle}
                style={style}
            >
                {renderMarkers()}
                {/* <InfoWindow onClose={onInfoWindowClose} marker={selectedPlace} visible={selectedPlace !== null}>
                    <div>
                        <h1>1231312</h1>
                    </div>
                </InfoWindow> */}
            </Map>
        </>
    );
};



export default GoogleApiWrapper(
    (props) => ({
        apiKey: props.googleApiKey,
    }
    ))(MapContainer)