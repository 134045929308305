import { Box, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Chart from './Chart';
import FormatCurrency from '../../../../../utils/FormatNumber/FormatNumber';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Typography component="div" sx={{ p: 3 }}>
                    {children}
                </Typography>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


export default function GeneralChart({
    listData,
    typeChart,
    timeType,
    setTimeType,
    year,
    setYear,
    month,
    setMonth,
    day,
    setDay,
    unit,
    title,
    height,
}) {
    const [series, setSeries] = useState();
    const [options, setOptions] = useState();
    const [valueTab, setValueTab] = useState(2);
    const { t } = useTranslation();


    // console.log(valueTab)
    useEffect(() => {
        getDataChart(listData, timeType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listData]);

    useEffect(() => {
        if (series) {
            const newOptions = JSON.parse(JSON.stringify(options));
            newOptions.xaxis = {
                labels: {
                    style: {
                        colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                    },
                }
            };
            newOptions.yaxis = {
                labels: {
                    formatter: (val) => {
                        return FormatCurrency(Math.floor(val)) + ' ' + unit
                    },
                    style: {
                        colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                    },
                }
            }
            setOptions(newOptions);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("theme")])

    const getNumberOfDays = (month, year) => {
        // Tạo đối tượng Date với ngày đầu tiên của tháng được truyền vào
        const date = new Date(year, month - 1, 1);

        // Di chuyển đến ngày đầu tiên của tháng tiếp theo
        date.setMonth(date.getMonth() + 1);

        // Trừ đi một ngày để lấy ngày cuối cùng của tháng hiện tại
        date.setDate(date.getDate() - 1);

        // Trả về ngày cuối cùng của tháng
        return date.getDate();
    };

    const checkTimeType = (listSeries, timeType) => {
        let number;
        if (timeType === 'day') {
            number = 24;
        }
        if (timeType === "month") {
            number = getNumberOfDays(month, year)
        }
        if (timeType === "year") {
            number = 12;
        }

        return number;
    }

    const getOptionOne = (number, listYear) => {

        let content;
        if(timeType==='day'){
            content='Giờ';
        }
        if(timeType==='month'){
            content='Ngày';
        }
        if(timeType==='year'){
            content='Tháng';
        }
        if (typeChart === 'group') {
            setOptions({
                chart: {
                    type: 'bar',
                    height: 350,
                    stacked: true,
                    events: {
                        dataPointSelection: handleDataPointSelection,
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
                    categories: listYear ? listYear : Array.from({ length: number }, (_, index) => timeType === 'day' ? index : index + 1),
                    labels: {
                        style: {
                            colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                        },
                    }
                },
                fill: {
                    opacity: 1
                },
                colors:['#faad14','#a0d911','#f5222d','#1677ff','#722ed1','#13c2c2','#eb2f96','#d9d9d9','#fadb14'],
                yaxis: {
                    labels: {
                        formatter: (val) => {
                            return FormatCurrency(Math.floor(val)) + ' ' + unit
                        },
                        style: {
                            colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                        },
                    },
                },
                legend: {
                    position: 'top',
                    horizontalAlign: 'left',
                },
                tooltip: {
                    shared: true,
                    intersect: false,
                    x:{
                            formatter: (val) => {
                                return timeType==='day'?val+' '+t(content):t(content)+' '+val
                            },
                    },
                    y: {
                        formatter: (val, { seriesIndex, dataPointIndex, w }) => {
                            var sumDown = 0;
                            for (var index = 0; index < w.config.series.length; index++) {
                                let arr = w.globals.series[index]
                                sumDown += arr[dataPointIndex]
                            }
                            // ===================
                            let total = `<div style="margin-top:-5px;margin-bottom:5px;">${seriesIndex === 0 ? `${t('Tổng')}: ${FormatCurrency(sumDown)} ${unit}` : ''}</div><p style="margin-top:-5px">${val} ${unit}</p>`;
                            return total;
                        }
                    },
                }
            })
        }
    }



    const getDataChart = (listSeries, timeType) => {
        try {
            if (listSeries[0]?.value) {
                const new_array = Object.keys(listSeries[0].value);
                let number = checkTimeType(listSeries, timeType);
                    const resultList = []
                    for (let index = 0; index < new_array.length; index++) {
                        const element = new_array[index];
                        resultList.push({ name: element, group: extractNumberFromOffPeak(element), data: Array(number).fill(0) })
                    }

                    // console.log('resultList',resultList)

                    listSeries.forEach(item => {
                        let ts;
                        if (timeType === 'day') {
                            ts = new Date(item.ts).getHours();
                        }
                        if (timeType === "month") {
                            ts = new Date(item.ts).getDate();
                        }
                        if (timeType === "year") {
                            ts = new Date(item.ts).getMonth();
                        }
                        const values = item.value;
                        Object.keys(values).forEach(key => {
                            const value = values[key];
                            resultList.find(obj => obj.name === key).data[timeType === 'month' ? ts - 1 : ts] = value;
                        });
                    });
                    // console.log(resultList)
                    setSeries(resultList);
                    getOptionOne(number);
                // }
            }

        } catch (error) {
            console.error(error)
        }
    }

    function extractNumberFromOffPeak(offPeak) {
        const number = offPeak.match(/\d+$/); // Sử dụng biểu thức chính quy để tìm số cuối cùng trong chuỗi
        return number ? parseInt(number[0]) : 1; // Chuyển số tìm được thành số nguyên hoặc trả về null nếu không tìm thấy số
    }


    function handleDataPointSelection(event, chartContext, selectedDataPoints) {
        if (valueTab !== 2) {
            let dataPointIndex = selectedDataPoints.dataPointIndex + 1;
            if (valueTab === 1) {
                setDay(dataPointIndex < 10 ? ('0' + dataPointIndex) : dataPointIndex)
                setTimeType("day")
                setValueTab(2);
            }
            if (valueTab === 0) {
                setMonth(dataPointIndex < 10 ? ('0' + dataPointIndex) : dataPointIndex);
                setTimeType("month");
                setValueTab(1);
            }
        }
    };

    const handleChange = (event, value) => {
        if (value === 0) {
            setTimeType('year');
        }
        if (value === 1) {
            setTimeType('month');
        }
        if (value === 2) {
            setTimeType('day');
        }
        setValueTab(value)
    };

    return (
        <Box
            sx={{
                width: '96%',
                height: '90%',
                margin: 'auto',
                borderRadius: 2,
                boxShadow: 2,
                background: localStorage.getItem("theme") === "dark" ? "rgb(81, 81, 81)" : 'white',

            }}
        >
            <Grid container spacing={0}>
                <Grid item xs={6} md={4}>
                    <Typography sx={{ ml: 2, mt: 1.5,fontWeight:600,
                    }}>{title}</Typography>
                </Grid>
                <Grid item xs={6} md={8}>
                    <Tabs value={valueTab ? valueTab : 0} onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={false}
                        aria-label="scrollable prevent tabs example"
                        TabIndicatorProps={{
                            style: {
                                height: 0,
                                // transform: 'translateY(-12px)',
                            },
                        }}
                        sx={{ float: 'right', }}>
                        <Tab sx={{ fontWeight:700,boxShadow: valueTab === 0 ? 2 : 0, borderRadius: valueTab === 0 ? 1 : 0,background: valueTab === 0 ? '#2f54eb' : '', color: valueTab === 0 ? '#fff !important' : localStorage.getItem('theme')==='dark'?'#ffff':'black' }} label={t('Năm') + ": " + year} {...a11yProps(0)} />
                        <Tab sx={{fontWeight:700, boxShadow: valueTab === 1 ? 2 : 0, borderRadius: valueTab === 1 ? 1 : 0,background: valueTab === 1 ? '#2f54eb' : '', color: valueTab === 1 ? '#fff !important' : localStorage.getItem('theme')==='dark'?'#ffff':'black' }} label={t('Tháng') + ": " + month + '-' + year} {...a11yProps(1)} />
                        <Tab sx={{ fontWeight:700,boxShadow: valueTab === 2 ? 2 : 0, borderRadius: valueTab === 2 ? 1 : 0, background: valueTab === 2 ? '#2f54eb' : '', color: valueTab === 2 ? '#fff !important' : localStorage.getItem('theme')==='dark'?'#ffff':'black' }} label={t('Ngày') + ": " + day} {...a11yProps(2)} />
                    </Tabs>
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                    {series && options &&
                        <div style={{ height: height, minHeight: '230px', color: 'black', marginTop: '1vh', cursor: 'pointer' }} className={localStorage.getItem("theme") === 'dark' ? 'mode' : ''} >
                            {/* {showType === 'solar' &&
                            <div style={{
                                height: '3vh',
                                marginTop: '-4px',
                                display: 'flex',
                                marginBottom: '15px',
                                // background:'red',
                            }}>
                                <LineChart color1={'#69b1ff'} color2={'#bae0ff'}
                                    width={'40%'}
                                    value1={((dataChartLine?.consumed / dataChartLine?.yield) || 0 * 100).toFixed(2)}
                                    value2={((dataChartLine?.fedToGrid / dataChartLine?.yield) || 0 * 100).toFixed(2)}
                                    title={`Yield: ${dataChartLine?.yield || 0} kWh`}
                                    from={`Consumed: ${dataChartLine?.consumed || 0} kWh`}
                                    to={`Fed To Grid: ${dataChartLine?.fedToGrid || 0} kWh`} />
                                <LineChart color1={'#85a5ff'} color2={'#d6e4ff'}
                                    width={'40%'}
                                    value1={((dataChartLine?.fromPV / dataChartLine?.consumption) || 0 * 100).toFixed(2)}
                                    value2={((dataChartLine?.fromGrid / dataChartLine?.consumption) || 0 * 100).toFixed(2)}
                                    title={`Consumption: ${dataChartLine?.consumption || 0} kWh`}
                                    from={`From PV: ${dataChartLine?.fromPV || 0} kWh`}
                                    to={`From Grid: ${dataChartLine?.fromGrid || 0} kWh`} />
                            </div>
                        } */}
                                <Chart series={series} options={options} type="bar" />
                        </div>}
                </Grid>

            </Grid>
        </Box>
    )
};
