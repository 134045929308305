// assets
import { IconSquareRoundedPlus,IconSettingsCog,IconLocation,IconReplace,IconVectorTriangle } from '@tabler/icons-react';
// constant
const icons = { IconSquareRoundedPlus,IconSettingsCog,IconLocation,IconReplace,IconVectorTriangle };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const system = {
    id: 'system',
    type: 'group',
    children: [
        {
            id: 'new-place',
            title: 'Thêm địa điểm',
            type: 'item',
            url: '/new-place',
            icon: icons.IconSquareRoundedPlus,
            breadcrumbs: false
        },
        {
            id: 'my-place',
            title: 'Danh sách địa điểm',
            type: 'item',
            url: '/my-place',
            icon: icons.IconReplace,
            breadcrumbs: false
        },
        {
            id: 'parameters',
            title: 'Thông số',
            type: 'item',
            url: '/parameters',
            icon: icons.IconVectorTriangle,
            breadcrumbs: false
        },
        // {
        //     id: 'equipment',
        //     title: 'Trạng thái thiết bị',
        //     type: 'item',
        //     url: '/equipment',
        //     icon: icons.IconLocation,
        //     breadcrumbs: false
        // },
    ]
};
export default system;
