import { Grid, Typography} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from 'prop-types';

import TabPanel0 from "./TabPanel0"
import TabPanel3 from "./TabPanel3";

import "./index.scss";
import { useEffect } from "react";
import { setValueTabSystemHeader } from "../../utils/store/actions";
function TabPanel(props) {
    const { children, value, index, ...other } = props;
    // const theme = useTheme();
    // const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Typography component="div" sx={{ p: 3 }}>
                    {children}
                </Typography>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export default function EnergySystems() {
    const dispatch = useDispatch();

    const newValueTabHeader = useSelector((state) => state.ReducerValueTabSystemHeader.data);

      useEffect(() => {
        dispatch(setValueTabSystemHeader(0))
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

    return (
        <>
            <Grid container sx={{ width: '100%',height:'93vh' }} spacing={0} >
                <Grid item xs={12} sm={12} md={12}>
                    {newValueTabHeader === 0 &&
                        <TabPanel0 />
                    }
                    {newValueTabHeader === 1 &&
                            <TabPanel3 />
                    }
                    {/* {newValueTabHeader === 2 &&
                            <TabPanel3 />
                    }
                    {newValueTabHeader === 3 &&
                            <>Item 4</>
                    } */}
                </Grid>
            </Grid>
        </>
    )
}