/* eslint-disable no-empty-pattern */
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

import { useEffect } from "react";
import { toast } from "react-toastify";



import Iconify from "../../components/iconify/Iconify";
import useValidator from "../../utils/Validator";
import Axios from "../../utils/Axios";
import useLogin from "../../utils/Login/useLogin";
import DialogConfirm from "../Dialog/DialogConfirm";
import Loading from "../../components/Loading";
import logApiErrorGet from "../../utils/Axios/LogApiError";
import { useTranslation } from "react-i18next";


const styleInputFullField = {
  width: "100%",
  mb: 3,
  mr: 3,
};


export default function ChangePassword() {
  const { logout } = useLogin();
  const { t } = useTranslation();

  const { validateChangePassword } = useValidator();

  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);

  const [openDialog, setOpenDialog] = React.useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [userDetail, setUserDetail] = useState({})
  const [inputValues, setInputValues] = useState({
  });

  const [user, setUser] = useState({
  });

  const [errors, setErrors] = useState({
  });


  useEffect(() => {
    getUserDetail();
  }, [])

  const getUserDetail = async () => {
    setShowLoading(true);
    try {
      const response = await Axios.User.getUserDetail();
      if (response?.status === 200) {
        setShowLoading(false)
        setUserDetail(response.data)
      } else {
        logApiErrorGet(setShowLoading, "getUserDetail", response);
      }
    } catch (error) {
      logApiErrorGet(setShowLoading, "getUserDetail", error);

    }
  }

  const handleClickOpen = () => {
    setOpenDialog(true);
  };


  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validateChangePassword(name, value) });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validateChangePassword(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));

    if (validForm) {
      if (user.newPassword !== user.confirmPassword) {
        setErrors({
          confirmpassword: "Mật khẩu mới không khớp!",
        });
        return;
      } else {
        handleClickOpen();
      }
    } else {
      toast.error(`${t('Vui lòng điền đầy đủ thông tin')}!`);
    }
  };

  const updatePassword = async () => {
    setShowLoading(true)
    const response = await Axios.User.changePassword({
      oldPassword: user.oldPassword,
      newPassword: user.newPassword,
    });
    if (response.status === 200) {
      toast.success(t('Đổi mật khẩu thành công'));
      
      setShowLoading(false)
      localStorage.removeItem("account");
      setTimeout(() => {
        logout();
      }, 1000);
    } else {
      if (response.status === 400 && response.code === 3) {
        setErrors({
          oldPassword: 'Mật khẩu cũ không chính xác!'
        })
      }
      setShowLoading(false)

    }
    setOpenDialog(false)
  };

  return (
    <>
    <title> {t('Đổi mật khẩu')} </title>
      <Grid container sx={{ mt: '20vh' }}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <form onSubmit={handleOnSubmit}>
                <Box
                  margin="auto"
                  position="relative"
                  width="100%"
                  height="100%"
                  boxShadow={3}
                  borderRadius={3}
                  padding={3}
                  maxWidth={600}
                >
                  <Box>
                    <Typography sx={{ fontWeight: "bold", fontSize: "20" }}>
                      {t('Đổi mật khẩu')}
                    </Typography>
                    <Box sx={{ textAlign: "center", mt: 3 }}>
                      <TextField
                        name="fullname"
                        label={t('Họ Và Tên')}
                        disabled
                        value={userDetail?.username || ''}
                        variant="standard"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon={"icon-park-solid:edit-name"} />
                            </InputAdornment>
                          ),
                        }}
                        autoComplete="none"
                        sx={styleInputFullField}
                      />
                    </Box>
                  </Box>
                  <Box sx={{ textAlign: "center" }}>
                    <TextField
                      name="oldPassword"
                      label={t('Mật khẩu cũ')}
                      type={showOldPassword ? "text" : "password"}
                      placeholder={t('Nhập mật khẩu cũ')}
                      onChange={(e) => {
                        setUser({ ...user, oldPassword: e.target.value });
                      }}
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon={"material-symbols:password"} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowOldPassword(!showOldPassword)}
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  showOldPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.oldPassword ? true : false}
                      helperText={errors.oldPassword}
                      onInput={handleOnInput}
                      sx={styleInputFullField}
                    />
                  </Box>
                  <Box sx={{ textAlign: "center" }}>
                    <TextField
                      name="newpassword"
                      label={t('Mật khẩu mới')}
                      type={showNewPassword ? "text" : "password"}
                      placeholder={t('Nhập mật khẩu mới')}
                      onChange={(e) => {
                        setUser({ ...user, newPassword: e.target.value });
                      }}
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon={"material-symbols:password"} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setNewShowPassword(!showNewPassword)}
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  showNewPassword ? "eva:eye-fill" : "eva:eye-off-fill"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      autoComplete="none"
                      error={errors.newpassword ? true : false}
                      helperText={errors.newpassword}
                      onInput={handleOnInput}
                      sx={styleInputFullField}
                    />
                  </Box>
                  <Box sx={{ textAlign: "center" }}>
                    <TextField
                      name="confirmpassword"
                      label={t('Nhập lại mật khẩu mới')}
                      type={showConfirmPassword ? "text" : "password"}
                      placeholder={t('Nhập lại mật khẩu mới')}
                      onChange={(e) => {
                        setUser({ ...user, confirmPassword: e.target.value });
                      }}
                      variant="standard"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Iconify icon={"material-symbols:password"} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setConfirmShowPassword(!showConfirmPassword)
                              }
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  showConfirmPassword
                                    ? "eva:eye-fill"
                                    : "eva:eye-off-fill"
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      sx={styleInputFullField}
                      autoComplete="none"
                      error={errors.confirmpassword ? true : false}
                      helperText={errors.confirmpassword}
                      onInput={handleOnInput}
                    />
                  </Box>
                  <Box textAlign={"right"}>
                    <Button type="submit" variant="contained" color="warning">
                      Đổi mật khẩu
                    </Button>
                  </Box>
                </Box>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <DialogConfirm title={"đổi mật khẩu?"} openDialog={openDialog} setOpenDialog={setOpenDialog} numSelected={""} titleSeleced={""} onChange={updatePassword} />
      <Loading showLoading={showLoading} />

    </>
  );
}
