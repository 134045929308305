import { IconMenuOrder,IconBrandGoogleHome, IconBrandCoreos, IconCaretDown, IconCaretUp } from '@tabler/icons-react';



import Axios from "./../../utils/Axios";

const icons = {
    IconMenuOrder, IconBrandCoreos, IconCaretDown, IconCaretUp,IconBrandGoogleHome
};
const ChangListPlace = async (page, limit, sortOrder, search, property) => {

    const response = await Axios.Place.getAll(
        page,
        limit,
        sortOrder,
        search,
        property
    );
    if (response?.status === 200) {
        let listChildren = [];
        for (let index = 0; index < response?.data?.listItem?.length; index++) {
            const element = response?.data?.listItem[index];
            listChildren.push({
                id: 'energy-system/' + element._id,
                title: element.name,
                type: 'item',
                icon: icons.IconBrandCoreos,
                url: '/energy-system/' + element._id,
                breadcrumbs: false,
                index: ((response.data.totalItem > 4 && index + 1 === response.data.listItem.length) ? response?.data?.totalItem : index)
            })
        }

        if (response.data.totalItem === 0) {
            const data = {
                id: 'utilities',
                children: [
                    {
                        id: 'dashboard',
                        title: 'Trang chủ',
                        type: 'item',
                        url:'/dashboard',
                        icon: icons.IconBrandGoogleHome,
                    },
                    {
                        id: ' Unknown device',
                        title: ' Unknown device',
                        type: 'collapse',
                    },
                ]
            }
            return data;
        } else {
            const data = {
                id: 'utilities',
                type: 'group',
                children: [
                    {
                        id: 'dashboard',
                        title: 'Trang chủ',
                        type: 'item',
                        url:'/dashboard',
                        icon: icons.IconBrandGoogleHome,
                    },
                    {
                        id: 'Energy-Systems',
                        title: 'Tất cả địa điểm',
                        type: 'collapse',
                        icon: icons.IconMenuOrder,
                        children: listChildren
                    },
                ]
            }
            return data;
        }

    } else {
        // toast.error("Lấy danh sách thất bại!");
    }
};

export default ChangListPlace;