import { Box, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import PropTypes from 'prop-types';
import { useTranslation } from "react-i18next";
import Loading from "../Loading";
import Axios from "../../utils/Axios";
import logApiErrorGet from "../../utils/Axios/LogApiError";
import { useNavigate } from "react-router-dom";
import Iconify from "../iconify/Iconify";
import FormatCurrency from "../../utils/FormatNumber/FormatNumber";




function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Typography component="div" sx={{ p: 3 }}>
                    {children}
                </Typography>
            )}
        </div>
    );
}


TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

const year = new Date().getFullYear();
const month = new Date().getMonth() + 1 < 10 ? ('0' + (new Date().getMonth() + 1)) : (new Date().getMonth() + 1);
const day = new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate();

export default function RegionalStatistics() {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [valueTab, setValueTab] = useState(2);
    const [timeType, setTimeType] = useState('day');
    const [showLoading, setShowLoading] = useState(false);
    const [listData, setListData] = useState();


    useEffect(() => {
        getStatisticalTableOfAllPlace(year + '-' + month + '-' + day, timeType);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeType])

    const getStatisticalTableOfAllPlace = async (date, type) => {
        setShowLoading(true);
        try {
            const response = await Axios.Place.getStatisticalTableOfAllPlace(date, type);
            if (response?.status === 200) {
                setShowLoading(false);
                setListData(response.data);
            } else {
                logApiErrorGet(setShowLoading, "getStatisticalTableOfAllPlace", response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "getStatisticalTableOfAllPlace", error);
        }
    }

    const handleChange = (event, value) => {
        if (value === 0) {
            setTimeType('year');
        }
        if (value === 1) {
            setTimeType('month');
        }
        if (value === 2) {
            setTimeType('day');
        }
        setValueTab(value)
    };

    // const temporary = [1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 11, 2, 3, 4, 5, 6, 7, 8, 9, 0, 11, 12, 13, 14, 13, 12, 12];

    return <>
        <Box sx={{
            width: '96%',
            height: '90%',
            margin: 'auto',
            borderRadius: 2,
            boxShadow: 2,
            background: localStorage.getItem("theme") === "dark" ? "rgb(81, 81, 81)" : 'white',
        }}>

            <Grid container spacing={0}>
                <Grid item xs={4} sm={6} md={4}>
                    <Typography sx={{ ml: 2, mt: 1.5, fontWeight: 600 }}>{t('Thống kê khu vực')}</Typography>
                </Grid>
                <Grid item xs={8} sm={6} md={8} sx={{ position: 'relative' }}>
                    <Tabs value={valueTab ? valueTab : 0} onChange={handleChange}
                        variant="scrollable"
                        scrollButtons={false}
                        aria-label="scrollable prevent tabs example"
                        TabIndicatorProps={{
                            style: {
                                height: 0,
                                // transform: 'translateY(-12px)',
                            },
                        }}
                        sx={{ float: 'right', }}>
                        <Tab sx={{ fontWeight: 700, boxShadow: valueTab === 0 ? 2 : 0, borderRadius: valueTab === 0 ? 1 : 0, background: valueTab === 0 ? '#2f54eb' : '', color: valueTab === 0 ? '#fff !important' : localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black' }} label={t('Năm') + ": " + year} {...a11yProps(0)} />
                        <Tab sx={{ fontWeight: 700, boxShadow: valueTab === 1 ? 2 : 0, borderRadius: valueTab === 1 ? 1 : 0, background: valueTab === 1 ? '#2f54eb' : '', color: valueTab === 1 ? '#fff !important' : localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black' }} label={t('Tháng') + ": " + month + '-' + year} {...a11yProps(1)} />
                        <Tab sx={{ fontWeight: 700, boxShadow: valueTab === 2 ? 2 : 0, borderRadius: valueTab === 2 ? 1 : 0, background: valueTab === 2 ? '#2f54eb' : '', color: valueTab === 2 ? '#fff !important' : localStorage.getItem('theme') === 'dark' ? '#ffff' : 'black' }} label={t('Ngày') + ": " + day} {...a11yProps(2)} />
                    </Tabs>
                </Grid>
                <Grid item xs={12} sm={12} md={12} sx={{ height: '29vh', minHeight: '275px', mt: 1 }}>
                    <Box sx={{
                        display: 'flex', boxShadow: 2, height: '40px', alignItems: 'center', background: '#bae7ff',
                        width: '96%',
                        margin: 'auto',
                        color: 'black',

                    }}>
                        <Typography sx={{ width: '5%', textAlign: 'center', fontWeight: 700 }}>STT</Typography>
                        <Typography sx={{ width: '19%', textAlign: 'center', fontWeight: 700 }}>{t('Tên')}</Typography>
                        <Typography sx={{ width: '19%', textAlign: 'center', fontWeight: 700 }}>kW</Typography>
                        <Typography sx={{ width: '19%', textAlign: 'center', fontWeight: 700 }}>kWh</Typography>
                        <Typography sx={{ width: '19%', textAlign: 'center', fontWeight: 700, color: '#52c41a' }}>{t('Tiền') + ' (VNĐ)'}</Typography>
                        <Typography sx={{ width: '19%', textAlign: 'center', fontWeight: 700 }}>{t('Tỉ lệ')}</Typography>
                    </Box>
                    {listData &&
                        <Box
                            sx={{
                                minHeight: '230px',
                                overflow: 'auto',
                                height: '25vh',
                                width: '96%',
                                margin: 'auto',
                            }}
                        >
                            {listData.map((item, index) => {
                                return <div key={index}>
                                    <Box sx={{
                                        display: 'flex', position: 'relative', pt: 1, pb: 1,
                                        ":hover": {
                                            background: localStorage.getItem('theme') === 'dark' ? '#8c8c8c' : '#f5f5f5',
                                            cursor: 'pointer',
                                        },
                                        boxShadow: 2,
                                        mt: 1

                                    }}
                                        onClick={(e) => { navigate('/energy-system/' + item?._id) }}
                                    >
                                        <Typography sx={{ width: '5%', textAlign: 'center' }}>{index + 1}</Typography>
                                        <Typography sx={{ width: '19%', textAlign: 'center' }}>{item?.name}</Typography>
                                        <Typography sx={{ width: '19%', textAlign: 'center' }}>{item?.values?.currentValue || 0}</Typography>
                                        <Typography sx={{ width: '19%', textAlign: 'center' }}>{item?.values?.electricUsed || 0}</Typography>
                                        <Typography sx={{ width: '19%', textAlign: 'center', color: '#52c41a', fontWeight: 600 }}> {FormatCurrency(item?.values?.expense || 0)} </Typography>
                                        <Typography sx={{ width: '19%', textAlign: 'center' }}>{item?.values?.ratio || 0}
                                            <span style={{ position: 'absolute', top: 9, marginLeft: 4 }}>
                                                {
                                                    !item?.values?.ratio  ?
                                                        ''
                                                        :
                                                        <Iconify icon={item?.values?.ratio > 0 ? 'icons8:up-round' : 'icons8:down-round'} sx={{ width: 20, color: item?.values?.ratio > 0 ? '#52c41a' : '#f5222d' }} />
                                                }
                                            </span>
                                        </Typography>
                                    </Box>
                                    {/* <Divider /> */}
                                </div>
                            })}
                        </Box>
                    }
                    <Divider />
                </Grid>
            </Grid>
        </Box>

        <Loading showLoading={showLoading} />

    </>
}