
import ReactApexChart from "react-apexcharts";

export default function Chart({ series, options, type,width }) {
    
    return (
        <>
                <ReactApexChart options={options} series={series} type={type} height="100%" width={width} />
        </>
    )
}