import { useEffect, useState } from "react";
import Axios from "../../../../utils/Axios";
import { useParams } from "react-router-dom";
import Loading from "../../../Loading";
import GeneralChart from "./Chart/GeneralChart";
import { getUniqueAndSortedTimestamps } from "../../../../utils/FormatNumber/FormatNumber";
import logApiErrorGet from "../../../../utils/Axios/LogApiError";
import { useTranslation } from "react-i18next";
import addFieldsToObject from "../../../../utils/AddFieldsToObject";

export default function Revenue() {
    const { _id } = useParams();
    const { t,i18n } = useTranslation();


    const [timeType, setTimeType] = useState('day');
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1 < 10 ? ('0' + (new Date().getMonth() + 1)) : (new Date().getMonth() + 1));
    const [day, setDay] = useState(new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate());
    const [showLoading, setShowLoading] = useState(false);
    const [listData, setListData] = useState();
    const [modifiedLanguage, setModifiedLanguage] = useState();


    useEffect(() => {
        getRevenue(_id, year + '-' + month + '-' + day, timeType)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeType, _id]);

    useEffect(() => {
        if (modifiedLanguage) {
            if (i18n.resolvedLanguage !== 'vi') {
                const newArray = JSON.parse(JSON.stringify(modifiedLanguage));
                if(newArray.length>0){
                    const transformedArray = addFieldsToObject(newArray);
                    setListData(transformedArray)
                }
            } else {
                setListData(modifiedLanguage)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ t,modifiedLanguage])

    const getRevenue = async (id, date, timeType) => {
        setShowLoading(true);
        try {
            const response = await Axios.Place.getRevenue(id, date, timeType);
            if (response.status === 200) {
                setShowLoading(false);


                const uniqueTimestamps = getUniqueAndSortedTimestamps(response.data.values);
                // console.log(uniqueTimestamps);
                const modifiedList = []
                uniqueTimestamps.forEach((element, jk) => {
                    const resultObject = {}
                    let objTemporary = {}
                    for (const key in response?.data.values) {
                        if (response.data.values.hasOwnProperty(key)) {
                            const value = response.data.values[key];
                            let foundItem = value.find(item => item.ts === element);
                            let number;
                            if (foundItem) {
                                const valuesArray = Object.values(foundItem.value);
                                number =valuesArray[0]
                            } else {
                                number = 0 

                            }
                            let field;
                            if(key==='offPeak'){
                                field = 'Giờ thấp điểm'
                            }
                            if(key ==='peak'){
                                field = 'Giờ cao điểm'
                            }
                            if(key ==='standard'){
                                field = 'Giờ bình thường'
                            }
                            objTemporary[field] = number

                        }
                        resultObject.ts = element;
                        resultObject.value = objTemporary;
                    }
                    modifiedList.push(resultObject)

                });
                // console.log('reuslt',modifiedList)
                setModifiedLanguage(modifiedList)

            } else {
                logApiErrorGet(setShowLoading, "getRevenue", response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "getRevenue", error);

        }
    }


    return <>
        {listData &&
            <GeneralChart
                listData={listData}
                typeChart={'group'}
                timeType={timeType}
                setTimeType={setTimeType}
                year={year}
                setYear={setYear}
                month={month}
                setMonth={setMonth}
                day={day}
                setDay={setDay}
                unit={'VNĐ'}
                title={t('Chi phí')}
                height={'28vh'}
            />
        }
        <Loading showLoading={showLoading} />

    </>
}