
import { ThemeProvider, CssBaseline } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { ConfigProvider,theme } from 'antd';

import { useEffect } from 'react';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Route from './routes';
import themes from "./utils/Theme/theme";
import { setListPlace } from './utils/store/actions';
import ChangListPlace from './layout/menu-items/changeListPlace';
import useLogin from './utils/Login/useLogin';
import './utils/i18n/i18n'; // Đường dẫn đến tệp cấu hình i18n.js

function App() {

  const dispatch = useDispatch();
  const { account } = useLogin();

  const customization = useSelector((state) => state?.customization);
  useEffect(() => {
    if (account?.status) {
      ChangListPlace(0, 10, "", "", "createdAt").then(data => {
        dispatch(setListPlace(data));
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  

  return (
    <>
      <ThemeProvider theme={themes(customization)}>
        <ConfigProvider
          theme={{
            token: {
              colorBgBase: localStorage.getItem('theme') === "dark" ? 'rgb(81, 81, 81)' : '#fff',
              colorText: localStorage.getItem('theme') === "dark" ? '#ffff' : 'black',
            },
            algorithm: localStorage.getItem("theme")==="dark"?theme.darkAlgorithm:theme.defaultAlgorithm,
          }}

        >
          <CssBaseline />
          <Route />
        </ConfigProvider>

        <ToastContainer autoClose={600} />
      </ThemeProvider>
    </>
  );
}

export default App;
