import PropTypes from "prop-types";

// material-ui
import { Box, ButtonBase, IconButton, useTheme, useMediaQuery, Grid } from "@mui/material";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';

import ProfileSection from "./ProfileSection";
import { useNavigate } from "react-router-dom";


import { IconMoon, IconBrightnessUp } from '@tabler/icons-react';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { MENU_OPEN, setTheme } from "../../utils/store/actions";
import { useEffect } from "react";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";
import TabSystem from "./components/TabSystem";
import TabPlace from "./components/TabPlace";
import { useTranslation } from "react-i18next";
// ==============================|| MAIN NAVBAR / HEADER ||============================== //

function getInitialLanguage() {
  const savedLanguage = localStorage.getItem('selectedLanguage');
  return savedLanguage || 'en'; // Trả về 'en' nếu không có ngôn ngữ đã lưu
}

const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const customization = useSelector((state) => state.customization);
  const checkTab = customization.isOpen[0];

  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  const [themeChange, setThemeChange] = useState(customization.mode);

  const [showTabSystem, setTabSystem] = useState();
  const [showTabPlace, setTabPlace] = useState();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState(getInitialLanguage());


  // ==========================
  useEffect(() => {
    // Khôi phục ngôn ngữ từ localStorage khi khởi tạo
    const initialLanguage = getInitialLanguage();
    i18n.changeLanguage(initialLanguage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleChange = (event) => {
    let value;
    if (event.target) {
      value = event.target.value;
    } else {
      value = event;
    }

    i18n.changeLanguage(value);
    localStorage.setItem('selectedLanguage', value)
    setLanguage(value);
  };


  useEffect(() => {
    if (checkTab === 'new-place' || checkTab === 'my-place') {
      setTabPlace(true);
      setTabSystem(false);
    } else {
      if (checkTab?.includes('energy-system/')) {
        setTabPlace(false);
        setTabSystem(true);
      } else {
        setTabPlace(false);
        setTabSystem(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkTab])




  const handleTheme = () => {
    let data = 'light';
    if (themeChange === 'light') {
      data = 'dark';
      setThemeChange('dark')
      localStorage.setItem("theme", 'dark')
    } else {
      setThemeChange('light')
      localStorage.setItem("theme", 'light')
    }

    dispatch(setTheme(data));
  }

  const redirect = () => {
    navigate('/dashboard')
    dispatch({ type: MENU_OPEN, id: 'dashboard' });

  }
  return (
    <>
      <Box sx={{ display: 'flex', flexWrap: 'wrap', height: '100%', }}>
        <Box sx={{ minWidth: matchDownMd ? '100%' : '', width: 260, minHeight: '40px', position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <span style={{ fontWeight: 600, color: '#fff', cursor: 'pointer' }}
            onClick={redirect}
          >{t('Giám sát nguồn điện')}
          </span>
          <ButtonBase
            onClick={handleLeftDrawerToggle}
            sx={{
              position: 'absolute',
              right: 0,
              mr: 1,
            }}>
            {customization.opened ?
              <MenuFoldOutlined style={{ fontSize: '25px', color: 'white' }} />
              :
              <MenuUnfoldOutlined style={{ fontSize: '25px', color: 'white' }} />
            }

          </ButtonBase>
        </Box>

        <Box sx={{
          width:matchDownMd?'100%': 'calc(100% - 260px)', 
          margin:'auto',
          display:'flex',
          justifyContent:'center'
        }}>
          <Grid container sx={{width:'95%'}} >
            <Grid item xs={12} sm={7} md={7} sx={{ display: 'flex', alignItems: 'center', justifyContent:!matchDownMd?'left': 'center' }} >
              <Box sx={{minHeight:'47px'}} >
                {showTabSystem &&
                  <TabSystem />
                }
                {showTabPlace &&
                  <TabPlace matchDownMd={matchDownMd} />
                }
              </Box>

            </Grid>
            <Grid item xs={12} sm={5} md={5} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'right' }}>
              <Box sx={{
                color: '#fff',
              }}>
                <FormControl >
                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={language}
                    onChange={handleChange}

                  >
                    <FormControlLabel value="en"
                      sx={{ ml: -1 }}
                      control={<Radio sx={{
                        color: '#ffff',
                        '&.Mui-checked': {
                          color: '#fff',
                        },

                      }} />} label="EN" />
                    <IconButton color="inherit" onClick={(e) => { handleChange('en') }}>
                    </IconButton>
                    <FormControlLabel value="vi"
                      sx={{ ml: -3 }}
                      control={<Radio
                        sx={{
                          color: '#ffff',
                          '&.Mui-checked': {
                            color: '#fff',
                          },
                        }} />} label="VI" />
                    <IconButton color="inherit" onClick={(e) => { handleChange('vi') }}>
                    </IconButton>
                  </RadioGroup>
                </FormControl>
                <IconButton onClick={handleTheme} color="inherit" sx={{ ml: -3 }}>
                  {customization.mode !== 'light' ? <IconBrightnessUp /> : <IconMoon />}
                </IconButton>
                <ProfileSection />

              </Box>
            </Grid>
          </Grid>
        </Box>

      </Box>
    </>
  );
};

Header.propTypes = {
  handleLeftDrawerToggle: PropTypes.func,
};

export default Header;
