import { Box, Divider, Grid } from "@mui/material";
import schedule from 'node-schedule';


import Iconify from "../../components/iconify/Iconify";
import { useEffect, useState } from "react";
import Axios from "../../utils/Axios";
import logApiErrorGet from "../../utils/Axios/LogApiError";
import Loading from "../Loading";

export default function Parameters() {
    const [showLoading, setShowLoading] = useState(false);
    const [listData, setListData] = useState();

    useEffect(() => {
        getCurrentValueOfAllPlace();
        const job = schedule.scheduleJob('2 */1 * * * *', () => {
            getCurrentValueOfAllPlace();
        });

        return () => {
            job.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const getCurrentValueOfAllPlace = async () => {
        setShowLoading(true);
        try {
            const response = await Axios.Place.getCurrentValueOfAllPlace();
            if (response?.status === 200) {
                setShowLoading(false);
                setListData(response.data);
            } else {
                logApiErrorGet(setShowLoading, "getCurrentValueOfAllPlace", response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "getCurrentValueOfAllPlace", error);
        }
    }
    return <Box sx={{ width: '95%', margin: 'auto' }}>
        {listData &&
            <Grid container >
                {listData.map((item, jk) => {
                    return <Grid key={jk} item xs={12} sm={4} md={3} sx={{ marginTop: '15px' }}>
                        <Box sx={{ boxShadow: 2, borderRadius: 2, display: 'flex', flexWrap: 'wrap', width: '95%', margin: 'auto' }}>
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '60px' }}>
                                <Iconify icon='fluent:book-clock-20-regular' width={50} />
                            </div>
                            <Divider width='100%' />
                            <div style={{ width: '100%', display: 'flex', alignItems: 'center', fontWeight: 'bold', justifyContent: 'center', height: '40px' }}>
                                {item?.name}
                            </div>
                            <div style={{ width: '60%', margin: 'auto', paddingBottom: '10px', display: 'flex', flexWrap: 'wrap', alignItems: 'center', justifyContent: 'center' }}>
                                {Object.keys(item?.value).map((field, index) => {
                                    return <div key={index} style={{ width: '100%', display: 'flex', flexWrap: 'wrap', }}>
                                        <div style={{ width: '50%', marginTop: '10px' }}>{field} </div>
                                        <div style={{ width: '50%', marginTop: '10px', textAlign: 'right' }}>
                                            {item?.value[field]}
                                        </div>
                                        {index !== Object.keys(item?.value).length - 1
                                            && <Divider width='100%' />
                                        }

                                    </div>
                                })}
                            </div>
                        </Box>
                    </Grid>
                })}

            </Grid>
        }
        <Loading showLoading={showLoading} />
    </Box>
}