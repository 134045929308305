import { Box, Button, Grid, useMediaQuery, useTheme, } from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { DatePicker, Space } from 'antd';
import { TreeSelect } from 'antd';
import dayjs from 'dayjs';
import { useParams } from "react-router-dom";


import Axios from "../../../../utils/Axios"
import FormatddMMyyyyHour from "../../../../utils/FormatDate/FormatddMMyyyyHour";
import TableReport from "./TableReport";
import ExcelService from "../../../../utils/exportExcel/excelService"
import Loading from "../../../Loading";
import logApiErrorGet from "../../../../utils/Axios/LogApiError";


//============================================================================================================
const dateFormat = 'DD/MM/YYYY';
const customFormat = (value) => `${value.format(dateFormat)}`;

//============================================================================================================

export default function Report() {
    const { _id } = useParams();
    const { RangePicker } = DatePicker;
    const { SHOW_PARENT } = TreeSelect;
    const { exportToExcelPro } = ExcelService();

    const theme = useTheme();
    const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));


    const [startDate, setStartDate] = useState(dayjs(new Date()));
    const [endDate, setEndDate] = useState(dayjs(new Date()));
    const [TABLE_HEAD, setTABLE_HEAD] = useState();
    const [listData, setListData] = useState();
    const [showLoading, setShowLoading] = useState(false);
    const [valueType, setValueType] = useState(["all"]);
    const [nameExcel, setNameExcel] = useState();

    const [tPtops, setTProps] = useState();
    // const [showType, setShowType] = useState();
    const [treeData, setTreeData] = useState();

    useEffect(() => {
        getReport(_id, JSON.stringify(valueType), startDate, endDate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_id, startDate, endDate, valueType])

    useEffect(() => {

        if (treeData) {
            // console.log('-treeData--> ',treeData[0])
            setTProps({
                treeData: treeData,
                value: valueType,
                onChange: onChangeTProps,
                treeCheckable: true,
                showCheckedStrategy: SHOW_PARENT,
                placeholder: 'Please select',
                style: {
                    width: '100%',
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [treeData, valueType,])

    const onChangeTProps = (newValue) => {
        const newTABLE_HEAD = JSON.parse(JSON.stringify(TABLE_HEAD))
        if (newValue.includes('all')) {
            setTABLE_HEAD(newTABLE_HEAD)
        }
        setValueType(newValue);
    };

    const getReport = async (id, valueType, fromDate, toDate) => {
        try {
            setShowLoading(() => true);
            const response = await Axios.Place.getReport(
                id,
                valueType,
                fromDate,
                toDate
            );
            if (response.status === 200) {
                setShowLoading(() => false);
                if (response?.data?.values[0].value) {
                    const dataArray = Object.keys(response?.data?.values[0]?.value).map(key => ({
                        title: key,
                        value: key,
                        key: key
                    }));
                    // console.log(dataArray, treeData)
                    if (treeData) {
                        if (treeData[0].type !== response.data.type || treeData[0].children.length < dataArray.length) {
                            // console.log('=======>',dataArray[0])
                            setTreeData([
                                {
                                    title: 'Select all',
                                    value: 'all',
                                    key: 'all',
                                    children: dataArray,
                                    type: response.data.type,
                                },
                            ])
                            // console.log(valueType)
                            if (valueType.length === 0) {
                                setValueType(['all'])
                            } else {
                                setValueType(['all'])
                            }
                        }


                    } else {
                        // console.log('undfine<<<<<<<<<<<<<<<')
                        setTreeData([
                            {
                                title: 'Select all',
                                value: 'all',
                                key: 'all',
                                children: dataArray,
                                type: response.data.type
                            },
                        ])
                    }

                    // setShowType(response.data.type)
                    setNameExcel(response.data.name);

                    let arr = JSON.parse(valueType);
                    if (arr.length === 0) {
                        setTABLE_HEAD([])
                    }

                    const listField = []
                    for (let index = 0; index < dataArray.length; index++) {
                        const element = dataArray[index];
                        listField.push(element.title)
                    }

                    if (arr.includes("all")) {
                        arr = listField
                    }
                    const listHead = arr.map(item => {
                        return {
                            id: item,
                            label: item,
                            width: 200,
                            numeric: false,
                        };
                    });
                    listHead.unshift({
                        id: 'ts',
                        label: 'Date',
                        width: 300,
                        numeric: false,
                    })
                    listHead.unshift({
                        id: 'index',
                        label: 'STT',
                        width: 50,
                        numeric: false,
                    })
                    setTABLE_HEAD(listHead)
                    const result = response.data.values.map((item, index) => {
                        const selectedFields = {};

                        listField.forEach(field => {
                            if (item.value.hasOwnProperty(field)) {
                                selectedFields[field] = item.value[field];
                            }
                        });

                        return {
                            index: index + 1,
                            ts: <FormatddMMyyyyHour dateTime={item.ts} />,
                            ...selectedFields
                        };
                    });
                    setListData(result)
                } else {
                    setListData([])
                    if (treeData[0].type !== response.data.type) {
                        if (valueType === '[]') {
                            setValueType(['all'])
                        }
                    }
                }


            } else {
                logApiErrorGet(setShowLoading, "getReport", response);
                setListData([])
            }
    
        } catch (error) {
            logApiErrorGet(setShowLoading, "getReport", error);
            setListData([])
        }
    };


    const onChangeDate = (valueDate) => {
        if (valueDate) {
            let newStartDate = valueDate[0].$d;
            let newEndDate = valueDate[1].$d;

            setStartDate(newStartDate)
            setEndDate(newEndDate)
        } else {
            setListData([])
        }

    }

    return (
        <>

            <Grid container >
                {matchDownMd && <Grid item xs={12} sm={12} md={12} sx={{ height: '6vh', }}>
                </Grid>}
                <Grid item xs={12} sm={6} md={4} sx={{ minHeight: '100px', display: 'flex', justifyItems: 'center', alignItems: 'center' }} >
                    <Box sx={{ width: '90%', margin: 'auto', }}>
                        {/* {type && */}
                        {/* <TreeSelect {...tPropsOne}
                                size={'large'}
                                treeDefaultExpandAll
                            /> */}
                        {tPtops &&
                            <TreeSelect {...tPtops}
                                size={'large'}
                                treeDefaultExpandAll
                            />}
                        {/* } */}
                        {/* {!type &&
                            <TreeSelect {...tPropsThree}
                                size={'large'}
                                treeDefaultExpandAll
                                listHeight={280}
                            />
                        } */}
                    </Box>
                </Grid>

                <Grid item xs={12} sm={6} md={4} sx={{ minHeight: '100px', display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <Space direction="vertical" size={12}>
                            <RangePicker
                                defaultValue={[startDate, endDate]}
                                style={{ cursor: 'pointer', }}
                                format={customFormat} size={'large'}
                                onChange={(e) => { onChangeDate(e) }}
                            />

                        </Space>

                    </Box>
                </Grid>
                <Grid item xs={12} sm={12} md={3} sx={{ minHeight: '100px', display: 'flex', justifyItems: 'center', alignItems: 'center' }}>
                    <Box sx={{ width: '100%', textAlign: 'center' }}>
                        <Button onClick={(e) => exportToExcelPro(listData,
                            `${nameExcel}`,
                            `${new Date(startDate).getDate() + '-' + (new Date(startDate).getMonth() + 1) + '-' + new Date(endDate).getFullYear()}`,
                            `${new Date(endDate).getDate() + '-' + (new Date(endDate).getMonth() + 1) + '-' + new Date(endDate).getFullYear()}`,
                            'CÔNG TY TNHH CÔNG NGHỆ SIGINX',
                            TABLE_HEAD,
                        )}
                            sx={{
                                backgroundColor: '#73d13d', // màu nền button
                                color: 'white', // màu chữ button
                                fontWeight: 'bold',
                                borderRadius: 2,
                                paddingLeft: 2,
                                paddingRight: 2,
                                boxShadow: 2,
                                '&:hover': {
                                    backgroundColor: '#237804', // màu nền khi hover vào button
                                },
                            }}

                        >Export</Button>
                    </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={12} sx={{ minHeight: '630px' }} >
                    {TABLE_HEAD && listData && <TableReport TABLE_HEAD={TABLE_HEAD} listData={listData} />}
                </Grid>
            </Grid >
            <Loading showLoading={showLoading} />

        </>
    )
}