import PropTypes from "prop-types";
// @mui
import {
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Checkbox,
} from "@mui/material";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

// ----------------------------------------------------------------------


PlaceListHead.propTypes = {
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
};

export default function PlaceListHead({
  order,
  orderBy,
  rowCount,
  headLabel,
  numSelected,
  onRequestSort,
  onSelectAllClick,
  onChange,
  page,
  limit,
}) {
  const { t } = useTranslation();

  useEffect(() => {
    onChange(page, limit, order, "", orderBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [order || orderBy]);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <>
      <TableHead style={{ position: 'sticky', top: 0, zIndex: 1 }} className="tableHead" >
        <TableRow >
          <TableCell padding="checkbox" >
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              sx={{ color: 'white' }}
              className="tableHeadLabel"

            />
          </TableCell>
          {headLabel?.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.alignRight ? "left" : "center"}
              sortDirection={orderBy === headCell.id ? order : false}
              className="tableHeadLabel"
            >
              <TableSortLabel
                sx={{ fontWeight: "bold", fontSize: 15, }}
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                className="tableHeadLabel"
              >
                {t(headCell.label)}
              </TableSortLabel>
            </TableCell>
          ))}
          <TableCell padding="checkbox" >
          </TableCell>
        </TableRow>
      </TableHead >
    </>
  );
}
