import { Backdrop, CircularProgress } from "@mui/material";



export default function Loading({ showLoading }) {

    return (
        <Backdrop
            sx={{ color: "#fff", background: 'rgba(0, 0, 0, 0)', width: "50px", height: '5px', margin: 'auto', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={showLoading}
        >
            <CircularProgress color="primary" sx={{ zIndex: 9 }} />
        </Backdrop>
    )
}