import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setValueTabPlaceHeader } from "../../../utils/store/actions";
import TablePlace from "./components/TablePlace";
import { useTranslation } from "react-i18next";




export default function MyPlaces() {
    const dispatch = useDispatch();
    const { t } = useTranslation();


    useEffect(() => {
        dispatch(setValueTabPlaceHeader(0))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <title>{t('Danh sách địa điểm')}</title>
            <Grid container spacing={0} >
                <Grid item xs={12} sm={12} md={12} >
                    <TablePlace />
                </Grid>
            </Grid>

        </>
    )
}