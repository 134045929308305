import { useState } from "react";
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
} from "@mui/material";
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';


// components
import useValidator from "./../../../utils/Validator";
import Iconify from "./../../../components/iconify/Iconify";
import useLogin from "../../../utils/Login/useLogin";
import { useEffect } from "react";
import Axios from "../../../utils/Axios";
import Loading from "../../../components/Loading";
// ----------------------------------------------------------------------

const theme = createTheme({
  palette: {
    primary: {
      main: '#8c8c8c',
    },
  },

});


export const LoginForm = () => {
  const { validate } = useValidator();
  const { account, setAccount } = useLogin();


  const [showPassword, setShowPassword] = useState(false);
  const [remember, setRemember] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [inputValues, setInputValues] = useState({
    username: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    username: "",
    password: "",
  });


  useEffect(() => {
    if (account) {
      setInputValues(account);
      setRemember(account.remember)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loginWithAccount = async () => {
    setShowLoading(true);
    const response = await Axios.User.login(inputValues);
    if (response?.status === 400 && response?.data.code === 2) {
      setErrors({
        username: "Không tìm thấy username!",
      });
      setShowLoading(false);
      return true;
    }
    if (response?.status === 400 && response?.data.code === 3) {
      setShowLoading(false);
      setErrors({
        password: "Mật khẩu không chính xác!",
      });
      return true;
    } else {
      if (response?.status === 200 && response?.data.code === 1) {
        localStorage.removeItem("account");
        setAccount(response?.data.data, inputValues.username, inputValues.password, remember);
        window.location = "/"
      }
      setShowLoading(false);
    }


  }

  const handleOnSubmit = (e) => {
    e.preventDefault();
    let target = Array.from(e.target);
    let validForm = true;
    let error = {};
    target.forEach((data) => {
      if (data.name) {
        let err = validate(data.name, data.value);
        if (err) {
          error[data.name] = err;
          validForm = false;
        }
      }
    });
    setErrors(() => ({ ...errors, ...error }));
    if (validForm) {
      loginWithAccount();
    } else {
    }
  };

  const handleOnInput = (event) => {
    const { name, value } = event.target;
    setInputValues({ ...inputValues, [name]: value });
    setErrors({ ...errors, [name]: validate(name, value) });
  };


  return (
    <>
      <ThemeProvider theme={theme}>
        <form onSubmit={handleOnSubmit} >
          <Stack spacing={3} >
            <TextField
              name="username"
              label="Username"
              value={inputValues.username || ''}
              placeholder="Nhập username"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <Iconify icon={"bxs:user"} />
                  </InputAdornment>
                ),
                // style: { color: '#fff' }
              }}
              autoComplete="none"
              error={errors.username ? true : false}
              helperText={errors.username}
              onInput={handleOnInput}
              autoFocus
              focused
            // sx={{ color: '#FF5733' }}

            />
            <TextField
              name="password"
              label="Mật khẩu"
              value={inputValues.password || ''}
              type={showPassword ? "text" : "password"}
              placeholder="Nhập mật khẩu"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" >
                    <Iconify icon={"bxs:lock"} />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end" >
                    <IconButton
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      <Iconify
                        icon={showPassword ? "eva:eye-fill" : "eva:eye-off-fill"}
                      // color='#fff'
                      />
                    </IconButton>
                  </InputAdornment>
                ),
                // style: { color: '#fff' }

              }}
              autoComplete="none"
              error={errors.password ? true : false}
              helperText={errors.password}
              onInput={handleOnInput}
              focused

            />
          </Stack>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            sx={{ my: 2 }}
          >
            {account && account.remember === true ?
              <FormControlLabel
                control={
                  <Checkbox
                    name="remember"
                    defaultChecked
                    onChange={() => setRemember(!remember)}
                  // sx={{color:'#fff'}}
                  />
                }
                label="Ghi nhớ tài khoản"
              // sx={{ color: "#ffff"}}
              />
              :
              <FormControlLabel
                control={
                  <Checkbox
                    name="remember"
                    onChange={() => setRemember(!remember)}
                  // sx={{color:'#fff'}}
                  />
                }
                label="Ghi nhớ tài khoản"
              // sx={{ color: "#ffff"}}
              />
            }
            {/* <Link
            to={"/forgot-password"}
            variant="subtitle2"
            style={{ textDecoration: "none", color: localStorage.getItem("theme") === "dark" ? "#ffff" : 'black' }}
          >
            Quên mật khẩu?
          </Link> */}
          </Stack>

          <Button
            className="btn-orange"
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ background: '#1776d2', color: 'white' }}
          >
            Đăng Nhập
          </Button>
        </form>
        <Loading showLoading={showLoading} />
      </ThemeProvider>
    </>
  );
};
