


import Report from "./components/Report";

export default function TabPanel3() {

    return (
        <>
            <Report  />
        </>
    )

}