/* eslint-disable array-callback-return */
import React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Button from '@mui/material/Button';
import { MobileTimePicker } from '@mui/x-date-pickers';
import { Box, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';


import Iconify from '../../iconify/Iconify';

import "./index.scss"
import { useTranslation } from 'react-i18next';
export default function GeneralForm({ localizations, setLocalizations, place, setPlace, errors, setErrors, handleOnInput, titleValue, name,showErrorCheckBox,setShowErrorCheckBox }) {
    // console.log(localizations,titleValue)
    const { t } = useTranslation();
    const handleAddLocalization = () => {
        setLocalizations([...localizations, { startTime: null, endTime: null, status: true, dayOfWeek: [false, false, false, false, false, false, false] }]);
    };

    const handleTimeChange = (index, field, newTime) => {
        const data = {
            target: {
                name: titleValue + field + index,
                value: newTime?.$d
            }

        }
        handleOnInput(data)
        const updatedLocalizations = [...localizations];
        updatedLocalizations[index][field] = newTime;
        setLocalizations(updatedLocalizations);
    };

    const getEndTimeMinTime = (index) => {
        const checkStartTime = localizations[index].startTime;
        return checkStartTime ? checkStartTime.add(1, 'minute') : null;

    };

    const handleDeleteLocalization = (index) => {
        const updatedLocalizations = [...localizations];
        // updatedLocalizations.splice(index, 1);

        updatedLocalizations[index].status = false;
        setLocalizations(updatedLocalizations);

        // ====
        const newObj = { ...errors };
        delete newObj["peakPriceendTime" + index]
        delete newObj["standardPriceendTime" + index]
        delete newObj["offPeakPriceendTime" + index]
        delete newObj["peakPricestartTime" + index]
        delete newObj["standardPricestartTime" + index]
        delete newObj["offPeakPricestartTime" + index]

        setErrors(newObj);

    };


    const handleCheckboxChange = (index, checkboxIndex) => {

        try {
            const updatedLocalizations = [...localizations];
            updatedLocalizations[index].dayOfWeek[checkboxIndex] = !updatedLocalizations[index].dayOfWeek[checkboxIndex];
          
            for (let i = 0; i < updatedLocalizations.length; i++) {
                if(!checkForTrue(updatedLocalizations[i].dayOfWeek)){
                    setShowErrorCheckBox(true)
                }else{
                    setShowErrorCheckBox(false)
                }
            }
          
            setLocalizations(updatedLocalizations);
        } catch (error) {

        }
    };

    function checkForTrue(array) {
        for (let i = 0; i < array.length; i++) {
            if (array[i] === true) {
                return true;
            }
        }
        return false;
    }


    return (
        <Grid container sx={{ boxShadow: 2, width: '95%', m: 'auto' }} >
            <Grid item xs={12} sm={12} md={12} sx={{ pb: 2, minWidth: '200px' }}>
                <Box sx={{ width: '200px', m: 'auto' }}>
                    <TextField
                        name={titleValue}
                        id="outlined-basic"
                        label={t(name)}
                        value={place[titleValue] || ''}
                        multiline
                        maxRows={3}
                        variant="standard"
                        color="secondary"
                        sx={{ width: '100%' }}
                        error={errors[titleValue] ? true : false}
                        helperText={
                            errors[titleValue] ? (
                                errors[titleValue]
                            ) : (
                                <Box component="span" >
                                    &nbsp;
                                </Box>
                            )
                        }
                        onChange={(e) => {
                            setPlace({ ...place, [titleValue]: e.target.value });
                        }}
                        onInput={handleOnInput}
                    />
                </Box>
            </Grid>
            {localizations.map((localization, index) => {
                if (localization.status) {
                   return <Grid key={index} item xs={12} sm={12} md={6} sx={{ pb: 2, pt: 2}}>
                        <Box key={index} sx={{
                            boxShadow: 2, width: '95%', border: '1px solid #bfbfbf', borderRadius: 1, m: 'auto', position: 'relative',
                            display: 'flex', flexWrap: 'wrap',
                        }}>
                            <Typography sx={{ width: '110px', display: 'flex', alignItems: 'center', justifyContent: 'center', }} >{t('Giờ bắt đầu')}</Typography>
                            <div style={{ width: '30%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={['TimePicker']} sx={{
                                        width: '160px',
                                    }}
                                    >
                                        <MobileTimePicker
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    width: '160px',
                                                },
                                            }}
                                            defaultValue={localization?.startTime}
                                            ampm={false}
                                            onChange={(newTime) => handleTimeChange(index, 'startTime', newTime)}
                                            slotProps={{
                                                textField: {
                                                    name: `${titleValue}startTime${index}`,
                                                    size: 'small',
                                                    error: errors[`${titleValue}startTime${index}`] ? true : false,
                                                    helperText: errors[`${titleValue}startTime${index}`] ? (
                                                        errors[`${titleValue}startTime${index}`]
                                                    ) : (
                                                        <Box component="span" >
                                                        </Box>
                                                    ),
                                                },
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <Typography sx={{ width: '110px', display: 'flex', alignItems: 'center', justifyContent: 'center' }} >{t('Giờ kết thúc')}</Typography>
                            <div style={{ width: '30%', display: 'flex', }}>

                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <DemoContainer components={['TimePicker']} sx={{
                                        width: '160px',
                                    }}>
                                        <MobileTimePicker
                                            sx={{
                                                '& .MuiInputBase-root': {
                                                    width: '160px',
                                                },
                                            }}
                                            defaultValue={localization?.endTime}
                                            ampm={false}
                                            onChange={(newTime) => handleTimeChange(index, 'endTime', newTime)}
                                            minTime={getEndTimeMinTime(index)}
                                            slotProps={{
                                                textField: {
                                                    name: `${titleValue}endTime${index}`,
                                                    size: 'small',
                                                    error: errors[`${titleValue}endTime${index}`] ? true : false,
                                                    helperText: errors[`${titleValue}endTime${index}`] ? (
                                                        errors[`${titleValue}endTime${index}`]
                                                    ) : (
                                                        <Box component="span" >
                                                        </Box>
                                                    ),
                                                },
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </div>
                            <div
                                style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                {[1, 2, 3, 4, 5, 6, 7].map((checkboxIndex, jk) => (
                                    <div
                                        key={checkboxIndex}
                                        style={{ width: '14%', }}>
                                        <FormControlLabel
                                            labelPlacement="end"
                                            checked={localization.dayOfWeek[checkboxIndex - 1]}
                                            control={<Checkbox size='small' sx={{
                                                color: showErrorCheckBox?'red':'',
                                              }} />}
                                            label={(
                                                <Typography variant="body1"
                                                    sx={{ fontSize: 13, width: '30px',color:showErrorCheckBox?'red':'' }}>
                                                    {jk === 6 ? 'CN.' : `Th ` + (jk + 2) + '.'}
                                                </Typography>
                                            )}
                                            onChange={() => handleCheckboxChange(index, checkboxIndex - 1)}
                                        />
                                    </div>
                                ))}
                            </div>
                            {/* {!localization.dayOfWeek.includes(true) && */}
                            {showErrorCheckBox &&
                                <Typography sx={{ color: 'red', mt: -1,ml:2, pb: 0.5 }}>Phải bắt buộc chọn 1!</Typography>
                            }
                            <Iconify icon={"iwwa:delete"}
                                onClick={() => handleDeleteLocalization(index)}
                                sx={{
                                    cursor: 'pointer', top: 0,
                                    right: 0,
                                    position: 'absolute',
                                    background: 'red',
                                    color: '#fff',
                                    borderRadius: 0.5
                                }}
                            />
                        </Box>
                    </Grid>
                }
            })}
            <Grid item xs={12} sm={12} md={12} sx={{ display: 'flex', mb: 2 }}>
                <Button onClick={handleAddLocalization} variant="contained" color="primary"
                    sx={{
                        height: '35px',
                        margin: 'auto'
                    }}
                >
                    {t('Thêm')}
                </Button>
            </Grid>
        </Grid>
    );
}

