import { Box, Typography, } from "@mui/material";
import Iconify from "../../../../components/iconify/Iconify";
// import FormatCurrency from "../../../../utils/FormatNumber/FormatNumber";
import { useTranslation } from "react-i18next";


export default function AppWidgetSummary({ title, icon, icon1, icon2, value, unit, logo, border, color = 'primary', sx, ...other }) {

    const { t } = useTranslation();


    return (
        <>
            <Box
                sx={{
                    boxShadow: 1,
                    width: '85%',
                    margin: 'auto',
                    background: localStorage.getItem("theme") === "dark" ? "rgb(81, 81, 81)" : '#fafafa',
                    height: '80%',
                    display: 'flex',
                    justifyItems: 'center',
                    alignItems: 'center',
                    borderTop: '0.6rem solid ' + color
                }}>
                <div style={{ width: '35%', textAlign: 'center' }} >
                    <Iconify icon={icon} width={44} height={44} color={color} />
                </div>

                <div style={{ width: '55%', position: 'relative' }} >
                    <div style={{
                        width: '100%',
                        
                    }} >

                        <Typography 
                        sx={{
                            fontWeight:600
                        }}
                        >
                            <Iconify icon={icon1} width={24} height={24} />
                            {" " + t(title)}
                        </Typography>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div > <Iconify icon={icon2} width={24} height={24} /></div>
                        <div style={{ marginLeft: '8px' }}>
                            <Typography >
                                {/* {FormatCurrency(Math.floor(value))} {unit} */}
                                {value} {unit}
                            </Typography>
                        </div>
                    </div>
                </div>
            </Box>
        </>

    );
}