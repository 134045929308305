import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import schedule from 'node-schedule';
import { useParams } from 'react-router-dom';

import Axios from '../../../../utils/Axios';
import Loading from '../../../Loading';
// import LineChart from './Chart/LineChart';
import GeneralChart from './Chart/GeneralChart';
import { getUniqueAndSortedTimestamps } from '../../../../utils/FormatNumber/FormatNumber';
import logApiErrorGet from '../../../../utils/Axios/LogApiError';
import { useTranslation } from 'react-i18next';
import addFieldsToObject from '../../../../utils/AddFieldsToObject';



export default function GirdConsumption() {
    const { _id } = useParams();
    const { t, i18n } = useTranslation();



    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(new Date().getMonth() + 1 < 10 ? ('0' + (new Date().getMonth() + 1)) : (new Date().getMonth() + 1));
    const [day, setDay] = useState(new Date().getDate() < 10 ? '0' + new Date().getDate() : new Date().getDate());

    const [showLoading, setShowLoading] = useState(false);
    const [timeType, setTimeType] = useState();
    const [listData, setListData] = useState();
    const [typeId, setTypeId] = useState();
    const [modifiedLanguage, setModifiedLanguage] = useState();

    // ============================================================================================================================

    useEffect(() => {
        if (typeId !== _id) {
            setListData()
            if (timeType !== 'day') {
                setTimeType('day')
            } else {
                getAverageValue(_id, year + '-' + month + '-' + day, timeType)
            }
        } else {
            getAverageValue(_id, year + '-' + month + '-' + day, timeType)
        }

        setTypeId(_id);

        const job = schedule.scheduleJob('*/5 * * * *', () => {
            getAverageValue(_id, year + '-' + month + '-' + day, timeType)
          });
      
          return () => {
            job.cancel(); 
          };

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timeType, _id]);


    useEffect(() => {
        if (modifiedLanguage) {
            if (i18n.resolvedLanguage !== 'vi') {
                const newArray = JSON.parse(JSON.stringify(modifiedLanguage));
                if(newArray.length>0){
                const transformedArray = addFieldsToObject(newArray);
                setListData(transformedArray)
               }
            } else {
                setListData(modifiedLanguage)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [t, modifiedLanguage])


    const getAverageValue = async (id, date, timeType) => {
        setShowLoading(true);
        try {
            const response = await Axios.Place.getAverageValue(id, date, timeType);
            if (response.status === 200) {
                setShowLoading(false);
                const uniqueTimestamps = getUniqueAndSortedTimestamps(response.data.values);
                const modifiedList = []
                uniqueTimestamps.forEach((element, jk) => {
                    const resultObject = {}
                    let objTemporary = {};
                    let count = 1;
                    for (const key in response.data.values) {
                        if (response.data.values.hasOwnProperty(key)) {
                            if (count > 3) {
                                count = 1
                            }
                            const value = response.data.values[key];
                            let foundItem = value.find(item => item.ts === element);
                            let number;
                            if (foundItem) {
                                const valuesArray = Object.values(foundItem.value);
                                number = valuesArray[0]
                            } else {
                                number = 0
                            }
                            let field;
                            if (response.data.type === "singlePhase") {
                                count =undefined;
                            }
                            let content='';
                            if(count){
                                content = ' '+count
                            }

                            if (key === 'offPeak' + count || key === 'offPeak') {
                                field = 'Giờ thấp điểm' + content
                            }
                            if (key === 'peak' + count || key === 'peak') {
                                field = 'Giờ cao điểm' + content
                            }
                            if (key === 'standard' + count || key === 'standard') {
                                field = 'Giờ bình thường' + content
                            }
                            objTemporary[field] = number;

                        }
                        resultObject.ts = element;
                        resultObject.group = 1;
                        resultObject.value = objTemporary;
                        count++;
                    }
                    modifiedList.push(resultObject)
                });
                // setListData(modifiedList)
                setModifiedLanguage(modifiedList)
            } else {
                logApiErrorGet(setShowLoading, "getAverageValue",response);
            }

        } catch (error) {
            logApiErrorGet(setShowLoading, "getAverageValue", error);
        }
    }


    return (
        <>
            {listData &&
                <GeneralChart
                    listData={listData}
                    typeChart={'group'}
                    timeType={timeType}
                    setTimeType={setTimeType}
                    year={year}
                    setYear={setYear}
                    month={month}
                    setMonth={setMonth}
                    day={day}
                    setDay={setDay}
                    unit={'kWh'}
                    title={t('Tiêu thụ điện lưới')}
                    height={'28vh'}
                />
            }
            <Loading showLoading={showLoading} />
        </>
    )
};
