import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';


// material-ui
import { Collapse, ListItemButton, ListItemIcon, Typography } from '@mui/material';

// project imports
import NavItem from '../NavItem';

// assets
// import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { IconChevronUp, IconChevronDown } from '@tabler/icons-react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MENU_OPEN } from '../../../utils/store/actions';

// ==============================|| SIDEBAR MENU LIST COLLAPSE ITEMS ||============================== //

const NavCollapse = ({ menu, level }) => {
    const dispatch = useDispatch();

    const [open, setOpen] = useState(true);
    const [selected, setSelected] = useState(null);
    const { t } = useTranslation();



    const handleClick = () => {
        setOpen(!open);
        setSelected(!selected ? menu.id : null);
    };
    const { pathname } = useLocation();
    const checkOpenForParent = (child, id) => {

        child.forEach((item) => {
            if (item.url === pathname) {
                setOpen(true);
                setSelected(id);
            }
        });
    };

    // menu collapse for sub-levels
    useEffect(() => {
        // setOpen(false);
        setSelected(null);
        if (menu.children) {
            menu.children.forEach((item) => {

                if (item.children?.length) {
                    checkOpenForParent(item.children, menu.id);
                }
                if (item.url === pathname) {
                    dispatch({ type: MENU_OPEN, id: item.id });
                    setSelected(menu.id);
                    setOpen(true);
                }

            });

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname, menu.children,]);


    // menu collapse & item
    const menus = menu.children?.map((item) => {
        switch (item.type) {
            case 'collapse':
                return <NavCollapse key={item.id} menu={item} level={level + 1} />;
            case 'item':
                return <NavItem key={item.id} item={item} level={level + 1} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="white" align="center">
                        Unknown device
                    </Typography>
                );
        }
    });

    const Icon = menu.icon;
    const menuIcon = menu.icon ? (
        <Icon strokeWidth={1.5} size="1.3rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
    ) : (
        ''
    );

    return (
        <>
            <ListItemButton
                sx={{
                    borderRadius: `12px`,
                    mb: 0.5,
                    alignItems: 'flex-start',
                    background: selected !== null ? '#f0f2f5 !important' : '',
                    color: selected !== null ? 'black !important' : 'white',
                    py: level > 1 ? 1 : 1.25,
                    pl: `${level * 24}px`,
                }}
                selected={selected === menu.id}
                onClick={handleClick}
            >
                <ListItemIcon sx={{ my: 'auto', minWidth: !menu.icon ? 18 : 36, color: selected !== null ? 'black' : 'white' }}>{menuIcon}</ListItemIcon>
                <div>{t(menu.title)} </div>
                {open ? (
                    <IconChevronUp stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                ) : (
                    <IconChevronDown stroke={1.5} size="1rem" style={{ marginTop: 'auto', marginBottom: 'auto' }} />
                )}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit sx={{ fontSize: 14, ml: -1 }}>
                {menus}
            </Collapse>
        </>
    );
};

NavCollapse.propTypes = {
    menu: PropTypes.object,
    level: PropTypes.number
};

export default NavCollapse;
