import React from 'react';
import { Map, GoogleApiWrapper, Marker, InfoWindow } from 'google-maps-react';
import logo from "../../../assets/images/googleMap.png"

const style = {
    width: '100%',
    height: '40vh',
    borderRadius: '5px',
    boxShadow: "0px 0px 0px 2px #9E9E9E",
}

const containerStyle = {
    position: 'relative',
    width: '100%',
    height: '100%'
}

const MapContainer = (props) => {

    const { google, initialZoom, initialCenter, handleMapClick, selectedPlace, setSelectedPlace } = props;

    const renderMarkers = () => {
        const markers = props.markers;

        return markers.map((marker, index) => (

            <Marker
                key={index}
                onClick={onMarkerClick}
                name={marker.name}
                position={marker.position}
                icon={{
                    url: logo,
                    scaledSize: new window.google.maps.Size(50, 50),
                }}
                // label="free"
                title= {marker.address}
            >
                </Marker>
        ));
    }

    const onMarkerClick = (props, marker) => {
        setSelectedPlace(marker);
    };



    return (
        <>
            {props &&
                <Map google={google} zoom={initialZoom}
                    initialCenter={initialCenter}
                    onClick={handleMapClick}
                    containerStyle={containerStyle}
                    style={style}
                >
                    {renderMarkers()}
                    <InfoWindow onblur={(e) => { console.log('runn') }} marker={selectedPlace} visible={selectedPlace !== null}>
                        <div>
                            <h1 style={{ color: 'black' }}>{selectedPlace?.name}</h1>
                        </div>
                    </InfoWindow>
                </Map>
            }
        </>
    );
};



export default GoogleApiWrapper(
    (props) => ({
        apiKey: props.googleApiKey,
    }
    ))(MapContainer)