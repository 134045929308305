// material-ui
import { Typography } from '@mui/material';


// project imports
import NavGroup from './../NavGroup';
import menuItem from './../../menu-items';
import { useSelector } from 'react-redux';




// ==============================|| SIDEBAR MENU LIST ||============================== //


const MenuList = () => {

    const listData = useSelector((state) => state.ReducerListPlace.data);

    if (listData) {
        const navItems = menuItem({ utilitie: listData })?.items?.map((item) => {
            switch (item.type) {
                case 'group':
                    return <NavGroup key={item.id} item={item} />;
                default:
                    return (
                        <Typography key={item.id} variant="h6" color="white" align="center">
                            Unknown device
                        </Typography>
                    );
            }
        });
        return <>{navItems}</>;
    }

};

export default MenuList;