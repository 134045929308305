import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import schedule from 'node-schedule';






import Chart from "../EnergySystem/TabPanel0/components/Chart/Chart";
import logApiErrorGet from "../../utils/Axios/LogApiError";
import Axios from "../../utils/Axios";

import Loading from "../Loading";




export default function TotalPower() {
    const { t } = useTranslation();

    const [showLoading, setShowLoading] = useState(false);

    const [series, setSeries] = useState();
    const [options, setOptions] = useState();

    useEffect(() => {
        getSerialValueOfAllPlace();

        const job = schedule.scheduleJob('*/5 * * * *', () => {
            getSerialValueOfAllPlace()
        });

        return () => {
            job.cancel(); // Hủy lịch khi component unmount
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (options) {
            const newOptions = JSON.parse(JSON.stringify(options));
            newOptions.xaxis = {
                labels: {
                    style: {
                        colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                    },
                }
            };
            newOptions.yaxis = {
                labels: {
                    formatter: function (value) {
                        return Math.floor(value) + ' kWh';
                    },
                    style: {
                        colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                    },
                },

            }
            setOptions(newOptions)

        } else {
            setOptions(
                {
                    chart: {
                        type: 'area',
                        height: 350,
                        // events: {
                        //     zoomed: function(d1,d2) {
                        //       console.log('Zoomed event',d1,'--',d2,'==');
                        //     },
                        //     scrolled: function({ chartContext, opts }) {
                        //       console.log('Scrolled event--->');
                        //     }
                        //   }
                    },
                    colors: ['#008FFB', '#52c41a', '#eb2f96', '#722ed1', '#faad14', '#13c2c2'],
                    dataLabels: {
                        enabled: false
                    },
                    legend: {
                        position: 'top',
                        horizontalAlign: 'left',
                    },


                    xaxis: {
                        type: 'datetime',
                        labels: {
                            datetimeUTC: false,
                            format: 'HH:mm',
                            style: {
                                colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                            },
                        },
                        axisBorder: {
                            show: false,
                        },
                        axisTicks: {
                            show: false
                        },

                    },

                    stroke: {
                        curve: 'straight',// duong vien nhon
                        width: 1.3 // Đường viền mỏng hơn với giá trị 
                    },
                    yaxis: {
                        tickAmount: 6,
                        floating: false,

                        labels: {
                            formatter: function (value) {
                                return Math.floor(value) + ' kWh';
                            },
                            style: {
                                colors: `${localStorage.getItem("theme") === "dark" ? 'white' : '#8e8da4'}`
                            },
                        },

                    },
                    tooltip: {
                        x: {
                            format: 'dd/MM/yyyy HH:mm',
                        },
                        y: {
                            formatter: (val, { seriesIndex, dataPointIndex, w }) => {
                                var sumDown = 0;
                                for (var index = 0; index < w.config.series.length; index++) {
                                    let arr = w.globals.series[index]
                                    sumDown += arr[dataPointIndex]
                                }


                                // ========toFixed===========
                                const integerPart = Math.floor(sumDown);
                                const decimalPart = Math.floor((sumDown - integerPart) * 100) / 100;

                                const resultSumDown = `${integerPart}.${decimalPart.toFixed(2).slice(2)}`;
                                // ===================
                                let total = `<div style="margin-top:-5px;margin-bottom:5px;">${seriesIndex === 0 ? `${t('Tổng')}: ${resultSumDown} kWh` : ''}</div><p style="margin-top:-5px">${val} kWh</p>`;
                                if (w.config.series.length < 2) {
                                    total = val;
                                }

                                return total;
                            }
                        },
                    },

                    fill: {
                        type: 'solid', // Sử dụng màu solid (không loang)
                        opacity: 0   // Đặt độ mờ của màu fill thành 0
                    }
                }
            )
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [localStorage.getItem("theme")]);

    const getSerialValueOfAllPlace = async () => {
        setShowLoading(true);
        const response = await Axios.Place.getSerialValueOfAllPlace();
        try {
            if (response.status === 200) {
                setShowLoading(false);
                const uniqueTimestamps = getUniqueAndSortedTimestamps(response.data.values);
                const outputTimestamps = [];

                uniqueTimestamps.forEach(timestamp => {
                    const date = new Date(timestamp);
                    const minutes = date.getMinutes();
                    const checkMinutes = isDivisibleBy5(minutes);
                    if (checkMinutes) {
                        outputTimestamps.push(timestamp);
                    }

                });
                // console.log(">>>>>", outputTimestamps);

                const timestamp = new Date().getTime();
                const timestampsList = getListOfTimestampsInDay(timestamp);

                const resultList = [];
                const listName = [];
                response?.data?.values.forEach(element => {
                    listName.push(element.placeName)
                    const result = timestampsList.map(x => {
                        const matchingItem = element?.serialValue.find(item => roundSecondsToZero(item.ts) === x);
                        let getValue = 0
                        if (matchingItem) {
                            const valueItem = Object.values(matchingItem.value);
                            getValue = valueItem[0];
                        }
                        return {
                            x,
                            y: getValue
                        };
                    });
                    resultList.push(result)
                });
                getDataChart(listName, resultList)


            } else {
                logApiErrorGet(setShowLoading, "getSerialValueOfAllPlace", response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "getSerialValueOfAllPlace", error);
        }
    }



    const getDataChart = (listName, listData) => {
        const newList = []
        // console.log(listData)
        listName.forEach((element, index) => {
            newList.push({
                name: element, data: listData[index],

            })
        });

        setSeries(newList)

    }

    function getListOfTimestampsInDay(timestamp) {
        const startOfDay = new Date(timestamp);
        startOfDay.setHours(0, 0, 0, 0);

        const endOfDay = new Date(timestamp);
        endOfDay.setHours(23, 59, 59, 999);

        const timestampsList = [];
        let currentTimestamp = startOfDay.getTime();

        while (currentTimestamp <= endOfDay.getTime()) {
            timestampsList.push(currentTimestamp);
            currentTimestamp += 300000; // Thêm 5 phút (60,000 mili giây)
        }

        return timestampsList;
    }

    function isDivisibleBy5(number) {
        return number % 5 === 0;
    }

    function getUniqueAndSortedTimestamps(data) {
        const uniqueValues = new Set();

        data.forEach(item => {
            item?.serialValue.forEach(serialItem => {
                uniqueValues.add(roundSecondsToZero(serialItem.ts));
            });
        });

        const uniqueValuesArray = Array.from(uniqueValues);
        return uniqueValuesArray
    }

    function roundSecondsToZero(timestamp) {
        var date = new Date(timestamp);
        date.setSeconds(0);
        date.setMilliseconds(0);
        var roundedTimestamp = date.getTime();
        return roundedTimestamp;
    }

    return <>
        <Box
            sx={{
                width: '96%',
                height: '90%',
                margin: 'auto',
                borderRadius: 2,
                boxShadow: 2,
                background: localStorage.getItem("theme") === "dark" ? "rgb(81, 81, 81)" : 'white',

            }}
        >
            {series && options &&
                <div style={{ height: '32vh', minHeight: '260px', color: 'black', marginTop: '1vh', cursor: 'pointer' }} className={localStorage.getItem("theme") === 'dark' ? 'mode' : ''} >
                    <Chart series={series} options={options} type="area" />
                </div>}
        </Box>
        <Loading showLoading={showLoading} />

    </>
}