import { Grid, } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";



import Axios from "../../../utils/Axios";
import ChartPower from "./components/ChartPower";

import GirdConsumption from "./components/GridConsumption";
import ToTalValue from "./components/ToTalValue";
import HeatMaps from "./components/HeatMaps";
import Loading from "../../Loading";
import Revenue from "./components/Revenue";
import SolarContent from "./components/ContentLocation/Solar";
import ElectricityContent from "./components/ContentLocation/Electricity";
import logApiErrorGet from "../../../utils/Axios/LogApiError";


export default function TabPanel0() {
    const { _id } = useParams();
    const [showLoading, setShowLoading] = useState(false);
    const [listObject, setListObject] = useState();



    useEffect(() => {
        getTotalValue(_id);

        let timeOut = setInterval(() => {
            getTotalValue(_id);
        }, 60000);
        return () => clearInterval(timeOut)
    }, [_id])

    const getTotalValue = async (id) => {
        setShowLoading(true);
        try {
            const response = await Axios.Place.getTotalValue(id);
            if (response?.status === 200) {
                setListObject(response.data);
                setShowLoading(false);
                // if (response.data.type === 'solar') {
                // setShowType(true)
                // }
            } else {
                logApiErrorGet(setShowLoading, "getTotalValue",response);
            }
        } catch (error) {
            logApiErrorGet(setShowLoading, "getTotalValue", error);

        }
    }


    return (
        <>
            {/* mt 7vh  ==============   ToTalValue 14vh        */}
            <Grid container >
                <Grid item xs={12} sm={12} md={12} sx={{ minWidth:'250px',height: !listObject?'14vh':''}} >
                    <Grid container spacing={0} >
                        {listObject &&
                            <ToTalValue listObject={listObject} unit={'kWh'} />
                        }
                    </Grid>
                </Grid>


                <Grid item xs={12} sm={12} md={7.5} sx={{ minHeight: '79vh', }}>
                    <Grid container spacing={0} >
                        <Grid item xs={12} sm={12} md={12} sx={{
                            height: '39.5vh',
                            display: 'flex',
                            minHeight: '310px',
                        }}>
                            < ChartPower />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{
                            height: '39.5vh',
                            display: 'flex',
                            minHeight: '310px',
                        }}>
                            <GirdConsumption />
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} sx={{
                        minHeight: '310px',
                        height: '39.5vh',
                        display: 'flex',
                    }}>
                        <Revenue />
                    </Grid>
                </Grid>

                <Grid item xs={12} sm={12} md={4.5}
                >
                    <Grid item xs={12} sm={12} md={12} sx={{
                        height: '39.5vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                        display: 'flex',
                        minWidth: '520px',
                        minHeight: '310px',
                    }}>
                        {listObject?.type === 'solar' ?
                            <SolarContent />
                            : <ElectricityContent />

                        }
                    </Grid >
                    <Grid item xs={12} sm={12} md={12} sx={{
                        height: '79vh',
                        minHeight: '620px',
                        display: 'flex',
                    }}>
                        <HeatMaps />
                    </Grid >
                </Grid>

            </Grid>

            <Loading showLoading={showLoading} />

        </>
    )
}